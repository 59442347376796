import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { FormUtils } from '@modules/pages/vehicles/components/rental/utils/rental.util';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { OptionsSkeletonComponent } from '@shared/components/skeleton/options/options-skeleton.component';

@LoadingId(LoadingIdEnum.getVehicleByIdPlans)
@Component({
  selector: 'app-rental-period',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ParagraphComponent,
    OptionsSkeletonComponent,
  ],
  templateUrl: './rental-period.component.html',
})
export class RentalPeriodComponent
  extends LoadingBaseComponent
  implements OnInit
{
  @Input() form!: FormGroup;
  @Input() periodCart: string = '';
  @Input() requiresUpdatePeriod: boolean = false;

  periods: number[] = [48, 60];
  selectedPeriod: number = 60;
  labelField: string = 'rentalPeriod';

  override ngOnInit(): void {
    this.addControlToForm();
    this.checkRequiresUpdateQuantity();
  }

  addControlToForm(): void {
    FormUtils.addControlToForm(this.form, this.labelField, this.periods[1]);
  }

  checkRequiresUpdateQuantity(period?: number): void {
    if (this.requiresUpdatePeriod && this.periodCart)
      this.form
        ?.get(this.labelField)
        ?.setValue(period || this.periodCart || this.periods[1]);

    this.selectedPeriod = period || Number(this.periodCart) || this.periods[1];
  }

  selectPeriod(period: number): void {
    this.checkRequiresUpdateQuantity(period);
  }
}
