<section class="edit-profile-form mt-5">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Email</mat-label>

      <input matInput required email type="email" formControlName="email" />
    </mat-form-field>

    <div formGroupName="entity">
      <div formGroupName="nationalRegistry">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>CNPJ</mat-label>

          <input
            matInput
            type="text"
            mask="00.000.000/0000-00"
            formControlName="number"
            [value]="cnpj | cnpj" />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Razão Social</mat-label>

        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Telefone</mat-label>

      <input
        matInput
        type="text"
        mask="(00) 00000-0000"
        formControlName="phone"
        [value]="phone | phone" />

      <mat-error *ngIf="hasError({ form, field: 'phone' })">
        Telefone é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome do Responsável</mat-label>

      <input matInput type="text" formControlName="name" />

      <mat-error *ngIf="hasError({ form, field: 'name' })">
        Nome do responsável é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cargo</mat-label>

      <input matInput type="text" formControlName="role" />

      <mat-error *ngIf="hasError({ form, field: 'role' })">
        Cargo é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="acceptedTerm">
      Aceito receber comunicações sobre produtos e serviços.
    </mat-checkbox>
  </form>
</section>
