<section class="copyright container pt-md-5 pb-md-5 mt-4 mt-md-0 mb-4 mb-md-0">
  <div class="row">
    <div class="col-md-6">
      <app-paragraph variant="text-mountain-mist fs-xs fw-500 lh-xs mb-0">
        {{ company }}
      </app-paragraph>
    </div>

    <div class="col-md-6 text-md-end mt-2 mt-md-0">
      <app-paragraph variant="text-mountain-mist fs-xs fw-500 lh-xs mb-0">
        {{ copyright }}
      </app-paragraph>
    </div>
  </div>
</section>
