<div class="vehicle-card col position-relative mb-2">
  <div
    tabindex="0"
    role="button"
    (click)="openDetailsVehicle(vehicle)"
    (keyup.enter)="openDetailsVehicle(vehicle)">
    <div
      class="header-car position-relative mb-2 rounded-4 d-flex align-items-center justify-content-center">
      <span class="category fs-xs rounded-5 bg-white">{{
        vehicle.categoria
      }}</span>

      <app-image
        altText="{{ vehicle.title }}"
        [width]="200"
        [height]="200"
        [image]="vehicle.img"></app-image>
    </div>

    <div class="content-car">
      <app-paragraph variant="fs-md lh-xs text-black mb-0">
        {{ vehicle.marca }}
      </app-paragraph>

      <app-paragraph variant="fs-md-2 fw-500 lh-xl text-black">
        {{ vehicle.title }}
      </app-paragraph>

      <app-paragraph variant="size fs-sm fw-500 lh-sm">
        {{ vehicle.subtitle }}
      </app-paragraph>
    </div>
  </div>

  <app-paragraph
    variant="pfs-sm fw-500 lh-sm text-neutral-70 mt-1"
    appShowIfLoggedIn
    data="a partir de">
  </app-paragraph>

  <app-paragraph
    variant="pfs-sm fw-700 lh-sm text-flat-green text-decoration-none"
    appShowIfLoggedIn
    [data]="vehicle.minorPrice | brlCurrency">
    <app-button
      label="Ver Preço"
      layout="pfs-sm fw-500 lh-sm text-flat-green text-decoration-none"
      variant="resetButton"
      appShowSignupGateModal>
    </app-button>
  </app-paragraph>
</div>
