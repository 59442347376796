import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BreakpointService } from '@core/services/breakpoint.service';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { VehicleFilterService } from '@core/services/vehicle-filter.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FiltersComponent } from '@shared/components/filters/filters.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { CardSkeletonComponent } from '@shared/components/skeleton/card/card-skeleton.component';
import { VehicleHowItWorkCardComponent } from '@shared/components/vehicles-base/components/how-it-work-card/how-it-work-card.component';
import { VehicleCardComponent } from '@shared/components/vehicles-base/components/vehicle-card/vehicle-card.component';
import { FilterDisplayName } from '@shared/components/vehicles-base/enums/filter-type.enum';
import {
  FilterParams,
  VehicleList,
} from '@shared/components/vehicles-base/models/vehicles-base.model';
import { CarouselModule } from 'primeng/carousel';
import { Observable } from 'rxjs';

@LoadingId(LoadingIdEnum.getVehicles)
@Component({
  selector: 'app-vehicle-base',
  standalone: true,
  imports: [
    FiltersComponent,
    ButtonComponent,
    VehicleCardComponent,
    CardSkeletonComponent,
    CarouselModule,
    VehicleHowItWorkCardComponent,
    ParagraphComponent,
  ],
  templateUrl: './vehicles-base.component.html',
  styleUrl: './vehicles-base.component.scss',
})
export class VehiclesBaseComponent
  extends LoadingBaseComponent
  implements OnInit
{
  @Input() titleLabel: string = 'Conheça os modelos';
  @Input() currentPage: number = 1;
  @Input() pageSize: number = 4;
  @Input() showButton: boolean = true;
  @Input() showFilters: boolean = false;
  @Input() quantityRepeteLoading: number = 4;
  @Input() useSlide: boolean = true;
  @Input() isDifferentCard: boolean = false;

  @Output() totalItems = new EventEmitter<number>();

  vehicles!: VehicleList;
  brand: string = '';
  category: string = '';
  capacity: string = '';
  isMobile$!: Observable<boolean>;

  constructor(
    protected override loadingService: LoadingService,
    private breakpointService: BreakpointService,
    private vehicleFilterService: VehicleFilterService,
    private navigationService: NavigationService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.filteredVehicles();
    this.checkIsMobile();
  }

  filteredVehicles(): void {
    this.vehicleFilterService
      .filteredVehicles$(this.currentPage, this.pageSize)
      .subscribe({
        next: (vehicles: VehicleList) => {
          this.vehicles = vehicles;

          this.totalItems.emit(vehicles.setup.total);
        },
      });
  }

  applyFilterService(filter: FilterParams | null = null): void {
    this.vehicleFilterService.applyFilter(filter ? filter : null);
  }

  applyFilter(filter: FilterParams | null): void {
    if (filter) {
      switch (filter.type) {
        case FilterDisplayName.brands:
          this.brand = filter.title;
          break;
        case FilterDisplayName.categories:
          this.category = filter.title;
          break;
        case FilterDisplayName.capacities:
          this.capacity = filter.title;
          break;
      }
    } else this.resetFilters();

    this.applyFilterService(filter);
  }

  getDifferentCardIndex(index: number, isDifferentCard: boolean): boolean {
    return isDifferentCard && index === 3;
  }

  resetFilters(): void {
    this.brand = '';
    this.category = '';
    this.capacity = '';
    this.applyFilterService();
  }

  openAllVehicles(): void {
    this.navigationService.navigateTo('/veiculos');
  }

  checkIsMobile(): void {
    this.isMobile$ = this.breakpointService.isMobile();
  }
}
