import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BannerComponent } from '@shared/components/banner/banner.component';
import { MenuComponent } from '@shared/components/menu/menu.component';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MenuComponent, BannerComponent],
  templateUrl: './header.component.html',
})
export class HeaderComponent {}
