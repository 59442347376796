import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth/auth.service';
import { ScrollService } from '@core/services/scroll.service';
import { SignupGateComponent } from '@shared/components/signup-gate/signup-gate.component';

@Directive({
  selector: '[appShowSignupGateModal]',
  standalone: true,
})
export class ShowSignupGateModalDirective {
  @Input() scrollOnLoggedIn = false;
  @Input() scrollTargetId = 'conheca-os-modelos';

  @Output() loggedInAction = new EventEmitter<void>();

  constructor(
    private dialog: MatDialog,
    private scrollService: ScrollService,
    private authService: AuthService
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    event.stopPropagation();

    this.authService.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        if (this.scrollOnLoggedIn)
          this.scrollService.scrollToElement(this.scrollTargetId);

        this.loggedInAction.emit();
      } else
        this.dialog
          .open(SignupGateComponent, {
            panelClass: ['signup-gate', 'modal-custom', 'modal-custom-xs'],
          })
          .afterClosed()
          .subscribe(() => this.dialog.closeAll());
    });
  }
}
