<div
  class="user-account d-md-flex align-items-center justify-content-between"
  *ngIf="userData">
  <div class="user-data mb-4 mb-md-0 d-flex align-items-md-center gap-4">
    <ng-container>
      <ng-container
        *ngTemplateOutlet="
          iconUser;
          context: { classe: 'bg-icon rounded-circle d-none d-md-flex' }
        "></ng-container>

      <ng-container
        *ngTemplateOutlet="
          iconUser;
          context: {
            classe: 'bg-icon rounded-circle icon-mobile d-md-none',
          }
        "></ng-container>
    </ng-container>

    <span>
      <app-paragraph variant="fs-md fw-500 lh-md text-black">
        {{ userData.entity.name }}
      </app-paragraph>

      <app-paragraph variant="mb-0">
        {{ userData.entity.nationalRegistry.number | cnpj }}
      </app-paragraph>
    </span>
  </div>

  <app-button
    label="Editar"
    icon="mode_edit_outline"
    layout="button-border button-size-auto"
    variant="transparent"
    (buttonClick)="onUserEdit()">
  </app-button>

  <app-button-logout class="d-md-none mx-3"></app-button-logout>
</div>

<ng-template #iconUser let-classe="classe">
  <app-icons icon="person_outline" [class]="classe"></app-icons>
</ng-template>
