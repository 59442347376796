<div class="signup-gate sp-p-xl">
  <div class="d-flex justify-content-between mb-4">
    <h2 class="text-gunmetal fs-2xl fw-600 lh-2xl mb-0">
      Cadastre-se para visualizar os preços
    </h2>

    <app-button
      variant="resetButton"
      [isShowingCloseIcon]="true"
      (buttonClick)="closeModal()">
    </app-button>
  </div>

  <app-paragraph variant="mb-4 fs-md lh-md"
    >Ao criar a sua conta você terá acesso aos preços de locação e poderá montar
    seu plano.</app-paragraph
  >

  <div mat-dialog-actions class="d-flex justifu-content-start gap-3">
    <app-button
      label="Efetuar login"
      (buttonClick)="openSidebarDrawer()"></app-button>

    <app-button
      label="Ou efetue um cadastro"
      layout="p-0 button-size-auto text-primary fs-sm text-decoration-underline"
      variant="transparent"
      (buttonClick)="openSidebarDrawer('new')"></app-button>
  </div>
</div>
