import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'Ocorreu um erro inesperado.';

        if (
          typeof ErrorEvent !== 'undefined' &&
          error.error instanceof ErrorEvent
        )
          errorMessage = error.error.message;
        else if (error.error && error.error.error && error.error.error.message)
          errorMessage = error.error.error.message;
        else if (error.error.message) errorMessage = error.error.message;

        // else {
        //   switch (error.status) {
        //     case 400:
        //       errorMessage = 'Requisição inválida.';
        //       break;
        //     case 401:
        //       errorMessage = 'Não autorizado.';
        //       break;
        //     case 404:
        //       errorMessage = 'Recurso não encontrado.';
        //       break;
        //     case 500:
        //       errorMessage = 'Erro interno do servidor.';
        //       break;
        //   }
        // }

        this.notificationService.showToast(errorMessage, 'Erro', 'error');

        return throwError(() => error);
      })
    );
  }
}
