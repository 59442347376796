<div class="info-card">
  <app-card-info-skeleton [isLoading]="isLoading"></app-card-info-skeleton>

  <div
    class="d-flex justify-content-between align-items-center"
    *ngIf="!isLoading">
    <div class="info-card-header">
      <h2 class="fs-lg lh-sm mb-0">
        {{ vehicleData.marca }}
      </h2>

      <app-paragraph variant="fs-xl fw-600 lh-xl m-0 text-black">
        {{ vehicleData.title }}
      </app-paragraph>
    </div>

    <app-image
      variant="info-card-image"
      altText="Imagem da marca do veículo"
      [image]="vehicleData.icon"
      [width]="55"
      [height]="55"></app-image>
  </div>

  <app-paragraph variant="fs-sm fw-500 lh-sm mb-3">
    {{ vehicleData && vehicleData.subtitle }}
  </app-paragraph>

  <app-paragraph
    variant="fs-sm lh-sm"
    *ngIf="!isLoading"
    [html]="vehicleData.sale_description">
  </app-paragraph>

  <app-paragraph variant="mt-3 mb-0 fs-sm lh-sm" *ngIf="!isLoading"
    >a partir de</app-paragraph
  >

  <app-paragraph
    *ngIf="!isLoading"
    variant="price fs-lg lh-lg mb-3"
    appShowIfLoggedIn
    [data]="(vehicleData.minorPrice | brlCurrency) + '/mês'">
    <app-button
      label="Ver Preço"
      layout="price fs-lg lh-lg"
      variant="resetButton"
      appShowSignupGateModal>
    </app-button>
  </app-paragraph>
</div>
