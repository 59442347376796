<section class="header">
  <app-menu [menuDark]="true"></app-menu>
</section>

<section class="vehicle-details bg-body pb-4 pb-md-5">
  <div class="container">
    <div class="row">
      <div
        class="col-md-8 d-flex flex-column justify-content-between position-relative">
        <app-vehicle-breadcrumb
          *ngIf="detailsVehicle"
          [vehicle]="detailsVehicle"></app-vehicle-breadcrumb>

        <app-vehicle-main-image
          [imageUrl]="mainImageUrl"></app-vehicle-main-image>

        <app-vehicle-carousel
          [images]="detailsVehicle && detailsVehicle.images"
          [altText]="detailsVehicle && detailsVehicle.title"
          (imageClicked)="onCarouselImageClick($event)"></app-vehicle-carousel>
      </div>

      <div class="col-md-4 p-0">
        <app-card-vehicle
          *ngIf="currentStep === stageVehicle.detail"
          [currentStep]="currentStep"
          (stageSelected)="onStageSelected($event)">
          <app-vehicle-info-card
            [vehicleData]="detailsVehicle"></app-vehicle-info-card>
        </app-card-vehicle>

        <form
          *ngIf="currentStep === stageVehicle.rentalPlan"
          class="plan-form"
          [formGroup]="planForm"
          (ngSubmit)="onSubmit()">
          <app-card-vehicle
            typeButton="submit"
            [titleLabel]="labels.assemblePlan"
            [labelButton]="labels.add"
            [currentStep]="currentStep"
            (stageSelected)="onStageSelected($event)">
            <app-rental-plan
              [planForm]="planForm"
              [idVehicle]="detailsVehicle.uid"
              [planCart]="planCart"
              [periodCart]="periodCart"
              [franquiaCart]="franquia"
              [quantityCart]="quantityCart"></app-rental-plan>
          </app-card-vehicle>
        </form>
      </div>
    </div>
  </div>
</section>

<section class="vehicle-details mt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-vehicle-specs
          [specs]="
            detailsVehicle && detailsVehicle.specifications
          "></app-vehicle-specs>
      </div>
    </div>
  </div>
</section>

<section class="vehicle-details">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-line class="mb-5 d-block"></app-line>

        <app-vehicle-standard-items
          [items]="
            detailsVehicle && detailsVehicle.features
          "></app-vehicle-standard-items>
      </div>
    </div>
  </div>
</section>

<section class="vehicle-details">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-line class="mb-5 d-block"></app-line>

        <app-faq colLeft="col-md-3" colRight="col-md-9"></app-faq>
      </div>
    </div>
  </div>
</section>
