import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  FaqAdminCreateOrUpdate,
  FaqAdminResponse,
} from '@modules/admin/edit/components/faq/models/faq.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqAdminService extends BaseApiService {
  createOrUpdate(data: FaqAdminCreateOrUpdate[]): Observable<void> {
    return this.makeRequest(
      () =>
        this.http.post<void>(
          `${apiUrl}/${apiVersion}/questions-and-answers/batch`,
          data
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  faqAdmin(domain: string): Observable<FaqAdminResponse[]> {
    return this.makeRequest(
      () =>
        this.http.get<FaqAdminResponse[]>(
          `${apiUrl}/${apiVersion}/questions-and-answers`,
          {
            params: { domain },
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
