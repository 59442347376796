import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { HowItWorksList } from '@modules/pages/home/components/how-it-works/models/how-it-works.model';
import { HowItWorksService } from '@modules/pages/home/components/how-it-works/services/how-it-works.service';
import { ImageComponent } from '@shared/components/image/image.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { ImageHowItWorkSkeletonComponent } from '@shared/components/skeleton/hot-it-works/image/image-skeleton.component';
import { StepsHowItWorkSkeletonComponent } from '@shared/components/skeleton/hot-it-works/steps/steps-skeleton.component';
import { TitleHowItWorkSkeletonComponent } from '@shared/components/skeleton/hot-it-works/title/title-skeleton.component';

@LoadingId(LoadingIdEnum.getHowItWorks)
@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [
    CommonModule,
    ImageComponent,
    ParagraphComponent,
    TitleHowItWorkSkeletonComponent,
    StepsHowItWorkSkeletonComponent,
    ImageHowItWorkSkeletonComponent,
  ],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss',
})
export class HowitworksComponent
  extends LoadingBaseComponent
  implements OnInit
{
  hotItWorks: HowItWorksList[] = [];

  constructor(
    protected override loadingService: LoadingService,
    private howItWorksService: HowItWorksService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getHowItWorks();
  }

  getHowItWorks(): void {
    this.howItWorksService.getHowItWorks().subscribe({
      next: (howItWorks: HowItWorksList[]) => (this.hotItWorks = howItWorks),
    });
  }
}
