import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ItemCountFormatterService } from '@core/services/item-count-formatter.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { AddMoreItemsButtonComponent } from '@modules/pages/vehicles/components/add-more-items-button/add-more-items-button.component';
import { ProposalEmptyComponent } from '@modules/pages/vehicles/components/proposal/proposal-empty/proposal-empty.component';
import { RentalQuantityComponent } from '@modules/pages/vehicles/components/rental/rental-quantity/rental-quantity.component';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';
import {
  CartItem,
  EditItemCart,
} from '@modules/pages/vehicles/services/cart/models/cart.model';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { BRLCurrencyPipe } from '@shared/pipes/brl-currency.pipe';

@Component({
  selector: 'app-cart-item',
  standalone: true,
  imports: [
    CommonModule,
    ImageComponent,
    ParagraphComponent,
    ButtonComponent,
    ProposalEmptyComponent,
    RentalQuantityComponent,
    AddMoreItemsButtonComponent,
    BRLCurrencyPipe,
  ],
  templateUrl: './cart-item.component.html',
  styleUrl: './cart-item.component.scss',
})
export class CartItemComponent implements OnInit {
  form: FormGroup[] = [];
  items!: CartItem[];
  quantities: number[] = [];
  maxQuantityItemCount: number = 99999999999;
  labelField: string = 'quantity';

  constructor(
    private fb: FormBuilder,
    private sidebarDrawerService: SidebarDrawerService,
    private itemCountFormatterService: ItemCountFormatterService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.getCartItems();
    this.handleCartUpdate();
  }

  createItemForms(): void {
    this.form = this.items.map(item =>
      this.fb.group({
        quantity: [item.quantity],
      })
    );

    this.quantities = this.items.map(item => item.quantity);

    this.form.forEach((form, index) =>
      form
        .get(this.labelField)
        ?.valueChanges.subscribe(newQuantity =>
          this.updateCartItemQuantity(index, newQuantity)
        )
    );
  }

  formatItemQuantity(quantity: number): string {
    return this.itemCountFormatterService.formatItemCount(
      quantity,
      this.maxQuantityItemCount
    );
  }

  getCartItems(): void {
    this.cartService.cartItems$.subscribe(cart => {
      this.items = cart;

      this.createItemForms();
    });
  }

  handleCartUpdate(): void {
    this.cartService.cartItems$.subscribe(() => this.getCartItems());
  }

  updateCartItemQuantity(index: number, newQuantity: number): void {
    const itemId = this.items[index]?.id;

    if (itemId !== undefined) {
      this.cartService.updateItemQuantity(itemId, newQuantity);
      this.quantities[index] = newQuantity;
    }
  }

  onRemoveItem(id?: number): void {
    this.cartService.removeItemById(id);
  }

  onEditItem(
    { id, manutencao, quantity, rentalPeriod, franquia }: EditItemCart,
    vehicleId: string
  ): void {
    this.sidebarDrawerService.close();
    this.cartService.editItem(
      {
        id,
        manutencao,
        quantity,
        rentalPeriod,
        franquia,
      },
      vehicleId
    );
  }
}
