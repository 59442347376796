import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { bdAuth } from '@api/app.api';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenService } from '@core/services/auth/token.service';

export const authAdminGuard: CanActivateFn = async () => {
  const tokenService = inject(TokenService);
  const authService = inject(AuthService);

  await tokenService.initDb(bdAuth);

  const isLoggedIn = await authService.isLoggedIn();

  return isLoggedIn;
};
