import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlowIndex } from '@core/enums/flow.enum';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { TitleComponent } from '@modules/auth/shared/title/title.component';
import { AccountSummaryFlowService } from '@modules/pages/account-summary/components/account-summary-base/services/account-summary.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { IconsComponent } from '@shared/components/icons/icons.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar-drawer',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    ButtonComponent,
    TitleComponent,
    IconsComponent,
    MatIconModule,
    ModalComponent,
    ConfirmationDialogComponent,
  ],
  templateUrl: './sidebar-drawer.component.html',
  styleUrl: './sidebar-drawer.component.scss',
})
export class SidebarDrawerComponent implements OnInit {
  @Input() titleLabel: string = '';

  currentContent: string | null = null;

  constructor(
    private sidebarDrawerService: SidebarDrawerService,
    private authFlowService: AuthFlowService,
    private accountSummaryFlowService: AccountSummaryFlowService
  ) {}

  ngOnInit(): void {
    this.subscribeToContentChanges();
  }

  get isNotFirstNorLastStage(): boolean {
    const stageAuthIndex =
      this.authFlowService.getFlowService().getCurrentStageIndex;
    const stageAccountSummaryIndex =
      this.accountSummaryFlowService.getFlowService().getCurrentStageIndex;

    const totalStageAuth = this.authFlowService
      .getFlowService()
      .getFlow(this.authFlowService.getFlowService().getCurrentFlowName)
      ?.stages?.length;
    const totalStageAccountSummary = this.accountSummaryFlowService
      .getFlowService()
      .getFlow(
        this.accountSummaryFlowService.getFlowService().getCurrentFlowName
      )?.stages?.length;

    const currentIndex = stageAuthIndex || stageAccountSummaryIndex;
    const totalStages = totalStageAuth || totalStageAccountSummary;

    return (
      currentIndex > 0 &&
      (totalStages > 2
        ? currentIndex < totalStages - 1
        : currentIndex < totalStages)
    );
  }

  getIsSidebarDrawerOpen(): Observable<boolean> {
    return this.sidebarDrawerService.isOpen$;
  }

  subscribeToContentChanges(): void {
    this.sidebarDrawerService.content$.subscribe(
      content => (this.currentContent = content)
    );
  }

  showConfirmation(): boolean {
    const currentIndex: number =
      this.authFlowService.getFlowService().getCurrentStageIndex;

    if (currentIndex === FlowIndex.thankyou) this.clearStage();

    return (
      this.currentContent === FlowName.signup &&
      currentIndex !== FlowIndex.thankyou
    );
  }

  previousStage(): void {
    this.authFlowService.getFlowService().previousStage();
  }

  clearStage(): void {
    this.authFlowService
      .getFlowService()
      .clearStage(this.authFlowService.getFlowService().getCurrentFlowName);
  }

  closeSidebarDrawer(): void {
    this.sidebarDrawerService.close();
    this.clearStage();
  }

  onCancelSignup(): void {
    this.closeSidebarDrawer();
    this.clearStage();
  }
}
