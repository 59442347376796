<div class="verification-container">
  <app-paragraph>
    Enviamos um código de seis dígitos para seu e-mail {{ userEmail }}.
  </app-paragraph>

  <form appAutoFocus [formGroup]="verificationForm" (ngSubmit)="onSubmit()">
    <div class="code-inputs">
      <input
        #codeInput1
        type="text"
        maxlength="1"
        formControlName="code1"
        class="code-input"
        (input)="onCodeInput($event, codeInput2)"
        (paste)="onPaste($event)" />

      <input
        #codeInput2
        type="text"
        maxlength="1"
        formControlName="code2"
        class="code-input"
        (input)="onCodeInput($event, codeInput3)"
        (paste)="onPaste($event)" />

      <input
        #codeInput3
        type="text"
        maxlength="1"
        formControlName="code3"
        class="code-input"
        (input)="onCodeInput($event, codeInput4)"
        (paste)="onPaste($event)" />

      <input
        #codeInput4
        type="text"
        maxlength="1"
        formControlName="code4"
        class="code-input"
        (input)="onCodeInput($event, codeInput5)"
        (paste)="onPaste($event)" />

      <input
        #codeInput5
        type="text"
        maxlength="1"
        formControlName="code5"
        class="code-input"
        (input)="onCodeInput($event, codeInput6)"
        (paste)="onPaste($event)" />

      <input
        #codeInput6
        type="text"
        maxlength="1"
        formControlName="code6"
        class="code-input"
        (input)="onCodeInput($event)"
        (paste)="onPaste($event)" />
    </div>

    <div class="actions d-flex gap-2">
      <app-button
        type="submit"
        label="Continuar"
        [disabled]="verificationForm.invalid"></app-button>

      <app-button
        [layout]="(resendTimer$ | async) ? 're-send' : ''"
        [variant]="(resendTimer$ | async) ? 'transparent' : 'primary'"
        [label]="'Reenviar ' + (resendTimer$ | async)"
        (buttonClick)="onResendCode()"
        [disabled]="(resendTimer$ | async) ? true : false"></app-button>
    </div>
  </form>
</div>
