import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { emailValidator } from '@core/utils/email-validator.util';
import { hasFieldValidationError } from '@core/utils/validation-helpers/form-validation.util';
import { ValidationSchema } from '@core/utils/validation-helpers/models/validation-schema.model';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'app-field-email',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ParagraphComponent,
    ButtonComponent,
    AutoFocusDirective,
  ],
  templateUrl: './field-email.component.html',
})
export class FieldEmailComponent implements OnInit {
  @Input() message: string = 'Para continuar, informe seu e-mail abaixo.';
  @Input() agreedToTerms: boolean = true;

  @Output() formSubmitted: EventEmitter<string> = new EventEmitter<string>();

  form!: FormGroup;
  buttonDisabled: boolean = true;
  urlPrivacyAndTerms =
    'https://addiante.com.br/wp-content/uploads/2023/09/Politica-de-Privacidade-e-Termos-de-Uso-SITE.pdf';
  hasError: (params: ValidationSchema) => boolean = hasFieldValidationError;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initialForm();
  }

  initialForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, emailValidator]],
    });

    this.onActiveButton();
  }

  onSubmit(): void {
    if (this.form.valid) this.formSubmitted.emit(this.form.value.email);
  }

  onActiveButton(): void {
    this.form.statusChanges.subscribe(
      status => (this.buttonDisabled = status !== 'VALID')
    );
  }
}
