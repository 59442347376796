import { OnInit } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';

export interface LoadingSkeleton extends OnInit {
  isLoading: boolean;
  loadingIds?: string[];
  isLoadingById?: (loadingId: string) => boolean;
}

export function manageLoadingState(
  component: LoadingSkeleton,
  loadingService: LoadingService
): void {
  if (component.loadingIds) {
    const loadingStates = new Map<string, boolean>();

    component.loadingIds.forEach(loadingId => {
      loadingStates.set(loadingId, false);

      loadingService.getLoading$(loadingId).subscribe(loading => {
        loadingStates.set(loadingId, loading);

        component.isLoading = Array.from(loadingStates.values()).some(
          state => state
        );
      });
    });
  } else
    loadingService
      .getLoading$()
      .subscribe(loading => (component.isLoading = loading));
}
