import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { hasFieldValidationError } from '@core/utils/validation-helpers/form-validation.util';
import { ValidationSchema } from '@core/utils/validation-helpers/models/validation-schema.model';
import { AuthBaseComponent } from '@modules/auth/components/auth-base/auth-base.component';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import { UserService } from '@modules/auth/services/user/user.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@LoadingId(LoadingIdEnum.companyData)
@Component({
  selector: 'app-company-data',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskDirective,
    ParagraphComponent,
    ButtonComponent,
    AutoFocusDirective,
  ],
  providers: [provideNgxMask()],
  templateUrl: './company-data.component.html',
})
export class CompanyDataComponent
  extends LoadingBaseComponent
  implements OnInit
{
  companyForm!: FormGroup;
  hasError: (params: ValidationSchema) => boolean = hasFieldValidationError;

  constructor(
    protected override loadingService: LoadingService,
    private fb: FormBuilder,
    private authFlowService: AuthFlowService,
    private authBase: AuthBaseComponent,
    private userService: UserService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initialForm();
  }

  initialForm(): void {
    this.companyForm = this.fb.group({
      cnpj: ['', [Validators.required]],
      companyName: ['', Validators.required],
      phone: ['', [Validators.required]],
      responsiblePerson: ['', Validators.required],
      position: ['', Validators.required],
    });
  }

  get userEmail(): string {
    return this.authFlowService.getUserEmail;
  }

  get userToken(): string {
    return this.authFlowService.getUserToken;
  }

  get userName(): string {
    return this.authFlowService.getUserName;
  }

  onSubmit(): void {
    if (this.companyForm.valid) this.createCompany();
    else this.companyForm.markAllAsTouched();
  }

  createCompany(): void {
    const { cnpj, companyName, phone, responsiblePerson, position } =
      this.companyForm.value;

    this.userService
      .createCompany({
        email: this.userEmail,
        phone,
        name: responsiblePerson,
        role: position,
        entity: {
          nationalRegistry: {
            number: cnpj,
          },
          name: companyName,
        },
      })
      .subscribe({
        next: () =>
          this.authBase.nextStage({
            name: responsiblePerson,
            token: this.userToken,
          }),
      });
  }
}
