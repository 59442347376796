<app-base-skeleton *ngIf="isLoading">
  <div class="skeleton-custom-title d-flex w-100" [ngClass]="{ line: isLine }">
    <div
      class="skeleton-custom-col align-items-center"
      [ngClass]="{ 'line line-before': isLine }"
      *appNgTemplateRepeat="quantityRepeteLoading">
      <p-skeleton
        styleClass="neutral-variant-95"
        [width]="width"
        [height]="height"></p-skeleton>

      <p-skeleton
        *ngIf="isTowTitle"
        styleClass="neutral-variant-95 mt-2"
        width="4.5rem"
        [height]="height"></p-skeleton>
    </div>
  </div>
</app-base-skeleton>
