import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RentalPeriodComponent } from '@modules/pages/vehicles/components/rental/rental-period/rental-period.component';
import { RentalPlanOptionComponent } from '@modules/pages/vehicles/components/rental/rental-plan-option/rental-plan-option.component';
import { RentalQuantityComponent } from '@modules/pages/vehicles/components/rental/rental-quantity/rental-quantity.component';
import { FranquiaPlan } from '@modules/pages/vehicles/services/cart/models/cart.model';

@Component({
  selector: 'app-rental-plan',
  standalone: true,
  imports: [
    RentalPlanOptionComponent,
    RentalQuantityComponent,
    RentalPeriodComponent,
  ],
  templateUrl: './rental-plan.component.html',
  styleUrl: './rental-plan.component.scss',
})
export class RentalPlanComponent {
  @Input() planForm!: FormGroup;
  @Input() idVehicle: string = '';
  @Input() planCart: string = '';
  @Input() periodCart: string = '';
  @Input() franquiaCart!: FranquiaPlan | null;
  @Input() quantityCart: number = 1;
}
