<p-carousel
  *ngIf="!isLoading && banners"
  [value]="banners"
  [numScroll]="1"
  [showIndicators]="true">
  <ng-template pTemplate="item" let-banner>
    <section
      class="banner banner-bg d-flex align-items-center justify-content-center overflow-hidden"
      [ngStyle]="{ background: getBackgroundImageUrl(banner.image) }">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8">
            <h1 class="fs-sm lh-sm text-white">{{ banner.caption }}</h1>

            <h2 class="fs-4xl fw-600 lh-4xl text-white">
              {{ banner.title }}
            </h2>

            <ul class="text-white">
              <li
                class="fs-md"
                *ngFor="let description of banner.description.split(';')">
                {{ description }}
              </li>
            </ul>

            <app-banner-actions [actions]="banner.actions"></app-banner-actions>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
</p-carousel>

<app-banner-skeleton [isLoading]="isLoading"></app-banner-skeleton>
