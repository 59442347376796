import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterDisplayName } from '@shared/components/vehicles-base/enums/filter-type.enum';
import {
  FilterParams,
  VehicleList,
} from '@shared/components/vehicles-base/models/vehicles-base.model';
import { CardService } from '@shared/components/vehicles-base/services/vehicles-base.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class VehicleFilterService {
  private filterSubject = new BehaviorSubject<FilterParams | null>(null);
  private pageResetSubject = new Subject<void>();

  routeFilter$: Observable<FilterParams | null> = this.getFilterFromRoute();
  pageReset$ = this.pageResetSubject.asObservable();

  constructor(
    private route: ActivatedRoute,
    private cardService: CardService
  ) {
    this.routeFilter$.subscribe(filter => {
      if (filter) {
        this.applyFilter(filter);
      }
    });
  }

  filteredVehicles$(
    currentPage: number,
    pageSize: number
  ): Observable<VehicleList> {
    return combineLatest([
      this.filterSubject.asObservable(),
      this.routeFilter$,
    ]).pipe(
      debounceTime(0),
      distinctUntilChanged(),
      switchMap(([filter, routeFilter]) => {
        const queryParams = this.route.snapshot.queryParams;

        const params = {
          brands: queryParams[FilterDisplayName.brands] || '',
          categories: queryParams[FilterDisplayName.categories] || '',
          capacities: queryParams[FilterDisplayName.capacities] || '',
          page: currentPage,
          perPage: pageSize,
        };

        if (filter) Object.assign(params, filter);
        else if (routeFilter)
          Object.assign(params, {
            [routeFilter.type]: routeFilter.title,
          });

        return this.cardService.getVehicles(params);
      })
    );
  }

  getFilterFromRoute(): Observable<FilterParams | null> {
    return this.route.queryParams.pipe(
      map(params => {
        let filter: FilterParams | null = null;

        if (params[FilterDisplayName.brands]) {
          filter = {
            title: params[FilterDisplayName.brands],
            type: FilterDisplayName.brands,
          };
        } else if (params[FilterDisplayName.categories]) {
          filter = {
            title: params[FilterDisplayName.categories],
            type: FilterDisplayName.categories,
          };
        } else if (params[FilterDisplayName.capacities]) {
          filter = {
            title: params[FilterDisplayName.capacities],
            type: FilterDisplayName.capacities,
          };
        }

        return filter;
      })
    );
  }

  applyFilter(filter: FilterParams | null): void {
    if (filter) this.pageResetSubject.next();

    this.filterSubject.next(filter);
  }
}
