import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgTemplateRepeatDirective } from '@core/directives/ng-template-repeat.directive';
import { BaseSkeletonComponent } from '@shared/components/skeleton/base/base-skeleton.component';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-menu-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    SkeletonModule,
    BaseSkeletonComponent,
    NgTemplateRepeatDirective,
  ],
  templateUrl: './menu-skeleton.component.html',
  styleUrl: './menu-skeleton.component.scss',
})
export class MenuSkeletonComponent {
  @Input() isLoading: boolean = true;
  @Input() quantityRepeteLoading: number = 1;
}
