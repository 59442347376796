import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { admin, adminEdit, bdAuth, logoUrl, whiteLabel } from '@api/app.api';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenService } from '@core/services/auth/token.service';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { hasFieldValidationError } from '@core/utils/validation-helpers/form-validation.util';
import { ValidationSchema } from '@core/utils/validation-helpers/models/validation-schema.model';
import { LoginAdminResponse } from '@modules/admin/login/models/login.model';
import { LoginAdminService } from '@modules/admin/login/services/login.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { IconsComponent } from '@shared/components/icons/icons.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { LoadingComponent } from '@shared/components/skeleton/loading/loading.component';

@LoadingId([LoadingIdEnum.createOrLoginUser])
@Component({
  selector: 'app-adm-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    LoadingComponent,
    LogoComponent,
    IconsComponent,
    ButtonComponent,
    ParagraphComponent,
    AutoFocusDirective,
  ],
  templateUrl: './login.component.html',
})
export class LoginAdminComponent
  extends LoadingBaseComponent
  implements OnInit
{
  form!: FormGroup;
  adminUrl = admin;
  adminWhiteLabelUrl = whiteLabel;
  isLoggedIn: boolean = false;
  logoUrl: string = logoUrl;

  hasError: (params: ValidationSchema) => boolean = hasFieldValidationError;

  constructor(
    protected override loadingService: LoadingService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private authService: AuthService,
    private navigationService: NavigationService,
    private loginAdminService: LoginAdminService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getIsLoggedIn();
  }

  initialForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(9)]],
    });
  }

  getIsLoggedIn(): void {
    this.tokenService.initDb(bdAuth);

    this.authService.isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn)
        this.navigationService.navigateTo(
          `/${this.adminUrl}/${this.adminWhiteLabelUrl}`
        );
      else {
        this.isLoggedIn = true;
        this.initialForm();
      }
    });
  }

  onSubmit(): void {
    this.login();
  }

  login(): void {
    const { email, password } = this.form.value;

    this.loginAdminService
      .loginAdmin({
        email,
        password,
      })
      .subscribe({
        next: ({
          token,
          uid,
          name,
          accessProfile,
          domain,
        }: LoginAdminResponse) => {
          let url = `/${admin}/${adminEdit}/${uid}/${domain}`;

          if (accessProfile === 'Admin') url = `/${admin}/${whiteLabel}`;

          this.navigationService.navigateTo(url);
          this.tokenService.saveToken(token, name, accessProfile);
        },
      });
  }
}
