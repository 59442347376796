import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import {
  MenuAdmin,
  MenuAdminCreateOrUpdate,
} from '@modules/admin/edit/components/menu/models/menu.model';
import { MenuAdminService } from '@modules/admin/edit/components/menu/services/menu.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LineComponent } from '@shared/line/line.component';
import { OrderListModule } from 'primeng/orderlist';

@Component({
  selector: 'app-adm-menu',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutoFocusDirective,
    ButtonComponent,
    LineComponent,
    OrderListModule,
  ],
  templateUrl: './menu.component.html',
})
export class MenuAdminComponent implements OnInit {
  form!: FormGroup;
  domain: string = '';
  menuItems: MenuAdmin[] = [{ name: '', link: '' }];

  constructor(
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private menuAdminService: MenuAdminService
  ) {}

  ngOnInit(): void {
    this.initialForm();
    this.getMenuByIdFromRoute();
  }

  get menuItemsArray(): FormArray {
    return this.form.get('menuItems') as FormArray;
  }

  initialForm(): void {
    this.form = this.fb.group({
      menuItems: this.fb.array([]),
    });
  }

  getMenuByIdFromRoute(): void {
    this.route.params.subscribe(
      params => params['domain'] && this.getMenu(params['domain'])
    );
  }

  getMenu(domain: string): void {
    this.domain = domain;

    this.menuAdminService.menu({ domain }).subscribe({
      next: menu => {
        if (menu && menu.length > 0) {
          this.menuItemsArray.clear();

          menu.forEach(item => {
            this.menuItemsArray.push(
              this.fb.group({
                name: [item.name, Validators.required],
                link: [item.link, Validators.required],
              })
            );

            this.menuItems.push({
              name: item.name,
              link: item.link,
            });
          });
        }
      },
    });
  }

  addItem(): void {
    this.menuItemsArray.push(
      this.fb.group({
        name: ['', Validators.required],
        link: ['', Validators.required],
      })
    );

    this.menuItems.push({
      name: '',
      link: '',
    });

    this.form.reset(this.form.value);
  }

  removeItem(index: number): void {
    this.menuItemsArray.removeAt(index);
    this.menuItems.splice(index, 1);
  }

  onSubmit(): void {
    this.createOrUpdate();
  }

  createOrUpdate(): void {
    const data: MenuAdminCreateOrUpdate[] = this.menuItemsArray.controls.map(
      (item, index) => ({
        domain: this.domain,
        name: item.value.name,
        section: 'homeSignedOut',
        order: index + 1,
        link: item.value.link,
      })
    );

    this.menuAdminService.createOrUpdate(data).subscribe({
      next: () => this.notificationService.showToast('Menu salvo.'),
    });
  }
}
