<div class="row vehicle-specs">
  <div class="col-md-3 col-sm-12">
    <h2 class="fh-28">Ficha técnica</h2>
  </div>

  <div class="col-md-9 col-sm-12 spec-item">
    <app-title-skeleton
      [isLoading]="isLoading"
      [isTowTitle]="true"
      [quantityRepeteLoading]="9"
      width="7rem"
      height="1.5rem"></app-title-skeleton>

    <mat-grid-list cols="5" rowHeight="100px" *ngIf="!isLoading">
      <mat-grid-tile *ngFor="let spec of specs">
        <app-paragraph variant="spec-value fs-lg lh-lg text-black d-block">
          {{ spec.value }}
        </app-paragraph>

        <app-paragraph variant="spec-label fs-sm lh-sm d-block">
          {{ spec.key }}
        </app-paragraph>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
