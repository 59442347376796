import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ItemCountFormatterService } from '@core/services/item-count-formatter.service';
import { CartFlowName } from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';
import { CartItem } from '@modules/pages/vehicles/services/cart/models/cart.model';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-cart-icon',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ImageComponent],
  templateUrl: './cart-icon.component.html',
  styleUrl: './cart-icon.component.scss',
})
export class CartIconComponent implements OnInit {
  cartItemCount: number = 0;
  maxCartItemCount: number = 99;
  badgeCount$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private sidebarDrawerService: SidebarDrawerService,
    private itemCountFormatterService: ItemCountFormatterService,
    private cartService: CartService
  ) { }

  ngOnInit(): void {
    this.getCurrentCart();
    this.handleCartUpdate();
  }

  updateBadgeCount(items: CartItem[]): void {
    const formattedCount = this.itemCountFormatterService.formatItemCount(
      items?.length,
      this.maxCartItemCount
    );

    this.cartItemCount = items?.length;
    this.badgeCount$.next(formattedCount);
  }

  getCurrentCart(): void {
    this.cartService.cartItems$.subscribe(items =>
      this.updateBadgeCount(items)
    );
  }

  handleCartUpdate(): void {
    this.cartService.cartItems$.subscribe(() => this.getCurrentCart());
  }

  openSidebarDrawer(): void {
    this.sidebarDrawerService.toggle(CartFlowName.cart);
  }
}
