import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { BaseApiService } from '@core/services/base-api.service';
import { AboutUs } from '@modules/pages/home/components/about-us/models/about-us.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AboutUsService extends BaseApiService {
  aboutUs(domain: string): Observable<AboutUs> {
    return this.makeRequest(() =>
      this.http.get<AboutUs>(
        `${apiUrl}/${apiVersion}/whitelabels/${domain}`,
        {}
      )
    );
  }
}
