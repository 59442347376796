import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import {
  FaqAdminCreateOrUpdate,
  FaqAdminResponse,
} from '@modules/admin/edit/components/faq/models/faq.model';
import { FaqAdminService } from '@modules/admin/edit/components/faq/services/faq.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { LineComponent } from '@shared/line/line.component';
import { OrderListModule } from 'primeng/orderlist';

@LoadingId(LoadingIdEnum.admin)
@Component({
  selector: 'app-adm-faq',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutoFocusDirective,
    ButtonComponent,
    LineComponent,
    OrderListModule,
  ],
  templateUrl: './faq.component.html',
})
export class FaqAdminComponent extends LoadingBaseComponent implements OnInit {
  form!: FormGroup;
  domain: string = '';

  constructor(
    protected override loadingService: LoadingService,
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private faqAdminService: FaqAdminService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initialForm();
    this.getFaqByIdFromRoute();
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  initialForm(): void {
    this.form = this.fb.group({
      items: this.fb.array([]),
    });
  }

  getFaqByIdFromRoute(): void {
    this.route.params.subscribe(
      params => params['domain'] && this.getFaq(params['domain'])
    );
  }

  getFaq(domain: string): void {
    this.faqAdminService.faqAdmin(domain).subscribe({
      next: faq => faq && this.buildFormArray(faq),
    });
  }

  buildFormArray(data: FaqAdminResponse[]): void {
    this.itemsArray.clear();

    data.forEach(item => {
      this.domain = item.domain;

      this.itemsArray.push(
        this.fb.group({
          question: [item.question, Validators.required],
          answer: [item.answer, Validators.required],
        })
      );
    });
  }

  addItem(): void {
    this.itemsArray.push(
      this.fb.group({
        question: ['', Validators.required],
        answer: ['', Validators.required],
      })
    );

    this.form.reset(this.form.value);
  }

  removeItem(index: number): void {
    this.itemsArray.removeAt(index);
  }

  onSubmit(): void {
    this.createOrUpdate();
  }

  createOrUpdate(): void {
    const data: FaqAdminCreateOrUpdate[] = this.itemsArray.controls.map(
      (item, index) => ({
        domain: this.domain,
        section: 'homeSignedInOrOut',
        answer: item.value.answer,
        question: item.value.question,
        order: index + 1,
        isOpen: false,
      })
    );

    this.faqAdminService.createOrUpdate(data).subscribe({
      next: () => this.notificationService.showToast('FAQ salvo.'),
    });
  }
}
