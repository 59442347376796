import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import { from, Observable, of, switchMap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private authFlowService: AuthFlowService
  ) {}

  intercept<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return this.authService.requiresAuth
      ? this.handleAuthenticatedRequest(request, next)
      : next.handle(request);
  }

  private handleAuthenticatedRequest<T>(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return this.getToken().pipe(
      switchMap(token => {
        if (token) request = this.setHeadersToken(request, token);

        return next.handle(request);
      })
    );
  }

  private getToken(): Observable<string | null> {
    const token = this.authFlowService.getUserToken;

    if (token) return of(token);

    return from(this.authService.getToken());
  }

  private setHeadersToken<T>(
    request: HttpRequest<T>,
    token: string
  ): HttpRequest<T> {
    return (request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    }));
  }
}
