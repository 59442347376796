import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Stage } from '@core/models/flow.model';
import { FlowType } from '@modules/auth/components/auth-base/types/auth-base.type';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { AuthNextData } from '@modules/auth/services/auth-flow/models/auth-flow.model';
import { AllowedComponents } from '@modules/auth/services/auth-flow/types/auth-flow.type';

@Component({
  selector: 'app-auth-base',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './auth-base.component.html',
  styleUrl: './auth-base.component.scss',
})
export class AuthBaseComponent implements OnInit {
  @Input() flowName!: FlowName;

  @Output() currentFlowTitle = new EventEmitter<string>();

  currentFlow!: FlowType;

  constructor(protected authFlowService: AuthFlowService) {}

  ngOnInit(): void {
    this.initializeRegistrationFlow();
    this.subscribeToCurrentStageChanges();
  }

  initializeRegistrationFlow(): void {
    this.authFlowService.initFlow();

    this.currentFlow = this.authFlowService
      .getFlowService()
      .getFlow(this.flowName);
  }

  emitCurrentFlowTitle(title: string): void {
    this.currentFlowTitle.emit(title);
  }

  subscribeToCurrentStageChanges(): void {
    this.authFlowService
      .getFlowService()
      .currentStage$.subscribe(({ title }) => this.emitCurrentFlowTitle(title));
  }

  nextStage({ email, name, token }: AuthNextData): void {
    this.authFlowService.nextStage({ email, name, token });
  }

  previousStage(): void {
    this.authFlowService.getFlowService().previousStage();
  }

  get currentStage(): Stage<AllowedComponents> {
    return this.authFlowService.getFlowService().currentStage;
  }
}
