import { Component } from '@angular/core';
import { HowitworksComponent } from '@modules/pages/home/components/how-it-works/how-it-works.component';
import { RentalAdvantagesComponent } from '@modules/pages/home/components/rental-advantages/rental-advantages.component';
import { FiltersComponent } from '@shared/components/filters/filters.component';
import { VehiclesBaseComponent } from '@shared/components/vehicles-base/vehicles-base.component';
import { HeaderComponent } from '@shared/header/header.component';
import { LineComponent } from '@shared/line/line.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { FaqComponent } from './components/faq/faq.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HeaderComponent,
    VehiclesBaseComponent,
    FiltersComponent,
    HowitworksComponent,
    RentalAdvantagesComponent,
    LineComponent,
    AboutUsComponent,
    FaqComponent,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {}
