import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  Banner,
  FilterBannersParams,
} from '@shared/components/banner/models/banner.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerService extends BaseApiService {
  getBanners({ domain = dynamicHost }: FilterBannersParams = {}): Observable<
    Banner[]
  > {
    return this.makeRequest(
      () =>
        this.http.get<Banner[]>(`${apiUrl}/${apiVersion}/banners`, {
          params: removeEmptyProps({ domain }),
        }),
      LoadingIdEnum.getBanners
    );
  }
}
