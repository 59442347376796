import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AbouUsDb,
  Logo,
} from '@modules/pages/home/components/about-us/models/about-us.model';
import Dexie from 'dexie';

@Injectable({ providedIn: 'root' })
export class AboutUsDatabaseService extends Dexie {
  aboutUs!: Dexie.Table<AbouUsDb, string>;
  aboutUsData: string = 'about-us';

  private db!: Dexie;
  private dbInit = false;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    super('');
  }

  async initDb(
    databaseName: string = 'addianteAboutUsDB',
    storeName: string = 'aboutus'
  ): Promise<void> {
    if (!this.dbInit && isPlatformBrowser(this.platformId)) {
      this.db = new Dexie(databaseName);

      this.db.version(1).stores({
        [storeName]: '++id, company, copyright, logoDefault, logoInverted',
      });

      this.aboutUs = this.db.table(storeName);
      this.dbInit = true;
    }
  }

  async save(company: string, copyright: string, logo: Logo): Promise<void> {
    await this.initDb();

    await this.aboutUs?.put({
      id: this.aboutUsData,
      company,
      copyright,
      logoDefault: logo.default.location,
      logoInverted: logo.contrast.location,
    });
  }

  async getLogoDefault(): Promise<string | null> {
    await this.initDb();

    const logoData = await this.aboutUs?.get(this.aboutUsData);

    return logoData?.logoDefault || null;
  }

  async getLogoInverted(): Promise<string | null> {
    await this.initDb();

    const logoData = await this.aboutUs?.get(this.aboutUsData);

    return logoData?.logoInverted || null;
  }
}
