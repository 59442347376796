<div class="rental-plan">
  <app-rental-plan-option
    [form]="planForm"
    [idVehicle]="idVehicle"
    [planCart]="planCart"
    [franquiaCart]="franquiaCart"></app-rental-plan-option>

  <app-rental-quantity
    [form]="planForm"
    [quantity]="quantityCart"
    [requiresUpdateQuantity]="true"></app-rental-quantity>

  <app-rental-period
    [form]="planForm"
    [periodCart]="periodCart"
    [requiresUpdatePeriod]="true"></app-rental-period>
</div>
