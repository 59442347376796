import {
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[appNgTemplateRepeat]',
  standalone: true,
})
export class NgTemplateRepeatDirective implements OnInit {
  @Input() appNgTemplateRepeat: number = 1;

  constructor(
    private templateRef: TemplateRef<null>,
    private viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.renderizarElementos();
  }

  private renderizarElementos(): void {
    for (let i = 0; i < this.appNgTemplateRepeat; i++) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
