<app-base-skeleton *ngIf="isLoading">
  <div class="skeleton-custom-col" *appNgTemplateRepeat="quantityRepeteLoading">
    <div class="positon-relative mb-2">
      <p-skeleton width="18.75rem" height="18.75rem"></p-skeleton>

      <p-skeleton
        styleClass="position-absolute neutral-variant-95"
        width="3.9375rem"
        height="2.04256rem"></p-skeleton>
    </div>

    <div class="d-flex flex-column gap-2">
      <p-skeleton width="9.8125rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="5.9375rem" height="1.25rem"></p-skeleton>
      <p-skeleton width="3.25rem" height="1.25rem"></p-skeleton>
    </div>
  </div>
</app-base-skeleton>
