import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { buttonType } from '@shared/components/button/types/button.type';
import { IconsComponent } from '@shared/components/icons/icons.component';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, IconsComponent],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() type: string = 'button';
  @Input() tabIndex: string = '0';
  @Input() label: string = '';
  @Input() color: string = 'primary';
  @Input() layout: string = '';
  @Input() variant: buttonType = 'primary';
  @Input() disabled: boolean = false;
  @Input() content: boolean = false;
  @Input() icon: string = '';
  @Input() isShowingCloseIcon: boolean = false;
  @Input() isShowingPreviousIcon: boolean = false;
  @Input() isShowingDownloadIcon: boolean = false;
  @Input() href!: string;

  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

  getVariantClass(variant: string): string {
    switch (variant) {
      case 'primary':
        return 'bg-primary-color text-primary-color button-border-transparent';
      case 'transparentWhite':
        return 'bg-transparent text-white border-white';
      case 'transparent':
        return 'bg-transparent';
      case 'resetButton':
        return 'reset-button';
      default:
        return '';
    }
  }

  onClick(): void {
    this.buttonClick.emit();
  }
}
