import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image.component.html',
})
export class ImageComponent {
  @Input() variant: string = '';
  @Input() image: string = '';
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() altText: string = '';
}
