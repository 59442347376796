import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  BannerAdminCreateOrUpdate,
  BannerAdminParams,
  BannerAdminResponse,
} from '@modules/admin/edit/components/banner/models/banner.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BannerAdminService extends BaseApiService {
  createOrUpdate(data: BannerAdminCreateOrUpdate[]): Observable<void> {
    return this.makeRequest(
      () => this.http.post<void>(`${apiUrl}/${apiVersion}/banners/batch`, data),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  banner({ domain }: BannerAdminParams): Observable<BannerAdminResponse[]> {
    return this.makeRequest(
      () =>
        this.http.get<BannerAdminResponse[]>(
          `${apiUrl}/${apiVersion}/banners`,
          {
            params: {
              domain,
            },
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
