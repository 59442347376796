<div class="row cart-item-base base-custom">
  <div class="col-12">
    <div class="cart-item-base-col container-fluid p-0">
      <div class="cart-item-base-content base-custom-content">
        <ng-template
          *ngComponentOutlet="currentStage.component"
          [ngComponentOutletContext]="{ nextStage: nextStage }"></ng-template>
      </div>
    </div>

    <div class="cart-item-base-footer">
      <div class="row">
        <div class="col-sm-12 col-md-6 d-none d-md-block">
          <ng-container
            *ngTemplateOutlet="
              itemMessage;
              context: {
                classe: 'fs-xs lh-xs text-mountain-mist mb-0',
              }
            "></ng-container>
        </div>

        <div class="col-12 col-md-6">
          <app-line
            *ngIf="showNoChargesMessage"
            class="d-block d-md-none mb-4 cart-item-base-line"></app-line>

          <div
            class="actions d-flex align-items-center justify-content-center justify-content-md-end gap-3">
            <app-button
              *ngIf="showClearButton"
              [label]="clearButtonLabel"
              layout="button-border w-100"
              variant="transparent"
              (buttonClick)="confirmation.openModal()"></app-button>

            <app-button
              [label]="nextButtonLabel"
              layout="w-100"
              (buttonClick)="onButtonClick()"></app-button>
          </div>
        </div>

        <div class="col-12 col-md-6 d-block d-md-none">
          <ng-container
            *ngTemplateOutlet="
              itemMessage;
              context: {
                classe: 'fs-xs lh-xs text-mountain-mist text-center mb-0 mt-4',
              }
            "></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #itemMessage let-classe="classe">
  <app-paragraph *ngIf="showNoChargesMessage" [variant]="classe">
    Nenhuma cobrança será efetuada<br />
    ao prosseguir com a proposta.
  </app-paragraph>
</ng-template>

<app-modal
  #confirmation
  size="xs-2"
  variant="button-red"
  titleLabel="Deseja mesmo limpar o carrinho?"
  confirmButtonName="Limpar"
  [confirmation]="true"
  (confirmed)="onClearCart(); confirmation.closeModal()">
  Todos os itens adicionados serão excluídos de uma vez.
</app-modal>
