import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { ShowIfLoggedInDirective } from '@core/directives/show-loggedIn.directive';
import { ShowSignupGateModalDirective } from '@core/directives/show-signup-gate-modal.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import {
  StageVehicle,
  VehicleRentalLabels,
} from '@modules/pages/vehicles/types/vehicle.type';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';

@LoadingId([LoadingIdEnum.getVehicleById, LoadingIdEnum.getVehicleByIdPlans])
@Component({
  selector: 'app-card-vehicle',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    ButtonComponent,
    ShowIfLoggedInDirective,
    ShowSignupGateModalDirective,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
})
export class CardVehiclesComponent extends LoadingBaseComponent {
  @Input() titleLabel: string = '';
  @Input() typeButton: string = 'button';
  @Input() labelButton: string = VehicleRentalLabels.assemblePlan;
  @Input() currentStep: number = StageVehicle.detail;
  @Input() showBackButton: boolean = false;

  @Output() stageSelected = new EventEmitter<number>();

  loadingIdEnum = LoadingIdEnum;

  constructor(protected override loadingService: LoadingService) {
    super(loadingService);
  }

  onGoBack(): void {
    this.currentStep = StageVehicle.detail;
  }

  onButtonClick(isBack: boolean = false): void {
    if (isBack) this.currentStep = StageVehicle.detail;
    else if (!isBack && !StageVehicle.detail)
      this.currentStep = StageVehicle.rentalPlan;

    this.stageSelected.emit(this.currentStep);
  }

  clearFlowProposal(): void {
    this.stageSelected.emit(StageVehicle.detail);
  }
}
