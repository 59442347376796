import { Component, OnInit } from '@angular/core';
import { WhitelabelService } from '@core/services/whitelabels.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';

@Component({
  selector: 'app-copyright',
  standalone: true,
  imports: [ParagraphComponent],
  templateUrl: './copyright.component.html',
})
export class CopyrightComponent implements OnInit {
  company: string = '';
  copyright: string = '';

  constructor(private whitelabelService: WhitelabelService) {}

  ngOnInit(): void {
    this.whitelabelService.company$.subscribe(
      company => (this.company = company)
    );

    this.whitelabelService.copyright$.subscribe(
      copyright => (this.copyright = copyright)
    );
  }
}
