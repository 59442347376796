<div class="edit-admin-upload image">
  <mat-label class="label">{{ label }}</mat-label>

  <div class="d-flex align-items-center w-100 mt-2 gap-2">
    <app-image *ngIf="imageSrc" [image]="imageSrc" [altText]="alt"></app-image>

    <p-fileUpload
      [name]="name"
      mode="advanced"
      chooseLabel="Upload"
      cancelLabel="Remover"
      accept="image/*"
      [showUploadButton]="false"
      [showCancelButton]="false"
      (onSelect)="onFileSelect($event)" />

    <app-button
      icon="close"
      label="Remover"
      layout="button-border button-size-auto button-remove"
      variant="transparent"
      [disabled]="!showRemoveButton"
      (buttonClick)="clearPreview()"></app-button>
  </div>
</div>
