import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FilterFooterMenuParams,
  FooterMenuList,
} from '@shared/components/footer/components/footer-menu/models/footer-menu.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterMenuService {
  constructor(private http: HttpClient) { }

  getFooterMenu({
    domain = dynamicHost,
  }: FilterFooterMenuParams = {}): Observable<FooterMenuList> {
    return this.http.get<FooterMenuList>(`${apiUrl}/${apiVersion}/links`, {
      params: removeEmptyProps({ domain }),
    });
  }
}
