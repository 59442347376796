<div class="cart-item-wrapper">
  <div
    class="cart-item-content"
    *ngIf="items && items.length > 0; else proposalEmpty">
    <div
      class="cart-item d-md-flex align-items-start justify-content-between mt-5 mb-5"
      *ngFor="let item of items; let i = index">
      <div class="d-flex align-items-start gap-5">
        <div class="cart-image">
          <app-image
            [image]="item.image"
            [width]="85"
            [height]="85"
            [altText]="item.car"></app-image>
        </div>

        <div class="details">
          <h4 class="fs-md fw-500 lh-md mb-3">{{ item.car }}</h4>

          <span class="mb-3 d-block">
            <app-paragraph variant="mb-0">Manutenção:</app-paragraph>

            <app-paragraph variant="text-black fw-500">
              {{ item.manutencao }}
            </app-paragraph>
          </span>

          <span class="mb-3 d-block">
            <app-paragraph variant="mb-0">Franquia (km)</app-paragraph>

            <app-paragraph variant="text-black fw-500">
              {{ item.franquia.name }}/mês

              <span class="cart-excedent fs-xs fw-500 lh-xs text-flat-green">
                Km excedente: {{ item.franquia.excedent | brlCurrency }}
              </span>
            </app-paragraph>
          </span>

          <span>
            <app-paragraph variant="mb-0">
              Prazo de locação (meses)
            </app-paragraph>

            <app-paragraph variant="text-black fw-500">
              {{ item.rentalPeriod }}
            </app-paragraph>
          </span>

          <app-rental-quantity
            [form]="form[i]"
            [quantity]="quantities[i]"></app-rental-quantity>

          <ng-container
            *ngTemplateOutlet="
              removeItem;
              context: {
                itemId: item.id,
                classe:
                  'button-border button-size-auto d-block d-md-none mt-4 button-fixed',
              }
            "></ng-container>

          <ng-container
            *ngTemplateOutlet="
              editItem;
              context: {
                itemCart: item,
                vehicleId: item.uid,
                classe:
                  'button-border button-size-auto d-block d-md-none mt-4 button-fixed',
              }
            "></ng-container>
        </div>
      </div>

      <div
        class="d-flex flex-column justify-content-end align-items-center gap-2">
        <ng-container
          *ngTemplateOutlet="
            removeItem;
            context: {
              itemId: item.id,
              classe:
                'button-border button-size-auto d-none d-md-block button-fixed',
            }
          "></ng-container>

        <ng-container
          *ngTemplateOutlet="
            editItem;
            context: {
              itemCart: item,
              vehicleId: item.uid,
              classe:
                'button-border button-size-auto d-none d-md-block button-fixed',
            }
          "></ng-container>
      </div>
    </div>

    <app-add-more-items-button></app-add-more-items-button>
  </div>

  <ng-template #proposalEmpty>
    <app-proposal-empty></app-proposal-empty>
  </ng-template>
</div>

<ng-template #removeItem let-itemId="itemId" let-classe="classe">
  <app-button
    label="Excluir"
    icon="delete_outline"
    [layout]="classe"
    variant="transparent"
    (buttonClick)="onRemoveItem(itemId)">
  </app-button>
</ng-template>

<ng-template
  #editItem
  let-itemCart="itemCart"
  let-vehicleId="vehicleId"
  let-classe="classe">
  <app-button
    label="Editar"
    icon="mode_edit_outline"
    [layout]="classe"
    variant="transparent"
    (buttonClick)="onEditItem(itemCart, vehicleId)">
  </app-button>
</ng-template>
