<div class="footer-menu d-md-flex justify-content-between align-items-start">
  <app-filter-menu class="item-menu mb-4 mb-md-0"></app-filter-menu>

  <div class="item-menu mb-4 mb-md-0">
    <h5>Sua conta</h5>

    <ul class="list-unstyled mb-0">
      <li>
        <a
          class="text-decoration-none"
          href="#"
          (click)="
            openCreateOrLogin(
              $event,
              isLogged ? accountSummaryFlowName.users : flowName.signup
            )
          ">
          {{ isLogged ? 'Minha conta' : 'Cadastro' }}
        </a>
      </li>

      <li>
        <a
          *ngIf="!isLogged"
          class="text-decoration-none"
          href="#"
          (click)="openCreateOrLogin($event)">
          Login
        </a>
      </li>
    </ul>
  </div>

  <div class="item-menu mb-4 mb-md-0" *ngIf="aboutUsLinks">
    <h5>Institucional</h5>

    <ul class="list-unstyled mb-0">
      <li *ngFor="let aboutUsLink of aboutUsLinks">
        <a
          class="text-decoration-none"
          [href]="aboutUsLink.link"
          (keyup.enter)="handleLink(aboutUsLink.link)"
          (click)="handleLink(aboutUsLink.link)"
          [target]="aboutUsLink.link.includes('#') ? '' : '_blank'">
          {{ aboutUsLink.name }}
        </a>
      </li>
    </ul>
  </div>

  <div class="item-menu" *ngIf="socialMediaLinks">
    <h5>Siga-nos</h5>

    <ul class="list-unstyled mb-0">
      <li *ngFor="let socialMediaLink of socialMediaLinks">
        <a
          class="text-decoration-none text-capitalize"
          [href]="socialMediaLink.link"
          target="_blank">
          {{ socialMediaLink.label }}
        </a>
      </li>
    </ul>
  </div>
</div>
