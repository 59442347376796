import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { FlowTitle } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { FaqComponent } from '@modules/pages/home/components/faq/faq.component';
import { CardVehiclesComponent } from '@modules/pages/vehicles/components/card/card.component';
import { CartFlowName } from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { RentalPlanComponent } from '@modules/pages/vehicles/components/rental/rental-plan/rental-plan.component';
import { VehicleBreadcrumbComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-breadcrumb/vehicle-breadcrumb.component';
import { VehicleCarouselComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-carousel/vehicle-carousel.component';
import { VehicleInfoCardComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-info-card/vehicle-info-card.component';
import { VehicleMainImageComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-main-image/vehicle-main-image.component';
import { VehicleSpecsComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-specs/vehicle-specs.component';
import { VehicleStandardItemsComponent } from '@modules/pages/vehicles/components/vehicle/vehicle-standard-items/vehicle-standard-items.component';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';
import { FranquiaPlan } from '@modules/pages/vehicles/services/cart/models/cart.model';
import { VehicleService } from '@modules/pages/vehicles/services/vehicles.service';
import {
  StageVehicle,
  VehicleRentalLabels,
} from '@modules/pages/vehicles/types/vehicle.type';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { VehicleItems } from '@shared/components/vehicles-base/models/vehicles-base.model';
import { LineComponent } from '@shared/line/line.component';

@LoadingId(LoadingIdEnum.getVehicleById)
@Component({
  selector: 'app-vehicles-details',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MenuComponent,
    VehicleBreadcrumbComponent,
    VehicleMainImageComponent,
    VehicleCarouselComponent,
    CardVehiclesComponent,
    VehicleInfoCardComponent,
    RentalPlanComponent,
    LineComponent,
    VehicleSpecsComponent,
    VehicleStandardItemsComponent,
    FaqComponent,
  ],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss',
})
export class VehiclesDetailsComponent
  extends LoadingBaseComponent
  implements OnInit
{
  planForm!: FormGroup;
  stageVehicle = StageVehicle;
  currentStep: number = this.stageVehicle.detail;
  mainImageUrl: string = '';
  detailsVehicle!: VehicleItems;
  labels = VehicleRentalLabels;
  invalid: boolean = false;
  flowTitle: string = FlowTitle.createAccount;
  idCart: number = -1;
  planCart: string = '';
  periodCart: string = '';
  quantityCart: number = 1;
  franquia!: FranquiaPlan | null;

  constructor(
    protected override loadingService: LoadingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private vehicleService: VehicleService,
    private sidebarDrawerService: SidebarDrawerService,
    private cartService: CartService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initialForm();
    this.getVehicleDetailsByIdFromRoute();
    this.handleCartItemUpdate();
  }

  initialForm(): void {
    this.planForm = this.fb.group({});
  }

  getVehicleDetailsByIdFromRoute(): void {
    this.route.params.subscribe(params =>
      this.loadVehicleDetails(params['id'])
    );
  }

  loadVehicleDetails(id: string): void {
    this.vehicleService.getVehicleById(id).subscribe({
      next: (vehicle: VehicleItems) => {
        this.detailsVehicle = vehicle;
        this.mainImageUrl = vehicle?.images[0] || vehicle?.img;
      },
    });
  }

  updateFlowTitle(title: string): void {
    this.flowTitle = title;
  }

  handleCartItemUpdate(): void {
    this.cartService.cartItemEdit$.subscribe(item => {
      if (item) {
        const {
          itemCart: { id, manutencao, rentalPeriod, quantity, franquia },
          idVehicle,
        } = item;

        if (id) {
          this.navigationService.navigateTo('/veiculos/detalhes/', idVehicle);

          this.idCart = Number(id);
          this.planCart = manutencao;
          this.periodCart = rentalPeriod;
          this.franquia = franquia;
          this.quantityCart = Number(quantity);
          this.currentStep = 1;
        }
      }
    });
  }

  onCarouselImageClick(imageUrl: string): void {
    this.mainImageUrl = imageUrl;
  }

  onStageSelected(stage: number): void {
    this.currentStep = stage;
  }

  onSubmit(): void {
    if (this.planForm.invalid) {
      this.invalid = true;
      this.planForm.markAllAsTouched();
    } else if (this.planForm.valid) {
      this.invalid = false;

      this.sidebarDrawerService.toggle(CartFlowName.cart);

      this.cartService.addOrUpdateItem(
        this.detailsVehicle.uid,
        this.planForm,
        this.detailsVehicle.title,
        this.detailsVehicle.img,
        this.idCart
      );

      this.currentStep = 0;
      this.resetForm();
    }
  }

  resetForm(): void {
    this.planForm.reset();
    this.idCart = -1;
    this.planCart = '';
    this.periodCart = '60';
    this.franquia = null;
    this.quantityCart = 1;
    this.currentStep = 0;
  }
}
