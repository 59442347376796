import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'brlCurrency',
  standalone: true,
})
export class BRLCurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    options?: { digits?: string; locale?: string }
  ): string {
    const digitsFormat = options?.digits ?? '1.2-2';
    const locale = options?.locale ?? 'pt-BR';

    const numberFormat = new Intl.NumberFormat(locale, {
      minimumFractionDigits: Number(digitsFormat.split('.')[1].split('-')[0]),
      maximumFractionDigits: Number(digitsFormat.split('.')[1].split('-')[1]),
    });

    return `R$ ${numberFormat.format(Number(value))}`;
  }
}
