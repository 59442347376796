<app-menu-skeleton *ngIf="isLoading" class="menu-items"></app-menu-skeleton>

<ul
  *ngIf="!isLoading"
  class="menu-items"
  [ngClass]="{ 'menu-items-mobile': mobile }">
  <li
    tabindex="0"
    (keyup.enter)="handleLink(isLink, item.link)"
    (click)="handleLink(isLink, item.link)"
    *ngFor="let item of menu">
    <a
      [ngClass]="item.link === '#conheca-os-modelos' ? 'menu-vehicle' : ''"
      [href]="item.link">
      {{ item.name }}
    </a>
  </li>
</ul>
