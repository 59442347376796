<form appAutoFocus [formGroup]="form" (ngSubmit)="onSubmit()">
  <app-paragraph>{{ message }}</app-paragraph>

  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Email</mat-label>

    <input matInput required email type="email" formControlName="email" />

    <mat-error *ngIf="hasError({ form, field: 'email' })">
      E-mail é obrigatório.
    </mat-error>

    <mat-error
      *ngIf="hasError({ form, field: 'email', validation: 'invalidEmail' })">
      E-mail inválido.
    </mat-error>
  </mat-form-field>

  <div class="row">
    <div class="col-12 d-md-flex align-items-center gap-4">
      <app-button
        type="submit"
        label="Continuar"
        layout="mb-4 mb-md-0"
        [disabled]="buttonDisabled"></app-button>

      <app-paragraph variant="continue fs-xs" *ngIf="agreedToTerms">
        Ao clicar em ’continuar’ você concorda com os nossos
        <a class="text-boulder" [href]="urlPrivacyAndTerms" target="_blank">
          termos de uso
        </a>
        e
        <a class="text-boulder" [href]="urlPrivacyAndTerms" target="_blank">
          política de privacidade</a
        >.
      </app-paragraph>
    </div>
  </div>
</form>
