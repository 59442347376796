<section class="how-it-works mt-5">
  <div class="row">
    <div class="text col-md-4">
      <app-title-how-it-work-skeleton
        *ngIf="isLoading"></app-title-how-it-work-skeleton>

      <ng-container *ngIf="!isLoading">
        <ng-container *ngFor="let hotItWork of hotItWorks">
          <h2 *ngIf="hotItWork.category === 'title'">
            {{ hotItWork.step }}
          </h2>

          <app-paragraph *ngIf="hotItWork.category === 'description'">
            {{ hotItWork.step }}
          </app-paragraph>
        </ng-container>
      </ng-container>
    </div>

    <div class="badge-number col-md-5">
      <app-steps-how-it-work-skeleton
        *ngIf="isLoading"></app-steps-how-it-work-skeleton>

      <ul *ngIf="!isLoading">
        <li *ngFor="let item of hotItWorks; let i = index">
          <ng-container *ngIf="item.category === 'step'">
            <div class="line line-before">
              <div class="d-flex align-items-center pt-4 pb-4">
                <span class="number fs-lg lh-lg me-4">0{{ i - 1 }}</span>

                <span class="text fs-md lh-md">{{ item.step }}</span>
              </div>
            </div>
          </ng-container>
        </li>
      </ul>
    </div>

    <div
      class="image col-md-3 d-flex d-md-block justify-content-center justify-content-md-start">
      <app-image-how-it-work-skeleton
        *ngIf="isLoading"></app-image-how-it-work-skeleton>

      <app-image
        *ngIf="!isLoading"
        variant="rounded-4"
        altText="Imagem da um entregador"
        [width]="360"
        [height]="304"
        image="/assets/images/howitworks/image.png"></app-image>
    </div>
  </div>
</section>
