import { Component, Input } from '@angular/core';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';

@Component({
  selector: 'app-confirmation-dialog',
  standalone: true,
  imports: [ParagraphComponent],
  templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
  @Input() message: string =
    'Todos os dados preenchidos serão perdidos e caso queira você terá que iniciar um novo cadastro.';
}
