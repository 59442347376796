import { Injectable } from '@angular/core';
import { FlowService } from '@core/services/flow.service';
import { LoginComponent } from '@modules/auth/components/login/login.component';
import { CompanyDataComponent } from '@modules/auth/components/signup/components/company-data/company-data.component';
import { ConfirmEmailComponent } from '@modules/auth/components/signup/components/confirm-email/confirm-email.component';
import { ThankYouComponent } from '@modules/auth/components/signup/components/thank-you/thank-you.component';
import { SignupComponent } from '@modules/auth/components/signup/signup.component';
import {
  FlowName,
  FlowTitle,
} from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import {
  AuthFlows,
  AuthNextData,
} from '@modules/auth/services/auth-flow/models/auth-flow.model';
import { AllowedComponents } from '@modules/auth/services/auth-flow/types/auth-flow.type';

@Injectable({ providedIn: 'root' })
export class AuthFlowService {
  userName: string = '';
  userEmail: string = '';
  userStatus: string = '';
  userToken: string = '';

  constructor(private flowService: FlowService<AllowedComponents>) {}

  initFlow(): void {
    const flows: AuthFlows = {
      signup: {
        stages: [
          { title: FlowTitle.createAccount, component: SignupComponent },
          { title: FlowTitle.confirmEmail, component: ConfirmEmailComponent },
          { title: FlowTitle.companyData, component: CompanyDataComponent },
          { title: '', component: ThankYouComponent },
        ],
      },
      login: {
        stages: [
          { title: FlowTitle.login, component: LoginComponent },
          { title: FlowTitle.confirmEmail, component: ConfirmEmailComponent },
          { title: '', component: ThankYouComponent },
        ],
      },
    };

    Object.entries(flows).forEach(([flowName, flow]) =>
      this.flowService.registerFlow(flowName as FlowName, flow)
    );
  }

  getFlowService(): FlowService<AllowedComponents> {
    return this.flowService;
  }

  nextStage({ email = '', name = '', token = '' }: AuthNextData): void {
    this.flowService.nextStage();

    this.updateUserData(email, name, token);
  }

  updateUserData(email: string, name: string, token: string): void {
    this.userName = name;
    this.userEmail = email;
    this.userToken = token;
  }

  switchToSignupFlow({
    email = '',
    name = '',
    token = '',
    flowName = '',
  }: AuthNextData): void {
    this.updateUserData(email, name, token);

    this.flowService.switchToFlow();
    this.flowService.getFlow(flowName);
    this.flowService.nextStage();
  }

  get getUserEmail(): string {
    return this.userEmail;
  }

  get getUserName(): string {
    return this.userName;
  }

  get getUserToken(): string {
    return this.userToken;
  }
}
