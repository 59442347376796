import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';

@LoadingId([
  LoadingIdEnum.getUserDetailsById,
  LoadingIdEnum.createOrLoginUser,
  LoadingIdEnum.validPassword,
  LoadingIdEnum.companyData,
  LoadingIdEnum.sendProposal,
  LoadingIdEnum.updateUserData,
  LoadingIdEnum.admin,
])
@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent extends LoadingBaseComponent {}
