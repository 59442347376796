<mat-card class="vehicle-details-card">
  <mat-card-header class="align-items-center">
    <app-button
      *ngIf="currentStep > 0"
      variant="resetButton"
      layout="pe-3"
      [isShowingPreviousIcon]="true"
      (buttonClick)="onButtonClick(true)">
    </app-button>

    <span class="title fs-xl fw-600 lh-xl" *ngIf="titleLabel">
      {{ titleLabel }}
    </span>
  </mat-card-header>

  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>

  <mat-card-actions
    class="d-block mt-4 btn-action"
    *ngIf="
      !isLoading &&
      (!isLoadingById ||
        (!isLoadingById(loadingIdEnum.getVehicleById) &&
          !isLoadingById(loadingIdEnum.getVehicleByIdPlans)))
    ">
    <app-button
      layout="w-100"
      appShowSignupGateModal
      [type]="typeButton"
      [label]="labelButton"
      (loggedInAction)="onButtonClick()"></app-button>
  </mat-card-actions>
</mat-card>
