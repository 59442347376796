import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { RentalPlanDescriptionComponent } from '@modules/pages/vehicles/components/rental/rental-plan-option/components/plan-description/plan-description.component';
import {
  PlansData,
  RentalPlan,
} from '@modules/pages/vehicles/components/rental/rental-plan-option/models/rental-plan-option.model';
import { FormUtils } from '@modules/pages/vehicles/components/rental/utils/rental.util';
import { FranquiaPlan } from '@modules/pages/vehicles/services/cart/models/cart.model';
import { VehicleService } from '@modules/pages/vehicles/services/vehicles.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { PlanSkeletonComponent } from '@shared/components/skeleton/plan/plan-skeleton.component';
import {
  Franquias,
  VehicleItems,
} from '@shared/components/vehicles-base/models/vehicles-base.model';
import { BRLCurrencyPipe } from '@shared/pipes/brl-currency.pipe';

@LoadingId(LoadingIdEnum.getVehicleByIdPlans)
@Component({
  selector: 'app-rental-plan-option',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ParagraphComponent,
    ButtonComponent,
    ModalComponent,
    BRLCurrencyPipe,
    RentalPlanDescriptionComponent,
    PlanSkeletonComponent,
  ],
  templateUrl: './rental-plan-option.component.html',
  styleUrl: './rental-plan-option.component.scss',
})
export class RentalPlanOptionComponent
  extends LoadingBaseComponent
  implements OnInit
{
  @Input() form!: FormGroup;
  @Input() idVehicle: string = '';
  @Input() planCart: string = '';
  @Input() franquiaCart!: FranquiaPlan | null;
  @Output() planSelected = new EventEmitter<RentalPlan>();
  @Output() franchisesPlanSelected = new EventEmitter<RentalPlan>();

  @ViewChild('modalDescription') modalDescription!: ModalComponent;

  maintenancePlans: RentalPlan[] = [];
  franchises: Franquias[] = [];
  planIndex!: number;
  selectedPlan!: RentalPlan;
  selectedFranchisesPlan!: Franquias;
  planData!: PlansData;
  maintenancePlansRequired: boolean = false;
  franchisesRequired: boolean = false;
  rentalPlanOptionMaintenancePlans: string = 'rentalPlanOptionMaintenancePlans';
  rentalPlanOptionFranchises: string = 'rentalPlanOptionFranchises';

  constructor(
    protected override loadingService: LoadingService,
    private vehicleService: VehicleService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.addControlToFormMaintenance();
    this.getMaintenancePlans();
  }

  addControlToFormMaintenance(): void {
    FormUtils.addControlToForm(
      this.form,
      this.rentalPlanOptionMaintenancePlans,
      null,
      true
    );

    this.maintenancePlansRequired =
      this.form
        ?.get(this.rentalPlanOptionMaintenancePlans)
        ?.hasValidator(Validators.required) || false;
  }

  addControlToFormFranchises(): void {
    FormUtils.addControlToForm(
      this.form,
      this.rentalPlanOptionFranchises,
      null,
      true
    );

    this.franchisesRequired =
      this.form
        ?.get(this.rentalPlanOptionFranchises)
        ?.hasValidator(Validators.required) || false;
  }

  getMaintenancePlans(): void {
    this.vehicleService
      .getVehicleById(this.idVehicle, LoadingIdEnum.getVehicleByIdPlans)
      .subscribe({
        next: (vehicle: VehicleItems) => {
          this.maintenancePlans = vehicle?.manutencoes;

          if (this.planCart)
            this.maintenancePlans.find(item => {
              if (item.tipo === this.planCart) {
                this.selectPlan(item);
                this.selectPlanByName(this.planCart);
              }
            });
        },
      });
  }

  updateFranchisesByPlan(planName: string): void {
    const selectedPlanData = this.maintenancePlans.find(
      plan => plan.tipo === planName
    );

    this.addControlToFormFranchises();

    if (selectedPlanData) {
      this.franchises = selectedPlanData.franquias;

      this.selectFranchisesByName();
    } else this.franchises = [];
  }

  selectPlan(plan: RentalPlan): void {
    this.maintenancePlansRequired = false;
    this.selectedPlan = plan;
    this.planSelected.emit(this.selectedPlan);

    this.updateFranchisesByPlan(plan.tipo);
  }

  selectFranchisesPlan(plan: Franquias): void {
    this.maintenancePlansRequired = false;
    this.franchisesRequired = false;
    this.selectedFranchisesPlan = plan;

    this.franchisesPlanSelected.emit(this.selectedPlan);
  }

  selectPlanByName(planName: string): void {
    const planSelected = this.maintenancePlans.find(
      item => item.tipo === planName
    );

    if (this.form) {
      const control = this.form.get(this.rentalPlanOptionMaintenancePlans);

      if (planSelected && control) control.setValue(planSelected);
    }
  }

  selectFranchisesByName(): void {
    this.maintenancePlans.find(item =>
      item.franquias.map(item => {
        if (this.form && item.franquia === Number(this.franquiaCart?.name)) {
          const control = this.form.get(this.rentalPlanOptionFranchises);

          if (control) {
            control.setValue(item);
            this.selectFranchisesPlan(item);
          }
        }

        return item;
      })
    );
  }

  onOpenModal(index: number): void {
    this.planIndex = index === 0 ? 1 : 0;
    this.modalDescription.openModal();
  }
}
