<div class="company-data">
  <app-paragraph>
    Para concluir o cadastro, preencha as informações da empresa.
  </app-paragraph>

  <form appAutoFocus [formGroup]="companyForm" (ngSubmit)="onSubmit()">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>CNPJ</mat-label>

      <input
        matInput
        type="text"
        mask="00.000.000/0000-00"
        formControlName="cnpj" />

      <mat-error *ngIf="hasError({ form: companyForm, field: 'cnpj' })">
        CNPJ é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Razão Social</mat-label>

      <input matInput type="text" formControlName="companyName" />

      <mat-error *ngIf="hasError({ form: companyForm, field: 'companyName' })">
        Razão social é obrigatória.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Telefone</mat-label>

      <input
        matInput
        type="text"
        mask="(00) 00000-0000"
        formControlName="phone" />

      <mat-error *ngIf="hasError({ form: companyForm, field: 'phone' })">
        Telefone é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome do Responsável</mat-label>

      <input matInput type="text" formControlName="responsiblePerson" />

      <mat-error
        *ngIf="hasError({ form: companyForm, field: 'responsiblePerson' })">
        Nome do responsável é obrigatório.
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cargo</mat-label>

      <input matInput type="text" formControlName="position" />

      <mat-error *ngIf="hasError({ form: companyForm, field: 'position' })">
        Cargo é obrigatório.
      </mat-error>
    </mat-form-field>

    <app-button
      type="submit"
      label="Continuar"
      [disabled]="companyForm.invalid"></app-button>
  </form>
</div>
