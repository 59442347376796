import { Component } from '@angular/core';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { IconsComponent } from '@shared/components/icons/icons.component';

@Component({
  selector: 'app-proposal-empty',
  standalone: true,
  imports: [ParagraphComponent, IconsComponent],
  templateUrl: './proposal-empty.component.html',
  styleUrl: './proposal-empty.component.scss',
})
export class ProposalEmptyComponent {}
