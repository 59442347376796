import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FilterRentalAdvantagesParams,
  RentalAdvantagesList,
} from '@modules/pages/home/components/rental-advantages/models/rental-advantages.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RentalAdvantagesService {
  constructor(private http: HttpClient) { }

  getRentalAdvantages({
    domain = dynamicHost,
  }: FilterRentalAdvantagesParams = {}): Observable<RentalAdvantagesList[]> {
    return this.http.get<RentalAdvantagesList[]>(
      `${apiUrl}/${apiVersion}/advantages`,
      {
        params: removeEmptyProps({ domain }),
      }
    );
  }
}
