<app-base-skeleton *ngIf="isLoading" [reset]="true">
  <div
    class="skeleton-custom-col skeleton-custom-card-info"
    *appNgTemplateRepeat="quantityRepeteLoading">
    <div class="header d-flex justify-content-between">
      <div class="title d-flex align-items-start flex-column gap-2">
        <p-skeleton
          styleClass="neutral-variant-95"
          width="5rem"
          height="1rem"></p-skeleton>

        <p-skeleton
          styleClass="neutral-variant-95"
          width="3rem"
          height="1rem"></p-skeleton>
      </div>

      <p-skeleton
        styleClass="neutral-variant-95"
        width="3rem"
        height="2rem"></p-skeleton>
    </div>

    <div class="content mt-3 mb-3 w-100">
      <p-skeleton
        styleClass="neutral-variant-95"
        width="100%"
        height="4rem"></p-skeleton>
    </div>

    <div class="d-flex flex-column gap-2">
      <p-skeleton
        styleClass="neutral-variant-95"
        width="4rem"
        height="1.5rem"></p-skeleton>

      <p-skeleton
        styleClass="neutral-variant-95"
        width="8rem"
        height="1.25rem"></p-skeleton>

      <p-skeleton
        styleClass="neutral-variant-95"
        width="100%"
        height="3rem"></p-skeleton>
    </div>
  </div>
</app-base-skeleton>
