import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { AboutUs } from '@modules/pages/home/components/about-us/models/about-us.model';
import { AboutUsDataService } from '@modules/pages/home/components/about-us/services/about-us-data.service';
import { ImageComponent } from '@shared/components/image/image.component';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule, ImageComponent, ParagraphComponent],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent implements OnInit {
  aboutUs!: AboutUs;

  constructor(private aboutUsDataService: AboutUsDataService) {}

  ngOnInit(): void {
    this.getAboutUs();
  }

  getAboutUs(): void {
    this.aboutUsDataService.aboutUsUpdated.subscribe(
      aboutUs => (this.aboutUs = aboutUs)
    );
  }

  getBackgroundImageUrl(imageUrl: string): string {
    return `url(${imageUrl}) center/cover no-repeat`;
  }
}
