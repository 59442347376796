import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhitelabelService {
  private companySource = new BehaviorSubject<string>('');
  company$ = this.companySource.asObservable();

  private copyrightSource = new BehaviorSubject<string>('');
  copyright$ = this.copyrightSource.asObservable();

  changeData(company: string, copyright: string): void {
    this.companySource.next(company);
    this.copyrightSource.next(copyright);
  }
}
