<section class="vehicle-all header">
  <app-menu [menuDark]="true"></app-menu>
</section>

<section class="pb-md-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-vehicle-base
          titleLabel="Todos os veículos"
          [currentPage]="currentPage"
          [pageSize]="pageSize"
          [showButton]="false"
          [showFilters]="true"
          [useSlide]="false"
          [isDifferentCard]="true"
          [quantityRepeteLoading]="12"
          (totalItems)="updateTotalItems($event)"></app-vehicle-base>
      </div>
    </div>

    <div class="col-12">
      <app-paginator
        [totalItems]="totalItems"
        (pageChange)="onPageChange($event)"></app-paginator>
    </div>
  </div>
</section>

<section class="vehicle-details d-none d-md-block">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-line class="mb-5 d-block"></app-line>

        <app-faq></app-faq>
      </div>
    </div>
  </div>
</section>
