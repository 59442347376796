import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ShowIfLoggedInDirective } from '@core/directives/show-loggedIn.directive';
import { ShowSignupGateModalDirective } from '@core/directives/show-signup-gate-modal.directive';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { VehicleItems } from '@shared/components/vehicles-base/models/vehicles-base.model';
import { BRLCurrencyPipe } from '@shared/pipes/brl-currency.pipe';

@Component({
  selector: 'app-how-it-work-card',
  standalone: true,
  imports: [
    CommonModule,
    ImageComponent,
    ParagraphComponent,
    ButtonComponent,
    BRLCurrencyPipe,
    ShowIfLoggedInDirective,
    ShowSignupGateModalDirective,
  ],
  templateUrl: './how-it-work-card.component.html',
  styleUrl: './how-it-work-card.component.scss',
})
export class VehicleHowItWorkCardComponent {
  @Input() vehicle!: VehicleItems;

  constructor(private navigationService: NavigationService) {}

  openDetailsVehicle(): void {
    this.navigationService.navigateTo('#como-funciona');
  }
}
