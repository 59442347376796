import { Component } from '@angular/core';
import { FooterMenuComponent } from '@shared/components/footer/components/footer-menu/footer-menu.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { LineComponent } from '@shared/line/line.component';
import { CopyrightComponent } from './components/copyright/copyright.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    LineComponent,
    LogoComponent,
    CopyrightComponent,
    FooterMenuComponent,
  ],
  templateUrl: './footer.component.html',
})
export class FooterComponent {}
