<div class="filters">
  <mat-form-field
    appearance="outline"
    class="mat-form-field-platinum rounded-md"
    *ngFor="let filterKey of filterKeys as keyof">
    <mat-label>{{ filterKey | filterName }}</mat-label>

    <mat-select
      [ngModel]="(selectedValues$ | async)?.[filterKey]"
      (selectionChange)="
        onOptionChange($event, filterKeyDisplayName[filterKey])
      ">
      <mat-option
        [value]="option.value"
        *ngFor="let option of filterOptions[filterKey]">
        {{ option.label }}
      </mat-option>
    </mat-select>

    <app-icons
      *ngIf="(selectedValues$ | async)?.[filterKey]"
      matSuffix
      icon="clear"
      class="mat-suffix-custom"
      (click)="onClear($event, filterKey)"></app-icons>
  </mat-form-field>
</div>
