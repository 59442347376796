import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { TitleSkeletonComponent } from '@shared/components/skeleton/title/title-skeleton.component';

@LoadingId(LoadingIdEnum.getVehicleById)
@Component({
  selector: 'app-vehicle-standard-items',
  standalone: true,
  imports: [CommonModule, ParagraphComponent, TitleSkeletonComponent],
  templateUrl: './vehicle-standard-items.component.html',
  styleUrl: './vehicle-standard-items.component.scss',
})
export class VehicleStandardItemsComponent extends LoadingBaseComponent {
  @Input() items: string[] = [];
}
