<div class="observation-form mt-5">
  <app-progressbar></app-progressbar>

  <div class="d-flex align-items-center justify-content-center flex-column">
    <h3 class="text-black fs-xl fw-600 lh-xl mt-5 mb-5 d-none d-md-block">
      Observações
    </h3>

    <form appAutoFocus [formGroup]="form" class="w-100 mt-5 mt-md-0">
      <mat-form-field appearance="outline" class="observation-field w-100 mb-2">
        <mat-label>Escreva aqui suas observações</mat-label>

        <textarea
          matInput
          formControlName="observation"
          rows="5"
          class="fs-md lh-md"></textarea>
      </mat-form-field>

      <app-paragraph variant="mb-0 fs-xs">* Campo é opcional.</app-paragraph>
    </form>
  </div>
</div>
