<app-plan-description-skeleton
  *ngIf="isLoading"></app-plan-description-skeleton>

<section class="plan-description" *ngIf="!isLoading && planData">
  <h2 class="fw-700">{{ planData.name }}</h2>

  <div
    class="text-description"
    [innerHtml]="sanitizedHtmlService.sanitizedHtml(planData.html)"></div>
</section>
