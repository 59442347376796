import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CartItem } from '@modules/pages/vehicles/services/cart/models/cart.model';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root',
})
export class CartDatabaseService extends Dexie {
  cart!: Dexie.Table<CartItem, number>;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    super('addianteCartDB');

    if (isPlatformBrowser(this.platformId))
      this.version(1).stores({
        cart: '++id, uid, car, image, quantity, rentalPeriod, manutencao, franquia.name, franquia.excedent, [uid+manutencao], [rentalPeriod], [franquia.name], [franquia.excedent]',
      });
  }
}
