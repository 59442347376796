import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { RentalAdvantagesList } from '@modules/pages/home/components/rental-advantages/models/rental-advantages.model';
import { RentalAdvantagesService } from '@modules/pages/home/components/rental-advantages/services/rental-advantages.service';
import { ImageComponent } from '@shared/components/image/image.component';

@Component({
  selector: 'app-rental-advantages',
  standalone: true,
  imports: [CommonModule, ParagraphComponent, ImageComponent],
  templateUrl: './rental-advantages.component.html',
  styleUrl: './rental-advantages.component.scss',
})
export class RentalAdvantagesComponent implements OnInit {
  rentalAdvantages: RentalAdvantagesList[] = [];
  filteredAdvantages: RentalAdvantagesList[] = [];

  constructor(private rentalAdvantagesService: RentalAdvantagesService) {}

  ngOnInit(): void {
    this.getRentalAdvantages();
  }

  getRentalAdvantages(): void {
    this.rentalAdvantagesService.getRentalAdvantages().subscribe({
      next: (advantages: RentalAdvantagesList[]) => {
        this.rentalAdvantages = advantages;

        this.filteredAdvantages = advantages.filter(
          item => item.category === 'advantage'
        );
      },
    });
  }
}
