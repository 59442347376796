import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Logo } from '@modules/pages/home/components/about-us/models/about-us.model';
import { AboutUsDataService } from '@modules/pages/home/components/about-us/services/about-us-data.service';
import { AboutUsDatabaseService } from '@modules/pages/home/components/about-us/services/about-us-db.service';
import { ImageComponent } from '@shared/components/image/image.component';

@Component({
  selector: 'app-logo',
  standalone: true,
  imports: [CommonModule, ImageComponent],
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent implements OnInit {
  @Input() url: string = '/';
  @Input() variant: string = 'default';
  @Input() imageUrl: string | null = '';
  @Input() width: number = 160;
  @Input() height: number = 40;
  @Input() altText: string = 'Imagem do logo';
  @Input() isLogoLoader: boolean = true;

  logo!: Logo;

  constructor(
    private cdr: ChangeDetectorRef,
    private aboutUsDataService: AboutUsDataService,
    private aboutUsDbService: AboutUsDatabaseService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.aboutUsDbService.initDb();
    await this.getAboutUs();
  }

  async getLogoDefault(): Promise<string | null> {
    const image = await this.aboutUsDbService.getLogoDefault();

    return image;
  }

  async getLogoInverted(): Promise<string | null> {
    const image = await this.aboutUsDbService.getLogoInverted();

    return image;
  }

  async getLogoUrl(): Promise<void | null> {
    if (!this.imageUrl)
      if (this.variant === 'negative') {
        if (this.logo?.default?.location)
          this.imageUrl = this.logo.default.location;
        else
          await this.aboutUsDbService
            .getLogoDefault()
            .then(logo => (this.imageUrl = logo));
      } else {
        if (this.logo?.contrast?.location)
          this.imageUrl = this.logo.contrast.location;
        else
          await this.aboutUsDbService
            .getLogoInverted()
            .then(logo => (this.imageUrl = logo));
      }

    this.cdr.detectChanges();
  }

  async getAboutUs(): Promise<void> {
    this.aboutUsDataService.aboutUsUpdated.subscribe(aboutUs => {
      this.logo = aboutUs.logo;

      this.getLogoUrl();
    });

    if (!this.logo) {
      if (this.isLogoLoader) await this.aboutUsDataService.getAboutUs();

      await this.getLogoUrl();
    }
  }
}
