import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { TokenService } from '@core/services/auth/token.service';
import { LoadingService } from '@core/services/loading.service';
import { NotificationService } from '@core/services/notification.service';
import { hasFieldValidationError } from '@core/utils/validation-helpers/form-validation.util';
import { ValidationSchema } from '@core/utils/validation-helpers/models/validation-schema.model';
import { AccountSummaryFlowService } from '@modules/pages/account-summary/components/account-summary-base/services/account-summary.service';
import { UserProfileService } from '@modules/pages/account-summary/components/user-profile-card/services/user-profile-card.service';
import { EditProfileFormService } from '@modules/pages/edit-profile-form/services/edit-profile-form.service';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { CnpjPipe } from '@shared/pipes/cnpj-format.pipe';
import { PhonePipe } from '@shared/pipes/phone-format.pipe';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@LoadingId(LoadingIdEnum.updateUserData)
@Component({
  selector: 'app-edit-profile-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NgxMaskDirective,
    CnpjPipe,
    PhonePipe,
  ],
  providers: [provideNgxMask()],
  templateUrl: './edit-profile-form.component.html',
})
export class EditProfileFormComponent
  extends LoadingBaseComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  hasError: (params: ValidationSchema) => boolean = hasFieldValidationError;

  private destroy$ = new Subject<void>();

  constructor(
    protected override loadingService: LoadingService,
    private fb: FormBuilder,
    private tokenService: TokenService,
    private notification: NotificationService,
    private accountSummaryFlowService: AccountSummaryFlowService,
    private userProfileService: UserProfileService,
    private editProfileFormService: EditProfileFormService
  ) {
    super(loadingService);

    this.form = this.fb.group({
      email: [{ value: '', disabled: true }],
      entity: this.fb.group({
        nationalRegistry: this.fb.group({
          number: [{ value: '', disabled: true }],
        }),
        name: [{ value: '', disabled: true }],
      }),
      phone: ['', [Validators.required]],
      name: ['', Validators.required],
      role: ['', Validators.required],
      acceptedTerm: [null, Validators.required],
    });
  }

  override ngOnInit(): void {
    this.getUserDetailsById();
    this.subscribeToEditUserChanges();
    this.accountSummaryFlowService.disableButton(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  subscribeToEditUserChanges(): void {
    this.accountSummaryFlowService
      .getUserData()
      .pipe(takeUntil(this.destroy$))
      .subscribe(update => update && this.onSubmit());
  }

  updateField(): void {
    this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(
        value => value && this.accountSummaryFlowService.disableButton()
      );
  }

  getUserDetailsById(): void {
    this.userProfileService
      .getUserDetailsById()
      .pipe(takeUntil(this.destroy$))
      .subscribe(userData => {
        this.form.patchValue(userData);

        this.updateField();
      });
  }

  onSubmit(): void {
    if (this.form.valid)
      this.editProfileFormService
        .updateUserData({
          ...this.form.value,
          ...this.form.getRawValue(),
        })
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.tokenService.updateUserName(this.form.value.name);
            this.accountSummaryFlowService.disableButton(true);
            this.notification.showToast('Cadastro atualizado.');
          },
        });
  }

  get cnpj(): string {
    return this.form?.get('entity.nationalRegistry')?.get('number')?.value;
  }

  get phone(): string {
    return this.form?.get('phone')?.value;
  }
}
