import { environment } from '@environment/environment';

export const apiVersion = environment.version;
export const apiDomain = environment.apiDomain;
export const apiUrl = environment.apiUrl;
export const apiAuth = environment.apiAuth;
export const authSend = environment.apiAuthSend;
export const authVerify = environment.apiAuthVerify;
export const bdAuth = environment.bdAuth;
export const whiteLabel = environment.whiteLabel;
export const admin = environment.admin;
export const adminLogin = environment.adminLogin;
export const adminEdit = environment.adminEdit;
export const logoUrl = environment.logoUrl;
export const dynamicHost = environment.dynamicHost;
