import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarDrawerService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();

  private contentSubject = new BehaviorSubject<string | null>(null);
  content$ = this.contentSubject.asObservable();

  close(): void {
    this.isOpenSubject.next(false);
    this.contentSubject.next(null);
  }

  toggle(content: string | null = null): void {
    this.isOpenSubject.next(!this.isOpenSubject.value);
    this.contentSubject.next(content);
  }
}
