import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { TokenService } from '@core/services/auth/token.service';
import { getFirstName } from '@core/utils/firstName.util';
import { AccountSummaryFlowName } from '@modules/pages/account-summary/components/account-summary-base/enums/account-summary.enum';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-icon-user-profile',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './icon-user-profile.component.html',
})
export class IconUserProfileComponent implements OnInit {
  userName: string = 'Usuário';

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private sidebarDrawerService: SidebarDrawerService
  ) {}

  ngOnInit(): void {
    this.getUserName();
  }

  async getUserName(): Promise<void> {
    this.tokenService.updateUserName$.subscribe(
      newName => newName && (this.userName = getFirstName(newName))
    );

    const fullName = (await this.authService.userData()) || '';

    this.userName = getFirstName(fullName);
  }

  openEditUserPerfil(): void {
    this.sidebarDrawerService.toggle(AccountSummaryFlowName.users);
  }
}
