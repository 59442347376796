import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgTemplateRepeatDirective } from '@core/directives/ng-template-repeat.directive';
import { BaseSkeletonComponent } from '@shared/components/skeleton/base/base-skeleton.component';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-options-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    SkeletonModule,
    BaseSkeletonComponent,
    NgTemplateRepeatDirective,
  ],
  templateUrl: './options-skeleton.component.html',
  styleUrl: './options-skeleton.component.scss',
})
export class OptionsSkeletonComponent {
  @Input() isLoading: boolean = true;
  @Input() quantityRepeteLoading: number = 2;
  @Input() circle: string = 'circle';
  @Input() width: string = '1.5rem';
  @Input() height: string = '1.5rem';
  @Input() reset: boolean = false;
}
