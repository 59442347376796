<mat-icon
  *ngIf="!isShowingCloseIcon && !isShowingPreviousIcon"
  aria-hidden="false"
  aria-label="Ícone"
  class="icon-custom"
  >{{ icon }}</mat-icon
>

<span
  *ngIf="isShowingCloseIcon"
  class="icon-custom icon-svg icon-close rounded-circle">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#0E2000" />
  </svg>
</span>

<span
  *ngIf="isShowingPreviousIcon"
  class="icon-custom icon-svg icon-previous rounded-circle">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none">
    <path
      d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
      fill="#0E2000" />
  </svg>
</span>

<span *ngIf="isShowingDownloadIcon" class="icon-custom">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      d="M13.5 1.5H7.5L3 6V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V3C15 2.175 14.325 1.5 13.5 1.5ZM13.5 3V15H4.5V6.6225L8.1225 3H13.5Z"
      fill="black" />
    <path
      d="M12 9.75L9 12.75L6 9.75L7.0575 8.6925L8.25 9.8775V6.765L9.75 6.75V9.8775L10.9425 8.685L12 9.75Z"
      fill="black" />
  </svg>
</span>
