import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { VehicleItems } from '@shared/components/vehicles-base/models/vehicles-base.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VehicleService extends BaseApiService {
  getVehicleById(
    id: string,
    loading: string = LoadingIdEnum.getVehicleById
  ): Observable<VehicleItems> {
    return this.makeRequest(
      () =>
        this.http.get<VehicleItems>(
          `${apiUrl}/${apiVersion}/proposals/parameters/${id}`
        ),
      loading
    );
  }
}
