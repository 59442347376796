import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ProgressbarComponent } from '@modules/pages/vehicles/components/proposal/progressbar/progressbar.component';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';

@Component({
  selector: 'app-observation-form',
  standalone: true,
  imports: [
    CommonModule,
    ProgressbarComponent,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    AutoFocusDirective,
    ParagraphComponent,
  ],
  templateUrl: './observation-form.component.html',
  styleUrl: './observation-form.component.scss',
})
export class ObservationFormComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.initialForm();
  }

  initialForm(): void {
    this.form = this.fb.group({ observation: [''] });

    this.cartService.formGroup(this.form);
  }
}
