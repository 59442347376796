<section class="admin">
  <div class="container p-5">
    <div class="row">
      <div class="col-12 mb-5">
        <app-logo
          [imageUrl]="logoUrl"
          [url]="'/' + adminUrl + '/' + adminWhiteLabelUrl"></app-logo>
      </div>

      <div class="col-12 col-md-6">
        <h1>White label</h1>
      </div>

      <div class="col-12 col-md-6 d-flex justify-content-end">
        <app-button
          icon="add_24px"
          label="Adicionar"
          (buttonClick)="onAddNew()"></app-button>
      </div>

      <div class="col-12 mt-4">
        <app-adm-table></app-adm-table>
      </div>
    </div>
  </div>
</section>
