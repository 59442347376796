export enum CartFlowName {
  cart = 'cart',
}

export enum CartFlowTitle {
  cart = 'Carrinho',
  observation = 'Observação',
  sent = 'Proposta enviada!',
  clear = 'Limpar',
}

export enum CartFlowButtons {
  close = 'Fechar',
  catalog = 'Ver catálogo',
  clear = 'Limpar',
  advance = 'Avançar',
  back = 'Voltar',
  finish = 'Concluir',
  save = 'Salvar',
}
