import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
  standalone: true,
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const focusableElement = this.findFirstFocusableElement();

    if (focusableElement) focusableElement.focus();
  }

  private findFirstFocusableElement(): HTMLElement | null {
    const possibleSelectors = ['input', 'textarea', 'mat-input input'];

    for (const selector of possibleSelectors) {
      const element: HTMLElement | null =
        this.el.nativeElement.querySelector(selector);

      if (element) return element;
    }

    return null;
  }
}
