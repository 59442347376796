<section class="admin edit-admin-hot-it-works row" [formGroup]="form">
  <div class="col-12 col-md-6">
    <h2>Como funciona</h2>
  </div>

  <div class="col-12 col-md-6" formGroupName="howItWork">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Título</mat-label>

      <input matInput type="text" formControlName="title" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Descrição</mat-label>

      <input matInput type="text" formControlName="description" />
    </mat-form-field>

    <div formArrayName="steps">
      <ng-container
        *ngFor="let step of steps.controls; let i = index"
        [formGroupName]="i">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Passo {{ i + 1 }}</mat-label>

          <input matInput type="text" formControlName="step" />
        </mat-form-field>
      </ng-container>
    </div>
  </div>
</section>
