<div class="proposal-sent mt-5">
  <app-progressbar [value]="100"></app-progressbar>

  <div class="d-flex align-items-center justify-content-center flex-column">
    <h3 class="text-black fs-xl fw-600 lh-xl mt-5 mb-3">Proposta enviada!</h3>

    <app-paragraph variant="mb-0">
      Os dados da sua proposta foram enviados com sucesso.
    </app-paragraph>

    <app-paragraph variant="mb-0">
      Enviaremos o orçamento completo por e-mail.
    </app-paragraph>

    <app-paragraph variant="mb-2 mt-4">Número da proposta</app-paragraph>

    <span class="label">{{ getIdProposal }}</span>
  </div>
</div>
