import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SanitizedHtmlService } from '@core/services/sanitized-html.service';

@Component({
  selector: 'app-paragraph',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './paragraph.component.html',
})
export class ParagraphComponent {
  @Input() html: string = '';
  @Input() variant: string = 'mb-4';

  constructor(public sanitizedHtmlService: SanitizedHtmlService) {}
}
