import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import { ItemFilterMenuMenu } from '@shared/components/footer/components/filter-menu/models/filter-menu.model';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FilterMenuService {
  private filterMenuData$ = new BehaviorSubject<ItemFilterMenuMenu | null>(
    null
  );

  constructor(private http: HttpClient) {
    this.fetchFilterMenuData();
  }

  private fetchFilterMenuData(): void {
    this.http
      .get<ItemFilterMenuMenu>(
        `${apiUrl}/${apiVersion}/proposals/parameters/metadata/filters/get`,
        {
          params: removeEmptyProps({
            domain: dynamicHost
          }),
        }
      )
      .pipe(shareReplay(1))
      .subscribe(data => {
        this.filterMenuData$.next(data);
      });
  }

  getFilterMenu(): Observable<ItemFilterMenuMenu | null> {
    return this.filterMenuData$.asObservable();
  }
}
