import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { UserProfileData } from '@modules/pages/account-summary/components/user-profile-card/models/user-profile.card.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService extends BaseApiService {
  getUserDetailsById(): Observable<UserProfileData> {
    return this.makeRequest(
      () =>
        this.http.get<UserProfileData>(
          `${apiUrl}/${apiVersion}/users/metadata`
        ),
      LoadingIdEnum.getUserDetailsById,
      true
    );
  }
}
