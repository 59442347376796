import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ShowSignupGateModalDirective } from '@core/directives/show-signup-gate-modal.directive';
import { AuthService } from '@core/services/auth/auth.service';
import { VerificationLinkHandlerService } from '@core/services/verification-link-handler.service';
import { sortByProperty } from '@core/utils/sort-utils';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { AccountSummaryFlowName } from '@modules/pages/account-summary/components/account-summary-base/enums/account-summary.enum';
import { FilterMenuComponent } from '@shared/components/footer/components/filter-menu/filter-menu.component';
import {
  FooterMenuList,
  ItemFooterMenu,
} from '@shared/components/footer/components/footer-menu/models/footer-menu.model';
import { FooterMenuService } from '@shared/components/footer/components/footer-menu/services/footer-menu.service';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-footer-menu',
  standalone: true,
  imports: [CommonModule, ShowSignupGateModalDirective, FilterMenuComponent],
  templateUrl: './footer-menu.component.html',
})
export class FooterMenuComponent implements OnInit {
  aboutUsLinks: ItemFooterMenu[] = [];
  socialMediaLinks: ItemFooterMenu[] = [];
  flowName = FlowName;
  accountSummaryFlowName = AccountSummaryFlowName;
  isLogged: boolean = false;

  constructor(
    private authService: AuthService,
    private verificationLinkHandlerService: VerificationLinkHandlerService,
    private footerMenuService: FooterMenuService,
    private sidebarDrawerService: SidebarDrawerService
  ) {}

  ngOnInit(): void {
    this.getToken();
    this.getFooterMenu();
  }

  getFooterMenu(): void {
    this.footerMenuService.getFooterMenu().subscribe({
      next: (footerMenu: FooterMenuList) =>
        this.sortFooterMenuItems(footerMenu),
    });
  }

  async getToken(): Promise<void> {
    this.isLogged = await this.authService.isLoggedIn();
  }

  sortFooterMenuItems(footerMenuList: FooterMenuList): void {
    this.aboutUsLinks = sortByProperty(footerMenuList.institutional, 'order');
    this.socialMediaLinks = sortByProperty(footerMenuList.media, 'order');

    this.formatLabelSocialMedia();
  }

  formatLabelSocialMedia(): void {
    this.socialMediaLinks.forEach(item => {
      const nameParts = item.name.split('-');

      if (nameParts.length === 3) item.label = nameParts[2];
    });
  }

  openCreateOrLogin(event: Event, type: string = this.flowName.login): void {
    event?.preventDefault();

    this.sidebarDrawerService.toggle(type);
  }

  handleLink(link: string = ''): void {
    this.verificationLinkHandlerService.handleVerificationLink(link, true);
  }
}
