import { Injectable } from '@angular/core';
import { NavigationService } from '@core/services/navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class VerificationLinkHandlerService {
  constructor(private navigationService: NavigationService) {}

  handleVerificationLink(link: string = '', isLink: boolean): void {
    if (isLink && !link.startsWith('mailto:'))
      this.navigationService.navigateTo(link);

    if (link.startsWith('mailto:')) window.location.href = link;
  }
}
