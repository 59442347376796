import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { admin, adminEdit } from '@api/app.api';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { BrandingAdminService } from '@modules/admin/edit/components/branding/services/branding.service';
import { FileUpload } from '@modules/admin/edit/shared/upload/models/upload.model';
import { UploadAdminComponent } from '@modules/admin/edit/shared/upload/upload.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LineComponent } from '@shared/line/line.component';
import { ColorPickerModule } from 'primeng/colorpicker';
import { distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-adm-branding',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    LineComponent,
    UploadAdminComponent,
    AutoFocusDirective,
    ColorPickerModule,
    ButtonComponent,
  ],
  templateUrl: './branding.component.html',
})
export class BrandingAdminComponent implements OnInit {
  @Output() nameChanged: EventEmitter<string> = new EventEmitter();
  @Output() tableDisabledChange: EventEmitter<boolean> = new EventEmitter();

  form!: FormGroup;
  name = '';
  logoMainSelectedImageSrc: string = '';
  logoMainSelectedImageSrcStorage: string = '';
  logoInvertedSelectedImageSrc: string = '';
  logoInvertedSelectedImageSrcStorage: string = '';
  colorButton = '';
  colorTextButton = '';
  cnpj = '';
  copyright = '';
  isEdit: boolean = false;
  domain: string = '';

  constructor(
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private brandingAdminService: BrandingAdminService
  ) {}

  ngOnInit(): void {
    this.initialForm();
    this.getBrandingByIdFromRoute();
    this.onChangeColorField('colorButtonField');
    this.onChangeColorField('colorTextButtonField');
  }

  initialForm(): void {
    this.form = this.fb.group({
      name: [this.name, Validators.required],
      colorButton: [this.colorButton],
      colorButtonField: [''],
      colorTextButton: [this.colorTextButton],
      colorTextButtonField: [''],
      cnpj: [this.cnpj],
      copyright: [this.copyright],
    });
  }

  getBrandingByIdFromRoute(): void {
    this.route.params.subscribe(
      params => params['id'] && this.getBranding(params['id'])
    );
  }

  getBranding(uid: string): void {
    this.brandingAdminService.branding(uid).subscribe({
      next: ({ name, domain, logo, palette, company, copyright }) => {
        this.isEdit = true;

        this.sendTableDisabledChange();
        this.onChangeName(name);

        this.domain = domain;

        if (logo?.default) {
          this.logoMainSelectedImageSrcStorage = logo.default.storage;
          this.logoMainSelectedImageSrc = logo.default.location;
        }

        if (logo?.contrast) {
          this.logoInvertedSelectedImageSrcStorage = logo.contrast.storage;
          this.logoInvertedSelectedImageSrc = logo.contrast.location;
        }

        this.form.patchValue({
          name,
          colorButton: palette?.primary || '',
          colorTextButton: palette?.secondary || '',
          cnpj: company,
          copyright,
        });

        this.colorButton = palette?.primary || '';
        this.colorTextButton = palette?.secondary || '';
      },
    });
  }

  sendTableDisabledChange(): void {
    this.tableDisabledChange.emit(true);
  }

  setFileSrc(fileName: string, type: string = 'logoMain'): void {
    if (type === 'logoInverted') {
      this.logoInvertedSelectedImageSrcStorage = fileName;
      this.logoInvertedSelectedImageSrc = fileName;
    } else {
      this.logoMainSelectedImageSrcStorage = fileName;
      this.logoMainSelectedImageSrc = fileName;
    }
  }

  onChangeColor(color: string = '', type: string = 'bg'): void {
    if (type === 'text') {
      this.colorTextButton = color;
      this.form.get('colorTextButton')?.patchValue(color);
    } else {
      this.colorButton = color;
      this.form.get('colorButton')?.patchValue(color);
    }
  }

  onChangeColorField(name: string): void {
    this.form
      .get(name)
      ?.valueChanges.pipe(distinctUntilChanged())
      .subscribe(color =>
        this.onChangeColor(color, name === 'colorTextButtonField' ? 'text' : '')
      );
  }

  onChangeName(name?: string): void {
    this.nameChanged.emit(name || this.form.value.name);
  }

  onUpload({ fileName }: FileUpload, type: string = 'logoMain'): void {
    this.setFileSrc(fileName, type);
  }

  onUploadClear(type: string = 'logoMain'): void {
    this.setFileSrc('', type);
  }

  onSubmit(): void {
    if (this.isEdit) this.edit();
    else this.create();
  }

  create(): void {
    this.brandingAdminService
      .create({
        name: this.form.value.name,
        company: this.form.value.cnpj,
        copyright: this.form.value.copyright,
        palette: {
          primary: this.colorButton,
          secondary: this.colorTextButton,
        },
        logo: {
          default: {
            storage: this.logoMainSelectedImageSrc,
          },
          contrast: {
            storage: this.logoInvertedSelectedImageSrc,
          },
        },
      })
      .subscribe({
        next: ({ uid, domain }) => {
          this.notificationService.showToast('Aparência salva.');
          this.sendTableDisabledChange();
          this.navigationService.navigateTo(
            `/${admin}/${adminEdit}/${uid}/${domain}`
          );
        },
      });
  }

  edit(): void {
    this.brandingAdminService
      .update({
        domain: this.domain,
        name: this.form.value.name,
        company: this.form.value.cnpj,
        copyright: this.form.value.copyright,
        palette: {
          primary: this.colorButton,
          secondary: this.colorTextButton,
        },
        logo: {
          default: {
            storage: this.logoMainSelectedImageSrcStorage,
          },
          contrast: {
            storage: this.logoInvertedSelectedImageSrcStorage,
          },
        },
      })
      .subscribe({
        next: () => this.notificationService.showToast('Aparência alterada.'),
      });
  }
}
