import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  FooterAdmin,
  FooterAdminCreateOrUpdate,
  FooterAdminParams,
} from '@modules/admin/edit/components/footer/models/footer.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FooterAdminService extends BaseApiService {
  createOrUpdate(data: FooterAdminCreateOrUpdate[]): Observable<void> {
    return this.makeRequest(
      () => this.http.post<void>(`${apiUrl}/${apiVersion}/links/batch`, data),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  footer({
    domain,
    category = 'institutional',
  }: FooterAdminParams): Observable<FooterAdmin[]> {
    return this.makeRequest(
      () =>
        this.http.get<FooterAdmin[]>(`${apiUrl}/${apiVersion}/links`, {
          params: {
            domain,
            category,
          },
        }),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
