import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { AboutUsAdminComponent } from '@modules/admin/edit/components/content/about-us/about-us.component';
import { AdvantagesAdminComponent } from '@modules/admin/edit/components/content/advantages/advantages.component';
import { HowItWorksAdminComponent } from '@modules/admin/edit/components/content/how-it-works/how-it-works.component';
import {
  AboutUsHowItWorkPostResponse,
  ContentAboutUsAdminCreateOrUpdate,
  ContentAdvantagesAdminResponse,
  ContentHowItWorkAdminResponse,
} from '@modules/admin/edit/components/content/models/content.model';
import { ContentAdminService } from '@modules/admin/edit/components/content/services/content.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { LineComponent } from '@shared/line/line.component';
import { forkJoin, Observable } from 'rxjs';

@LoadingId(LoadingIdEnum.admin)
@Component({
  selector: 'app-adm-content',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AutoFocusDirective,
    ButtonComponent,
    LineComponent,
    HowItWorksAdminComponent,
    AdvantagesAdminComponent,
    AboutUsAdminComponent,
  ],
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
})
export class ContentAdminComponent
  extends LoadingBaseComponent
  implements OnInit
{
  form!: FormGroup;
  domain: string = '';
  sectionHowItWork: string = 'homeSignedInOrOut';
  sectionAdvantages: string = 'homeSignedOut';
  imagesAdvantages: string[] = [];
  imageAboutUs: string = '';
  imageAboutUsStorage: string = '';
  howItWorkData: ContentHowItWorkAdminResponse[] = [];
  advantagesData: ContentAdvantagesAdminResponse[] = [];

  constructor(
    protected override loadingService: LoadingService,
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private contentAdminService: ContentAdminService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initialForm();
    this.getDomainByIdFromRoute();
  }

  get howItWork(): AbstractControl {
    return this.form.get('howItWork')!;
  }

  get advantages(): AbstractControl {
    return this.form.get('advantages')!;
  }

  get aboutUs(): AbstractControl {
    return this.form.get('aboutUs')!;
  }

  initialForm(): void {
    this.form = this.fb.group({});
  }

  getDomainByIdFromRoute(): void {
    this.route.params.subscribe(
      params =>
        params['domain'] && this.getDomain(params['domain'], params['id'])
    );
  }

  getDomain(domain: string, uid: string): void {
    this.domain = domain;
    this.getHowItWork(domain);
    this.getAdvantages(domain);
    this.getAboutUs(uid);
  }

  getHowItWork(domain: string): void {
    this.contentAdminService
      .howItWork({ domain, section: this.sectionHowItWork })
      .subscribe({
        next: howItWork => (this.howItWorkData = howItWork),
      });
  }

  getAdvantages(domain: string): void {
    this.contentAdminService
      .advantages({ domain, section: this.sectionAdvantages })
      .subscribe({
        next: advantages => (this.advantagesData = advantages),
      });
  }

  getAboutUs(uid: string): void {
    this.contentAdminService.aboutUs(uid).subscribe({
      next: aboutUs => {
        if (aboutUs.whoAreWe) {
          this.form.patchValue({
            aboutUs: {
              aboutUsTitle: aboutUs.whoAreWe?.title,
              aboutUsDescription: aboutUs.whoAreWe?.description,
            },
          });

          this.imageAboutUsStorage = aboutUs.whoAreWe.thumbnail?.storage;

          this.imageAboutUs = aboutUs.whoAreWe.thumbnail?.location || '';
        }
      },
    });
  }

  imagesAdvantagesChange(images: string[]): void {
    this.imagesAdvantages = images;
  }

  imageAboutUsChange(image: string): void {
    this.imageAboutUs = image;
    this.imageAboutUsStorage = image;
  }

  onSubmit(): void {
    forkJoin([
      this.howItWorksCreateOrUpdate(),
      this.advantagesCreateOrUpdate(),
      this.aboutUsCreateOrUpdate(),
    ]).subscribe(
      ([, , aboutUs]) =>
        aboutUs.name && this.notificationService.showToast('Conteúdo salvo.')
    );
  }

  howItWorksCreateOrUpdate(): Observable<void> {
    const data = [
      {
        domain: this.domain,
        step: this.howItWork.value.title,
        section: this.sectionHowItWork,
        category: 'title',
      },
      {
        domain: this.domain,
        step: this.howItWork.value.description,
        section: this.sectionHowItWork,
        category: 'description',
      },
      ...this.howItWork.value.steps
        .filter((step: { step: string }) => step.step !== '')
        .map((step: { step: string }) => ({
          domain: this.domain,
          ...step,
          section: this.sectionHowItWork,
          category: 'step',
        })),
    ];

    return this.contentAdminService.howItWorkCreateOrUpdate(data);
  }

  advantagesCreateOrUpdate(): Observable<void> {
    const data = [
      {
        domain: this.domain,
        name: this.advantages.value.advantageTitle,
        section: this.sectionAdvantages,
        category: 'title',
      },
      {
        domain: this.domain,
        name: this.advantages.value.advantageDescription,
        section: this.sectionAdvantages,
        category: 'description',
      },
      ...this.advantages.value.names
        .filter((name: { name: string }) => name.name !== '')
        .map((name: { name: string }) => ({
          domain: this.domain,
          ...name,
          section: this.sectionAdvantages,
          category: 'advantage',
        })),
    ];

    return this.contentAdminService.advantagesCreateOrUpdate(data);
  }

  aboutUsCreateOrUpdate(): Observable<AboutUsHowItWorkPostResponse> {
    const data: ContentAboutUsAdminCreateOrUpdate = {
      domain: this.domain,
      whoAreWe: {
        title: this.aboutUs.value.aboutUsTitle,
        description: this.aboutUs.value.aboutUsDescription,
        thumbnail: {
          storage: this.imageAboutUsStorage,
        },
      },
    };

    return this.contentAdminService.aboutUsUpdate(data);
  }
}
