import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { admin, adminEdit } from '@api/app.api';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { getFirstCharacters } from '@core/utils/get-first-characters.util';
import {
  WhiteLabelAdminItems,
  WhiteLabelList,
} from '@modules/admin/white-label/components/table/models/table.model';
import { WhiteLabelAdminService } from '@modules/admin/white-label/components/table/services/table.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { PaginatorCustom } from '@shared/components/paginator/models/paginator.model';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { LoadingComponent } from '@shared/components/skeleton/loading/loading.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

@LoadingId([LoadingIdEnum.admin])
@Component({
  selector: 'app-adm-table',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    TooltipModule,
    InputSwitchModule,
    LoadingComponent,
    LogoComponent,
    ButtonComponent,
    PaginatorComponent,
    ParagraphComponent,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class TableAdmComponent extends LoadingBaseComponent implements OnInit {
  dataSource!: WhiteLabelAdminItems[];
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 12;

  getFirstCharacters = getFirstCharacters;

  constructor(
    protected override loadingService: LoadingService,
    private navigationService: NavigationService,
    private whiteLabelAdminService: WhiteLabelAdminService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getWhiteLabels();
  }

  getWhiteLabels(): void {
    this.whiteLabelAdminService
      .getWhiteLabel({
        page: this.currentPage,
        perPage: this.pageSize,
      })
      .subscribe({
        next: (list: WhiteLabelList) => {
          this.dataSource = list.items;
          this.totalItems = list.setup.total;
        },
      });
  }

  getClassesStatus(status: boolean): string {
    if (status) return 'active';
    else return 'expired';
  }

  onAction(uid: string, domain: string): void {
    this.navigationService.navigateTo(
      `/${admin}/${adminEdit}/${uid}/${domain}`
    );
  }

  onPageChange({ page, pageSize }: PaginatorCustom): void {
    this.currentPage = page;
    this.pageSize = pageSize;

    this.getWhiteLabels();
  }
}
