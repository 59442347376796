import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FaqList,
  FilterFaqParams,
} from '@modules/pages/home/components/faq/models/faq.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  constructor(private http: HttpClient) { }

  getFaq({
    section = '',
    domain = dynamicHost,
  }: FilterFaqParams = {}): Observable<FaqList[]> {
    return this.http.get<FaqList[]>(
      `${apiUrl}/${apiVersion}/questions-and-answers`,
      {
        params: removeEmptyProps({ section, domain }),
      }
    );
  }
}
