import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-skeleton',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './base-skeleton.component.html',
  styleUrl: './base-skeleton.component.scss',
})
export class BaseSkeletonComponent {
  @Input() reset: boolean = false;
}
