import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ShowFooterResolver implements Resolve<boolean> {
  resolve(route: ActivatedRouteSnapshot): boolean {
    return route.data['showFooter'] !== undefined
      ? route.data['showFooter']
      : true;
  }
}
