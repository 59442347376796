import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AuthService } from '@core/services/auth/auth.service';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { IconUserProfileComponent } from '@modules/pages/user-profile/icon-user-profile/icon-user-profile.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CartIconComponent } from '@shared/components/cart/cart-icon/cart-icon.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { ItemsMenuComponent } from '@shared/components/menu/items-menu/items-menu.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { UserIconComponent } from '@shared/components/user-icon/user-icon.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    LogoComponent,
    ButtonComponent,
    ItemsMenuComponent,
    UserIconComponent,
    CartIconComponent,
    IconUserProfileComponent,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent implements OnInit {
  @Input() menuDark: boolean = false;

  isLogged: boolean = false;
  isMenuOpen = false;

  constructor(
    private authService: AuthService,
    private sidebarDrawerService: SidebarDrawerService
  ) {}

  ngOnInit(): void {
    this.getToken();
  }

  async getToken(): Promise<void> {
    this.isLogged = await this.authService.isLoggedIn();
  }

  toggleMenu(isMenuOpen: boolean = false): void {
    this.isMenuOpen = isMenuOpen || !this.isMenuOpen;
  }

  openSidebarDrawer(type: string = ''): void {
    this.sidebarDrawerService.close();

    if (type === 'new') this.sidebarDrawerService.toggle(FlowName.signup);
    else this.sidebarDrawerService.toggle(FlowName.login);
  }
}
