import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TokenData } from '@core/services/auth/models/auth.model';
import Dexie from 'dexie';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenService extends Dexie {
  tokens!: Dexie.Table<TokenData, string>;
  authToken: string = 'auth-token';

  private db!: Dexie;
  private dbInit = false;

  private updateUserNameSource = new BehaviorSubject<string>('');
  updateUserName$ = this.updateUserNameSource.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    super('');
  }

  async initDb(
    databaseName: string = 'addianteAuthDB',
    storeName: string = 'tokens'
  ): Promise<void> {
    if (!this.dbInit && isPlatformBrowser(this.platformId)) {
      this.db = new Dexie(databaseName);

      this.db.version(1).stores({
        [storeName]: '++id, token, userName, accessProfile, expiresAt',
      });

      this.tokens = this.db.table(storeName);
      this.dbInit = true;
    }
  }

  async saveToken(
    token: string,
    userName: string,
    accessProfile: string = '',
    expiresIn: number = 172800
  ): Promise<void> {
    const expiresAt = new Date();

    expiresAt.setSeconds(expiresAt.getSeconds() + expiresIn);

    await this.tokens.put({
      id: this.authToken,
      token,
      userName,
      accessProfile,
      expiresAt,
    });
  }

  async deleteToken(): Promise<void> {
    await this.tokens.delete(this.authToken);
  }

  async updateUserName(newUserName: string): Promise<void> {
    const tokenData = await this.getToken();

    if (tokenData) {
      await this.tokens.update(this.authToken, { userName: newUserName });

      this.updateUserNameSource.next(newUserName);
    }
  }

  async getToken(): Promise<string | null> {
    const tokenData = await this.tokens?.get(this.authToken);

    if (!tokenData) return null;

    if (tokenData.expiresAt < new Date()) {
      await this.tokens?.delete(this.authToken);

      return null;
    }

    return tokenData.token;
  }

  async getUserName(): Promise<string | null> {
    const userName = await this.tokens?.get(this.authToken);

    return userName?.userName || null;
  }

  async getUserAccessProfile(): Promise<string | null> {
    const userAccessProfile = await this.tokens?.get(this.authToken);

    return userAccessProfile?.accessProfile || null;
  }
}
