<div class="row account-summary-base base-custom">
  <div class="col-12">
    <div class="account-summary-base-col container-fluid p-0">
      <div class="account-summary-base-content base-custom-content">
        <ng-template
          *ngComponentOutlet="currentStage.component"
          [ngComponentOutletContext]="{ nextStage: nextStage }"></ng-template>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-12">
    <div class="actions d-flex align-items-center justify-content-end gap-3">
      <div
        class="d-flex align-items-center justify-content-end gap-3"
        *ngIf="stageEdit">
        <app-button
          *ngIf="showClearButton"
          [label]="clearButtonLabel"
          layout="button-border"
          variant="transparent"
          (buttonClick)="previousStage()"></app-button>

        <app-button
          [label]="nextButtonLabel"
          [disabled]="buttonDisabled"
          (buttonClick)="onButtonClick()"></app-button>
      </div>

      <app-button-logout
        *ngIf="!stageEdit"
        class="d-none d-md-block"></app-button-logout>
    </div>
  </div>
</div>
