<section class="advantages mt-5">
  <div class="row">
    <div class="col-md-4">
      <ng-container *ngFor="let item of rentalAdvantages">
        <h2 *ngIf="item.category === 'title'">
          {{ item.name }}
        </h2>

        <app-paragraph *ngIf="item.category === 'description'">
          {{ item.name }}
        </app-paragraph>
      </ng-container>
    </div>

    <div class="col-md-8 icons">
      <div class="row row-cols-2 row-cols-md-2 row-cols-md-3 g-4">
        <div *ngFor="let item of filteredAdvantages" class="col">
          <app-image
            variant="mb-3"
            altText="Ícone da vantagem"
            [width]="56"
            [height]="56"
            [image]="item?.icon?.location || ''"></app-image>

          <app-paragraph>{{ item.name }}</app-paragraph>
        </div>
      </div>
    </div>
  </div>
</section>
