<app-header id="header"></app-header>

<section class="container">
  <div id="como-funciona">
    <app-how-it-works></app-how-it-works>
  </div>

  <app-line></app-line>

  <div id="conheca-os-modelos">
    <app-vehicle-base [pageSize]="4"></app-vehicle-base>
  </div>

  <app-line></app-line>

  <div id="vantagens">
    <app-rental-advantages></app-rental-advantages>
  </div>

  <div id="quem-somos">
    <app-about-us class="mt-5 d-block"></app-about-us>
  </div>

  <div id="duvidas-frequentes">
    <app-faq class="mt-5 d-block"></app-faq>
  </div>
</section>
