import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NotificationService } from '@core/services/notification.service';
import { CartDatabaseService } from '@modules/pages/vehicles/services/cart/cart-db.service';
import {
  CartItem,
  EditItemCart,
} from '@modules/pages/vehicles/services/cart/models/cart.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartItemsSubject = new BehaviorSubject<CartItem[]>([]);
  cartItems$ = this.cartItemsSubject.asObservable();

  private cartClearedSubject = new Subject<boolean>();
  cartCleared$ = this.cartClearedSubject.asObservable();

  private cartItemEditSubject = new BehaviorSubject<{
    itemCart: EditItemCart;
    idVehicle: string;
  } | null>(null);
  cartItemEdit$ = this.cartItemEditSubject.asObservable();

  formSubject = new BehaviorSubject<FormGroup | undefined>(undefined);

  constructor(
    private notificationService: NotificationService,
    private cartDbService: CartDatabaseService
  ) {
    this.loadCartItems();
  }

  private async loadCartItems(): Promise<void> {
    const items = await this.cartDbService.cart?.toArray();

    this.cartItemsSubject.next(items || []);
  }

  async addOrUpdateItem(
    uid: string,
    itemForm: FormGroup,
    nameCar: string,
    image: string,
    idCart: number
  ): Promise<void> {
    const {
      quantity,
      rentalPeriod,
      rentalPlanOptionMaintenancePlans: { tipo },
      rentalPlanOptionFranchises: { franquia, km_excedente },
    } = itemForm.value;

    const item = {
      uid,
      car: nameCar,
      image,
      quantity,
      rentalPeriod,
      manutencao: tipo,
      franquia: {
        name: franquia,
        excedent: km_excedente,
      },
    };

    if (idCart >= 0) {
      await this.cartDbService.cart.update(idCart, item);

      this.notificationService.showToast('Item editado.');
    } else {
      const existingItem = await this.cartDbService.cart
        .where('uid')
        .equals(uid)
        .and(item => item.manutencao === tipo)
        .and(item => item.franquia.name === franquia)
        .and(item => item.rentalPeriod.toString() === rentalPeriod.toString())
        .and(item => item.franquia.excedent === km_excedente)
        .first();

      if (existingItem) {
        existingItem.quantity += item.quantity;
        existingItem.rentalPeriod = item.rentalPeriod;

        await this.cartDbService.cart.update(
          existingItem.id as number,
          existingItem
        );

        this.notificationService.showToast('Item atualizado.');
      } else {
        await this.cartDbService.cart?.add(item);

        this.notificationService.showToast('Item adicionado.');
      }
    }

    this.loadCartItems();
  }

  async clearCart(noMessageTost: boolean = false): Promise<void> {
    try {
      await this.cartDbService.cart?.clear();

      this.loadCartItems();

      if (!noMessageTost)
        this.notificationService.showToast('Carrinho limpo.', 'Aviso', 'info');

      this.cartClearedSubject.next(true);
    } catch (error) {
      console.error('Erro ao limpar o carrinho:', error);

      this.notificationService.showToast(
        'Não foi possível limpar o carrinho.',
        'Erro',
        'error'
      );

      this.cartClearedSubject.next(false);
    }
  }

  async removeItemById(id: number = -1): Promise<void> {
    try {
      const countBefore = await this.cartDbService.cart
        ?.where('id')
        .equals(id)
        .count();

      await this.cartDbService?.cart?.delete(id);

      const countAfter = await this.cartDbService.cart
        ?.where('id')
        .equals(id)
        .count();

      this.loadCartItems();

      if (countBefore > countAfter)
        this.notificationService.showToast('Item removido.', 'Atenção', 'warn');
      else
        this.notificationService.showToast(
          'O item não foi encontrado.',
          'Atenção',
          'error'
        );
    } catch (error) {
      console.error('Erro ao remover o item do carrinho:', error);

      this.notificationService.showToast(
        'Erro ao remover o item.',
        'Erro',
        'error'
      );
    }
  }

  async updateItemQuantity(itemId: number, newQuantity: number): Promise<void> {
    await this.cartDbService.transaction(
      'rw',
      this.cartDbService.cart,
      async () => {
        const item = await this.cartDbService.cart.get(itemId);

        if (item) {
          item.quantity = newQuantity;

          await this.cartDbService.cart.put(item);

          this.loadCartItems();
        }
      }
    );
  }

  editItem(itemCart: EditItemCart, idVehicle: string): void {
    this.cartItemEditSubject.next({
      itemCart,
      idVehicle,
    });
  }

  formGroup(form: FormGroup): void {
    this.formSubject.next(form);
  }
}
