import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { ShowIfLoggedInDirective } from '@core/directives/show-loggedIn.directive';
import { ShowSignupGateModalDirective } from '@core/directives/show-signup-gate-modal.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { VehicleInfoCard } from '@modules/pages/vehicles/components/vehicle/vehicle-info-card/models/vehicle-info-card.model';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { CardInfoSkeletonComponent } from '@shared/components/skeleton/card-info/card-info-skeleton.component';
import { BRLCurrencyPipe } from '@shared/pipes/brl-currency.pipe';

@LoadingId(LoadingIdEnum.getVehicleById)
@Component({
  selector: 'app-vehicle-info-card',
  standalone: true,
  imports: [
    CommonModule,
    ParagraphComponent,
    ImageComponent,
    ButtonComponent,
    BRLCurrencyPipe,
    ShowSignupGateModalDirective,
    ShowIfLoggedInDirective,
    CardInfoSkeletonComponent,
  ],
  templateUrl: './vehicle-info-card.component.html',
  styleUrl: './vehicle-info-card.component.scss',
})
export class VehicleInfoCardComponent extends LoadingBaseComponent {
  @Input() vehicleData!: VehicleInfoCard;
}
