import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { UploadAdminResponse } from '@modules/admin/edit/shared/upload/models/upload.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadAdminService extends BaseApiService {
  upload(file: FormData): Observable<UploadAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.post<UploadAdminResponse>(
          `${apiUrl}/${apiVersion}/upload/images`,
          file
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
