<div
  class="vehicle-main-image d-flex flex-column align-items-center justify-content-center">
  <app-image-main-skeleton [isLoading]="isLoading"></app-image-main-skeleton>

  <app-image
    *ngIf="imageUrl && !isLoading"
    [image]="imageUrl"
    [width]="700"
    [height]="700"
    altText="Imagem principal do veículo"></app-image>
</div>
