<div [formGroup]="form" class="rental-quantity d-block mt-4">
  <app-paragraph>Quantidade de veículos (un)</app-paragraph>

  <app-options-skeleton
    [isLoading]="isLoading"
    [quantityRepeteLoading]="5"></app-options-skeleton>

  <div
    class="quantity-container mt-3 d-flex align-items-center justify-content-between gap-2"
    *ngIf="!isLoading">
    <app-button
      icon="remove"
      layout="text-primary"
      variant="resetButton"
      (buttonClick)="decrement()"></app-button>

    <input
      type="number"
      id="quantity"
      appearance="none"
      class="quantity px-3 btn"
      min="1"
      [formControlName]="labelField"
      (input)="updateQuantity($event)" />

    <app-button
      icon="add"
      layout="text-primary"
      variant="resetButton"
      (buttonClick)="increment()"></app-button>
  </div>
</div>
