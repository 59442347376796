import { Component, OnInit } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { BannerActionsComponent } from '@shared/components/banner/components/banner-actions/banner-actions.component';
import { Banner } from '@shared/components/banner/models/banner.model';
import { BannerService } from '@shared/components/banner/services/banner.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { BannerSkeletonComponent } from '@shared/components/skeleton/banner/banner-skeleton.component';
import { CarouselModule } from 'primeng/carousel';

@LoadingId(LoadingIdEnum.getBanners)
@Component({
  selector: 'app-banner',
  standalone: true,
  imports: [
    ButtonComponent,
    CarouselModule,
    BannerSkeletonComponent,
    BannerActionsComponent,
  ],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
})
export class BannerComponent extends LoadingBaseComponent implements OnInit {
  banners!: Banner[];

  constructor(
    protected override loadingService: LoadingService,
    private bannerService: BannerService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getBanners();
  }

  getBanners(): void {
    this.bannerService.getBanners().subscribe({
      next: (banners: Banner[]) => (this.banners = banners),
    });
  }

  getBackgroundImageUrl(imageUrl: string): string {
    return `url(${imageUrl}) center/cover no-repeat`;
  }
}
