import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { OptionsSkeletonComponent } from '@shared/components/skeleton/options/options-skeleton.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';

@LoadingId(LoadingIdEnum.getVehicleById)
@Component({
  selector: 'app-vehicle-carousel',
  standalone: true,
  imports: [
    CommonModule,
    CarouselModule,
    ImageComponent,
    ButtonComponent,
    OptionsSkeletonComponent,
  ],
  templateUrl: './vehicle-carousel.component.html',
  styleUrl: './vehicle-carousel.component.scss',
})
export class VehicleCarouselComponent extends LoadingBaseComponent {
  @Input() images: string[] = [];
  @Input() altText: string = '';

  @Output() imageClicked = new EventEmitter<string>();

  isBrowser: boolean;
  activeSlide: number = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    protected override loadingService: LoadingService
  ) {
    super(loadingService);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  previous(): void {
    if (this.activeSlide > 0) {
      this.activeSlide--;

      this.onImageClick(this.images[this.activeSlide], this.activeSlide);
    }
  }

  next(): void {
    if (this.activeSlide < this.images.length - 1) {
      this.activeSlide++;

      this.onImageClick(this.images[this.activeSlide], this.activeSlide);
    }
  }

  onImageClick(
    imageUrl: string,
    index: number,
    event?: { stopPropagation: () => void }
  ): void {
    this.activeSlide = index;
    this.imageClicked.emit(imageUrl);

    if (event) event.stopPropagation();
  }
}
