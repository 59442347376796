import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHandlerService } from '@core/services/auth/auth-handler.service';
import { LoadingService } from '@core/services/loading.service';
import { defer, finalize, Observable } from 'rxjs';

@Injectable()
export class BaseApiService {
  constructor(
    protected http: HttpClient,
    protected loadingService: LoadingService,
    protected authHandlerService: AuthHandlerService
  ) {}

  protected makeRequest<T>(
    observableFactory: () => Observable<T>,
    loadingId: string = 'global',
    token: boolean = false,
    bd?: string
  ): Observable<T> {
    return defer(() => {
      this.loadingService.show(loadingId);
      this.authHandlerService.handleAuth(token, bd);

      return observableFactory();
    }).pipe(
      finalize(() => {
        this.loadingService.hide(loadingId);
        this.authHandlerService.handleAuth(false, bd);
      })
    );
  }
}
