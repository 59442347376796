import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FileUpload } from '@modules/admin/edit/shared/upload/models/upload.model';
import { UploadAdminComponent } from '@modules/admin/edit/shared/upload/upload.component';

@Component({
  selector: 'app-adm-about-us',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    UploadAdminComponent,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsAdminComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() imageSrcStorage: string = '';

  @Output() image: EventEmitter<string> = new EventEmitter();

  imageSelectedImageSrc: string = '';

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addControlToForm();
  }

  addControlToForm(): void {
    this.form.addControl(
      'aboutUs',
      this.fb.group({
        aboutUsTitle: '',
        aboutUsDescription: '',
      })
    );
  }

  setFileSrc(fileName: string): void {
    this.imageSelectedImageSrc = fileName;
    this.image.emit(this.imageSelectedImageSrc);
  }

  onUpload({ fileName }: FileUpload): void {
    this.setFileSrc(fileName);
  }

  onUploadClear(): void {
    this.setFileSrc('');
  }
}
