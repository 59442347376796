<ng-container *ngIf="actions && actions.length > 0">
  <app-button
    *ngIf="actions[0] && actions[0].link === loginAndSignup"
    layout="me-2"
    appShowSignupGateModal
    [label]="actions[0].title"
    [scrollOnLoggedIn]="true"></app-button>

  <app-button
    *ngIf="actions[0] && actions[0].link !== loginAndSignup"
    layout="border bg-primary-color rounded-5 text-decoration-none text-white fw-500 me-2"
    [label]="actions[0].title"
    [href]="actions[0].link"
    [attr.target]="actions[0].target || '_blank'"></app-button>

  <app-button
    *ngIf="actions[1]"
    variant="transparentWhite"
    layout="border border-white rounded-5 text-decoration-none text-white fw-500"
    [label]="actions[1].title"
    [href]="actions[1].link"
    [attr.target]="actions[1].target || '_blank'"></app-button>
</ng-container>
