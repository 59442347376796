<app-base-skeleton *ngIf="isLoading">
  <div class="skeleton-custom-col" *appNgTemplateRepeat="quantityRepeteLoading">
    <div class="d-flex gap-3">
      <p-skeleton width="2.4rem" height="1rem"></p-skeleton>
      <p-skeleton width="3.8rem" height="1rem"></p-skeleton>
      <p-skeleton width="5.5rem" height="1rem"></p-skeleton>
      <p-skeleton width="4rem" height="1rem"></p-skeleton>
      <p-skeleton width="3.5rem" height="1rem"></p-skeleton>
    </div>
  </div>
</app-base-skeleton>
