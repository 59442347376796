import { Injectable } from '@angular/core';
import { FlowIndex } from '@core/enums/flow.enum';
import { Flow, Stage } from '@core/models/flow.model';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FlowService<T> {
  currentStageIndex = FlowIndex.initial;
  currentFlow!: Flow<T>;
  currentFlowName!: string;
  currentStage!: Stage<T>;

  private currentStageSubject = new Subject<Stage<T>>();
  currentStage$ = this.currentStageSubject.asObservable();

  private flows: { [key: string]: Flow<T> } = {};

  registerFlow(flowName: string, flow: Flow<T>): void {
    this.flows[flowName] = flow;
  }

  getFlow(flowName: string): Flow<T> {
    this.currentFlowName = flowName;
    this.currentFlow = this.flows[flowName];
    this.currentStage = this.currentFlow?.stages[this.currentStageIndex];

    return this.flows[flowName];
  }

  nextStage(): void {
    this.currentStageIndex++;

    if (this.currentStageIndex >= this.currentFlow?.stages?.length) return;

    this.currentStage = this.currentFlow?.stages[this.currentStageIndex];

    this.updateCurrentStage(this.currentStage);
  }

  previousStage(): void {
    this.currentStageIndex--;

    this.currentStage = this.currentFlow?.stages[this.currentStageIndex];

    this.updateCurrentStage(this.currentStage);
  }

  updateCurrentStage(newStage: Stage<T>, index?: number): void {
    if (index) this.currentStageIndex = index;

    this.currentStage = newStage;
    this.currentStageSubject.next(this.currentStage);
  }

  switchToFlow(): void {
    this.clearStage(this.getCurrentFlowName);
    this.nextStage();
  }

  clearStage(flowName: string): void {
    this.currentStageIndex = FlowIndex.initial;
    this.updateCurrentStage(
      this.getFlow(flowName).stages[this.currentStageIndex]
    );
  }

  get getCurrentFlowName(): string {
    return this.currentFlowName;
  }

  get getCurrentStageIndex(): number {
    return this.currentStageIndex;
  }
}
