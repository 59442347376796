import { Injectable } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthHandlerService {
  constructor(private authService: AuthService) {}

  handleAuth(required: boolean = true, bd?: string): void {
    this.authService.setRequiresAuth(required, bd);
  }
}
