import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { ImageComponent } from '@shared/components/image/image.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { ImageMainSkeletonComponent } from '@shared/components/skeleton/image-main-skeleton/image-main-skeleton.component';

@LoadingId(LoadingIdEnum.getVehicleById)
@Component({
  selector: 'app-vehicle-main-image',
  standalone: true,
  imports: [CommonModule, ImageComponent, ImageMainSkeletonComponent],
  templateUrl: './vehicle-main-image.component.html',
  styleUrl: './vehicle-main-image.component.scss',
})
export class VehicleMainImageComponent extends LoadingBaseComponent {
  @Input() imageUrl: string = '';
}
