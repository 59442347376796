<app-button
  label="Sair"
  icon="logout"
  layout="button-border button-size-auto"
  variant="transparent"
  (buttonClick)="confirmation.openModal()"></app-button>

<app-modal
  #confirmation
  size="xs-2"
  variant="button-red"
  titleLabel="Deseja mesmo sair?"
  confirmButtonName="Sair"
  [confirmation]="true"
  (confirmed)="logout(); confirmation.closeModal()">
  Ao continuar você sairá da sua conta e poderá voltar a qualquer momento.
</app-modal>
