<div class="row vehicle-standard-items">
  <div class="col-md-3 col-sm-12">
    <h2 class="fh-28">Itens de série</h2>
  </div>

  <div class="col-md-9 col-sm-12 standard-item">
    <app-title-skeleton
      [isLoading]="isLoading"
      [quantityRepeteLoading]="5"
      [isLine]="true">
    </app-title-skeleton>

    <ul *ngIf="!isLoading">
      <li *ngFor="let item of items">
        <app-paragraph variant="pt-2 mb-4 fs-md lh-md text-black">
          {{ item }}
        </app-paragraph>
      </li>
    </ul>
  </div>
</div>
