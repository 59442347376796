import { Component } from '@angular/core';
import { AccountSummaryFlowService } from '@modules/pages/account-summary/components/account-summary-base/services/account-summary.service';
import { UserProfileCardComponent } from '@modules/pages/account-summary/components/user-profile-card/user-profile-card.component';
import { UserProposalComponent } from '@modules/pages/account-summary/components/user-proposal/user-proposal.component';

@Component({
  selector: 'app-account-summary',
  standalone: true,
  imports: [UserProfileCardComponent, UserProposalComponent],
  templateUrl: './account-summary.component.html',
})
export class AccountSummaryComponent {
  constructor(private accountSummaryFlowService: AccountSummaryFlowService) {}

  nextStage(): void {
    this.accountSummaryFlowService.getFlowService().nextStage();
  }

  previousStage(): void {
    this.accountSummaryFlowService.getFlowService().previousStage();
  }
}
