import { Component, ViewEncapsulation } from '@angular/core';
import { admin, logoUrl, whiteLabel } from '@api/app.api';
import { BannerAdminComponent } from '@modules/admin/edit/components/banner/banner.component';
import { BrandingAdminComponent } from '@modules/admin/edit/components/branding/branding.component';
import { ContentAdminComponent } from '@modules/admin/edit/components/content/content.component';
import { FaqAdminComponent } from '@modules/admin/edit/components/faq/faq.component';
import { FooterAdminComponent } from '@modules/admin/edit/components/footer/footer.component';
import { MenuAdminComponent } from '@modules/admin/edit/components/menu/menu.component';
import { SocialMediaAdminComponent } from '@modules/admin/edit/components/social-media/social-media.component';
import { LogoComponent } from '@shared/components/logo/logo.component';
import { TabViewModule } from 'primeng/tabview';

@Component({
  selector: 'app-adm-edit',
  standalone: true,
  imports: [
    LogoComponent,
    TabViewModule,
    BrandingAdminComponent,
    MenuAdminComponent,
    FooterAdminComponent,
    ContentAdminComponent,
    FaqAdminComponent,
    SocialMediaAdminComponent,
    BannerAdminComponent,
  ],
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class EditAdminComponent {
  label: string = 'Escolha o nome...';
  name: string = this.label;
  adminUrl = admin;
  adminWhiteLabelUrl = whiteLabel;
  isTableDisabled: boolean = true;
  logoUrl: string = logoUrl;

  nameChanged(name: string): void {
    this.name = name || this.label;
  }

  isTableDisabledChange(isActive: boolean): void {
    this.isTableDisabled = !isActive;
  }
}
