export class ButtonsPaginatorUtil {
  static getVisiblePages(
    currentPage: number,
    pages: number[],
    range: number
  ): number[] {
    const start = Math.max(0, currentPage - range);
    const end = Math.min(pages.length, currentPage + range + 1);

    return pages.slice(start, end);
  }

  static updatePages(totalPages: number): number[] {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }
}
