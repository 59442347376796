<div
  class="mt-5 d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row pagination-container"
  *ngIf="totalItems">
  <span class="pagination-info fs-xs fw-500 lh-xs mb-3 mb-md-0">
    {{ getPaginationRange() }}
  </span>

  <app-buttons-paginator
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [totalItems]="totalItems"
    (pageChange)="onPageChange($event)"></app-buttons-paginator>

  <div
    class="rounded-md d-flex justify-content-md-end align-items-center gap-2 mt-3 mt-md-0">
    <app-paragraph variant="mb-0 items-per-page">
      Itens por página
    </app-paragraph>

    <mat-form-field
      appearance="outline"
      class="mat-form-field-platinum rounded-md">
      <mat-select
        class="select"
        [(ngModel)]="pageSize"
        (selectionChange)="onPageSizeChange($event.value)">
        <mat-option [value]="item" *ngFor="let item of pageSizeOptions">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
