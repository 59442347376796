import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModalSizeType } from '@shared/components/modal/types/modal.type';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, DialogModule, ButtonComponent],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input() titleLabel: string = '';
  @Input() size: ModalSizeType = 'lg';
  @Input() variant: string = '';
  @Input() confirmation: boolean = false;
  @Input() confirmButtonName: string = 'Confirmar';

  @Output() confirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();

  visible: boolean = false;
  layout: string = this.variant + ' modal-custom sp-p-xl';

  openModal(): void {
    this.layout = `modal-custom-${this.size === 'fullscreen' ? 'fullscreen' : this.size}`;

    this.visible = true;
  }

  closeModal(): void {
    this.visible = false;
    this.closed.emit(true);
  }

  onConfirmModal(): void {
    this.confirmed.emit(true);
  }
}
