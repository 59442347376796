export function removeEmptyProps<T>(obj: T): T {
  return JSON.parse(
    JSON.stringify(obj, (_key, value) => {
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim() === '')
      )
        return null;

      return value;
    }),
    (_key, value) => {
      if (value === null) return undefined;

      return value;
    }
  );
}
