import { FilterData } from '@shared/components/filters/models/filters.model';
import { FilterDisplayName } from '@shared/components/vehicles-base/enums/filter-type.enum';

export const filterKeyToDisplayName: {
  [key in keyof FilterData]: FilterDisplayName;
} = {
  brands: FilterDisplayName.brands,
  categories: FilterDisplayName.categories,
  capacities: FilterDisplayName.capacities,
  domains: FilterDisplayName.domains,
};
