import { Component } from '@angular/core';
import { FaqComponent } from '@modules/pages/home/components/faq/faq.component';
import { MenuComponent } from '@shared/components/menu/menu.component';
import { PaginatorCustom } from '@shared/components/paginator/models/paginator.model';
import { PaginatorComponent } from '@shared/components/paginator/paginator.component';
import { VehiclesBaseComponent } from '@shared/components/vehicles-base/vehicles-base.component';
import { LineComponent } from '@shared/line/line.component';

@Component({
  selector: 'app-view-vehicles',
  standalone: true,
  imports: [
    MenuComponent,
    VehiclesBaseComponent,
    PaginatorComponent,
    LineComponent,
    FaqComponent,
  ],
  templateUrl: './view-vehicles.component.html',
  styleUrl: './view-vehicles.component.scss',
})
export class ViewVehiclesComponent {
  totalItems: number = 0;
  currentPage: number = 1;
  pageSize: number = 12;

  onPageChange({ page, pageSize }: PaginatorCustom): void {
    this.currentPage = page;
    this.pageSize = pageSize;
  }

  updateTotalItems(total: number): void {
    this.totalItems = total;
  }
}
