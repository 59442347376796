import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { BreadcrumbItem } from '@modules/pages/vehicles/components/vehicle/vehicle-breadcrumb/models/vehicle-breadcrumb.model';
import { ButtonComponent } from '@shared/components/button/button.component';
import { VehicleItems } from '@shared/components/vehicles-base/models/vehicles-base.model';

@Component({
  selector: 'app-vehicle-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent],
  templateUrl: './vehicle-breadcrumb.component.html',
  styleUrl: './vehicle-breadcrumb.component.scss',
})
export class VehicleBreadcrumbComponent implements OnInit {
  @Input() vehicle!: VehicleItems;

  items: BreadcrumbItem[] = [];

  constructor(private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.loadBreadcrumb();
  }

  loadBreadcrumb(): void {
    this.items = [
      { text: this.vehicle.marca },
      { text: this.vehicle.categoria },
      { text: this.vehicle.capacidade },
    ];
  }

  onGoBack(): void {
    this.navigationService.navigateTo('/veiculos');
  }
}
