import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ItemFilterMenuMenu } from '@shared/components/footer/components/filter-menu/models/filter-menu.model';
import { FilterMenuService } from '@shared/components/footer/components/filter-menu/services/filter-menu.service';

@Component({
  selector: 'app-filter-menu',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './filter-menu.component.html',
  styleUrl: './filter-menu.component.scss',
})
export class FilterMenuComponent implements OnInit {
  items: ItemFilterMenuMenu | null = null;

  constructor(private filterMenuService: FilterMenuService) {}

  ngOnInit(): void {
    this.getFooterMenu();
  }

  getFooterMenu(): void {
    this.filterMenuService
      .getFilterMenu()
      .subscribe(data => (this.items = data));
  }
}
