import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { dynamicHost } from '@api/app.api';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { VerificationLinkHandlerService } from '@core/services/verification-link-handler.service';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { Menu } from '@shared/components/menu/items-menu/models/items.model';
import { MenuService } from '@shared/components/menu/items-menu/services/items-menu.service';
import { MenuSkeletonComponent } from '@shared/components/skeleton/menu/menu-skeleton.component';

@LoadingId(LoadingIdEnum.menu)
@Component({
  selector: 'app-items-menu',
  standalone: true,
  imports: [CommonModule, MenuSkeletonComponent],
  templateUrl: './items-menu.component.html',
  styleUrl: './items-menu.component.scss',
})
export class ItemsMenuComponent extends LoadingBaseComponent implements OnInit {
  @Input() mobile: boolean = false;
  @Input() isLink: boolean = false;

  @Output() isMenuOpen: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );

  menu: Menu[] = [];

  constructor(
    protected override loadingService: LoadingService,
    private verificationLinkHandlerService: VerificationLinkHandlerService,
    private menuService: MenuService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getMenu();
  }

  getMenu(): void {
    this.menuService.menu({ domain: dynamicHost }).subscribe({
      next: menu => (this.menu = menu),
    });
  }

  handleLink(open: boolean = false, link: string = ''): void {
    if (!open) this.isMenuOpen.emit(open);

    this.verificationLinkHandlerService.handleVerificationLink(
      link,
      this.isLink
    );
  }
}
