import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-icons',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconsComponent {
  @Input() icon: string = '';
  @Input() isShowingCloseIcon: boolean = false;
  @Input() isShowingPreviousIcon: boolean = false;
  @Input() isShowingDownloadIcon: boolean = false;
}
