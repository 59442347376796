import { Component } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { AuthBaseComponent } from '@modules/auth/components/auth-base/auth-base.component';
import { UserService } from '@modules/auth/services/user/user.service';
import { FieldEmailComponent } from '@modules/auth/shared/field-email/field-email.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';

@LoadingId(LoadingIdEnum.createOrLoginUser)
@Component({
  selector: 'app-create-account',
  standalone: true,
  imports: [FieldEmailComponent],
  templateUrl: './create-account.component.html',
})
export class CreateAccountComponent extends LoadingBaseComponent {
  constructor(
    protected override loadingService: LoadingService,
    private authBase: AuthBaseComponent,
    private userService: UserService
  ) {
    super(loadingService);
  }

  onSubmit(email: string): void {
    this.createUser(email);
  }

  createUser(email: string): void {
    this.userService.createUser(email).subscribe({
      next: () => this.authBase.nextStage({ email: email }),
    });
  }
}
