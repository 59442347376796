<div
  class="filter-menu footer-menu d-md-flex justify-content-between align-items-start">
  <div class="item-menu mb-4 mb-md-0">
    <h5>Marcas</h5>

    <ul class="list-unstyled mb-0" *ngIf="items">
      <li *ngFor="let item of items.brands">
        <a
          class="text-decoration-none"
          [routerLink]="['/veiculos']"
          [queryParams]="{ brands: item }">
          {{ item }}
        </a>
      </li>
    </ul>
  </div>

  <div
    class="item-menu mb-4 mb-md-0"
    *ngIf="items && items.categories.length > 1">
    <h5>Categorias</h5>

    <ul class="list-unstyled mb-0">
      <li *ngFor="let item of items.categories">
        <a
          class="text-decoration-none"
          [routerLink]="['/veiculos']"
          [queryParams]="{ categories: item }">
          {{ item }}
        </a>
      </li>
    </ul>
  </div>

  <div class="item-menu mb-4 mb-md-0">
    <h5>Capacidade</h5>

    <ul class="list-unstyled mb-0" *ngIf="items">
      <li *ngFor="let item of items.capacities">
        <a
          class="text-decoration-none"
          [routerLink]="['/veiculos']"
          [queryParams]="{ capacities: item }">
          {{ item }}
        </a>
      </li>
    </ul>
  </div>
</div>
