import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  SocialMediaAdminCreateOrUpdate,
  SocialMediaAdminParams,
  SocialMediaAdminResponse,
} from '@modules/admin/edit/components/social-media/models/social-media.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SocialMediaAdminService extends BaseApiService {
  createOrUpdate(data: SocialMediaAdminCreateOrUpdate[]): Observable<void> {
    return this.makeRequest(
      () => this.http.post<void>(`${apiUrl}/${apiVersion}/links/batch`, data),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  socialMedia({
    domain,
    category = 'media',
  }: SocialMediaAdminParams): Observable<SocialMediaAdminResponse[]> {
    return this.makeRequest(
      () =>
        this.http.get<SocialMediaAdminResponse[]>(
          `${apiUrl}/${apiVersion}/links`,
          {
            params: { domain, category },
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
