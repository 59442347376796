<app-base-skeleton *ngIf="isLoading" [reset]="reset">
  <div
    class="skeleton-custom-col align-items-center"
    [ngClass]="{ 'skeleton-custom-border': circle === 'circle' }"
    *appNgTemplateRepeat="quantityRepeteLoading">
    <p-skeleton
      styleClass="neutral-variant-95"
      [shape]="circle"
      [width]="width"
      [height]="height"></p-skeleton>
  </div>
</app-base-skeleton>
