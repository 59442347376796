import { ElementRef, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CodeInputService {
  pasteCodeToInputs(
    event: ClipboardEvent,
    inputs: ElementRef[],
    form: FormGroup,
    formControlNames: string[]
  ): void {
    event.preventDefault();

    if (event.clipboardData) {
      const pastedText = event.clipboardData
        .getData('text')
        .replace(/[^0-9]/g, '');

      inputs.forEach((input, index) => {
        if (index < pastedText.length && form) {
          form?.get(formControlNames[index])?.setValue(pastedText[index]);

          input.nativeElement.focus();
        }
      });
    }
  }
}
