import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  watchFooterData(): Observable<{ showFooter: boolean }> {
    return this.router.events.pipe(
      filter(() => this.router.url !== ''),
      filter(() => this.route.firstChild !== null),
      map(() => this.route.firstChild!),
      map(route => route.data),
      switchMap(data => data)
    ) as Observable<{ showFooter: boolean }>;
  }
}
