import { Injectable } from '@angular/core';
import { adminLogin, apiAuth, apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  LoginAdminParams,
  LoginAdminResponse,
} from '@modules/admin/login/models/login.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginAdminService extends BaseApiService {
  loginAdmin({
    email,
    password,
  }: LoginAdminParams): Observable<LoginAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.post<LoginAdminResponse>(
          `${apiUrl}/${apiVersion}/${apiAuth}/${adminLogin}`,
          {
            email,
            password,
          }
        ),
      LoadingIdEnum.createOrLoginUser,
      false,
      bdAuth
    );
  }
}
