import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FilterHowItWorksParams,
  HowItWorksList,
} from '@modules/pages/home/components/how-it-works/models/how-it-works.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HowItWorksService extends BaseApiService {
  getHowItWorks({
    domain = dynamicHost,
    section = 'homeSignedInOrOut',
  }: FilterHowItWorksParams = {}): Observable<HowItWorksList[]> {
    return this.makeRequest(
      () =>
        this.http.get<HowItWorksList[]>(`${apiUrl}/${apiVersion}/steps`, {
          params: removeEmptyProps({ domain, section }),
        }),
      LoadingIdEnum.getHowItWorks
    );
  }
}
