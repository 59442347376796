import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth, whiteLabel } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FilterWhiteLabelsParams,
  WhiteLabelList,
} from '@modules/admin/white-label/components/table/models/table.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WhiteLabelAdminService extends BaseApiService {
  getWhiteLabel({
    page = 1,
    perPage = 12,
  }: FilterWhiteLabelsParams = {}): Observable<WhiteLabelList> {
    return this.makeRequest(
      () =>
        this.http.get<WhiteLabelList>(`${apiUrl}/${apiVersion}/${whiteLabel}`, {
          params: removeEmptyProps({ page, perPage }),
        }),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
