import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { NgTemplateRepeatDirective } from '@core/directives/ng-template-repeat.directive';
import { BaseSkeletonComponent } from '@shared/components/skeleton/base/base-skeleton.component';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-banner-skeleton',
  standalone: true,
  imports: [
    CommonModule,
    SkeletonModule,
    BaseSkeletonComponent,
    NgTemplateRepeatDirective,
  ],
  templateUrl: './banner-skeleton.component.html',
  styleUrl: './banner-skeleton.component.scss',
})
export class BannerSkeletonComponent {
  @Input() isLoading: boolean = true;
}
