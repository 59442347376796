import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import {
  SocialMedia,
  SocialMediaAdminCreateOrUpdate,
} from '@modules/admin/edit/components/social-media/models/social-media.model';
import { SocialMediaAdminService } from '@modules/admin/edit/components/social-media/services/social-media.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LineComponent } from '@shared/line/line.component';
import { OrderListModule } from 'primeng/orderlist';

@Component({
  selector: 'app-adm-social-media',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AutoFocusDirective,
    ButtonComponent,
    LineComponent,
    OrderListModule,
  ],
  templateUrl: './social-media.component.html',
})
export class SocialMediaAdminComponent implements OnInit {
  form!: FormGroup;
  domain: string = '';
  items: SocialMedia[] = [
    { name: 'social-media-linkedin', label: 'LinkedIn', link: '' },
    { name: 'social-media-instagram', label: 'Instagram', link: '' },
    { name: 'social-media-youtube', label: 'YouTube', link: '' },
    { name: 'social-media-facebook', label: 'Facebook', link: '' },
  ];

  constructor(
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private socialMediaAdminService: SocialMediaAdminService
  ) {}

  ngOnInit(): void {
    this.initialForm();
    this.getSocialMediaByIdFromRoute();
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  initialForm(): void {
    this.form = this.fb.group({
      items: this.fb.array(this.items.map(item => this.createItem(item))),
    });
  }

  getSocialMediaByIdFromRoute(): void {
    this.route.params.subscribe(
      params => params['domain'] && this.getSocialMedia(params['domain'])
    );
  }

  getSocialMedia(domain: string): void {
    this.domain = domain;

    this.socialMediaAdminService.socialMedia({ domain }).subscribe({
      next: socialMedia => {
        if (socialMedia.length > 0) {
          const sortedSocialMedia = [...socialMedia].sort(
            (a, b) => (a.order ?? 0) - (b.order ?? 0)
          );

          this.itemsArray.controls.sort((controlA, controlB) => {
            const nameA = controlA.get('name')?.value;
            const nameB = controlB.get('name')?.value;
            const orderA =
              sortedSocialMedia.find(item => item.name === nameA)?.order ?? 0;
            const orderB =
              sortedSocialMedia.find(item => item.name === nameB)?.order ?? 0;

            return orderA - orderB;
          });

          sortedSocialMedia.forEach(apiItem => {
            const itemControl = this.itemsArray.controls.find(
              item => item.get('name')?.value === apiItem.name
            );

            if (itemControl)
              itemControl.patchValue({
                link: apiItem.link,
              });
          });
        }
      },
    });
  }

  createItem(item: SocialMedia): FormGroup {
    return this.fb.group({
      name: item.name,
      label: item.label,
      link: item.link,
    });
  }

  onSubmit(): void {
    this.createOrUpdate();
  }

  createOrUpdate(): void {
    const data: SocialMediaAdminCreateOrUpdate[] = this.itemsArray.controls
      .filter(item => !!item.value.name && !!item.value.link)
      .map((item, index) => ({
        domain: this.domain,
        name: item.value.name,
        category: 'media',
        order: index + 1,
        link: item.value.link,
      }));

    this.socialMediaAdminService.createOrUpdate(data).subscribe({
      next: () => this.notificationService.showToast('Redes Sociais salva.'),
    });
  }
}
