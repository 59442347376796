import { Component } from '@angular/core';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-add-more-items-button',
  standalone: true,
  imports: [ButtonComponent],
  templateUrl: './add-more-items-button.component.html',
})
export class AddMoreItemsButtonComponent {
  constructor(
    private sidebarDrawerService: SidebarDrawerService,
    private navigationService: NavigationService
  ) {}

  onMoreItems(): void {
    this.sidebarDrawerService.close();
    this.navigationService.navigateTo('/veiculos');
  }
}
