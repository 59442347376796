import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';
import { RefreshPageService } from '@core/services/refresh-page.service';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-button-logout',
  standalone: true,
  imports: [ButtonComponent, ModalComponent],
  templateUrl: './button-logout.component.html',
})
export class ButtonLogoutComponent {
  constructor(
    private refreshPageService: RefreshPageService,
    private sidebarDrawerService: SidebarDrawerService,
    private cartService: CartService,
    private authService: AuthService
  ) {}

  logout(): void {
    this.sidebarDrawerService.close();
    this.authService.logout();
    this.cartService.clearCart(true);
    this.refreshPageService.refresh();
  }
}
