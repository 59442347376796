import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { dynamicHost } from '@api/app.api';
import { getIsJavaScriptEnabled } from '@core/utils/is-javascript.util';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { FaqList } from '@modules/pages/home/components/faq/models/faq.model';
import { FaqService } from '@modules/pages/home/components/faq/services/faq.service';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatAccordion,
    ParagraphComponent,
  ],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
})
export class FaqComponent implements OnInit {
  @Input() colLeft: string = 'col-md-4';
  @Input() colRight: string = 'col-md-8';

  isJavaScriptEnabled: boolean = false;
  faqList!: FaqList[];

  constructor(private faqService: FaqService) { }

  ngOnInit(): void {
    this.getFaq();
    this.isJavaScriptEnabled = getIsJavaScriptEnabled();
  }

  getFaq(): void {
    this.faqService.getFaq({
      domain: dynamicHost
    }).subscribe({
      next: (faq: FaqList[]) =>
        (this.faqList = faq.sort((a, b) => a.order - b.order)),
    });
  }
}
