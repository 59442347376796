import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import {
  LoadingSkeleton,
  manageLoadingState,
} from '@core/utils/loading-base.util';

@Component({
  template: '',
  standalone: true,
})
export abstract class LoadingBaseComponent implements OnInit, LoadingSkeleton {
  isLoading: boolean = true;
  loadingId!: string;
  isLoadingById?: (loadingId: string) => boolean;

  constructor(protected loadingService: LoadingService) {
    manageLoadingState(this, loadingService);
  }

  ngOnInit(): void {
    // do nothing.
    manageLoadingState(this, this.loadingService);
  }
}
