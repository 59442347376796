<app-base-skeleton *ngIf="isLoading">
  <div class="skeleton-custom-col" *appNgTemplateRepeat="quantityRepeteLoading">
    <div class="d-flex flex-column gap-2">
      <p-skeleton width="10rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="16rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="12rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="14rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="8rem" height="1.5rem"></p-skeleton>
      <p-skeleton width="18rem" height="1.5rem"></p-skeleton>
    </div>
  </div>
</app-base-skeleton>
