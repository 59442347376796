import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-user-icon',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ImageComponent],
  templateUrl: './user-icon.component.html',
  styleUrl: './user-icon.component.scss',
})
export class UserIconComponent {
  image: string = '/assets/images/icons/user.svg';

  constructor(private sidebarDrawerService: SidebarDrawerService) { }

  openSidebarDrawer(): void {
    this.sidebarDrawerService.toggle(FlowName.login);
  }
}
