export enum FlowName {
  signup = 'signup',
  login = 'login',
}

export enum FlowTitle {
  createAccount = 'Crie sua conta',
  login = 'Acesse sua conta',
  companyData = 'Dados da empresa',
  confirmEmail = 'Confirme seu e-mail',
}

export enum UserStatusFlow {
  pending = 'PENDING_REGISTER_ENTITY',
}
