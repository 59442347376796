export enum LoadingIdEnum {
  getVehicleById = 'getVehicleById',
  getVehicleByIdPlans = 'getVehicleByIdPlans',
  getVehicles = 'getVehicles',
  getUserDetailsById = 'getUserDetailsById',
  getHowItWorks = 'getHowItWorks',
  getPlanDescription = 'getPlanDescription',
  createOrLoginUser = 'createOrLoginUser',
  validPassword = 'validPassword',
  companyData = 'companyData',
  sendProposal = 'sendProposal',
  updateUserData = 'updateUserData',
  getBanners = 'getBanners',
  admin = 'admin',
  menu = 'menu',
}
