<form
  appAutoFocus
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="admin edit-admin-content text-black d-flex flex-column gap-4">
  <app-adm-how-it-works
    [form]="form"
    [data]="howItWorkData"></app-adm-how-it-works>

  <app-line class="mt-3 mb-5"></app-line>

  <app-adm-advantages
    [form]="form"
    [data]="advantagesData"
    (images)="imagesAdvantagesChange($event)"></app-adm-advantages>

  <app-line class="mt-4 mb-5"></app-line>

  <app-adm-about-us
    [form]="form"
    [imageSrcStorage]="imageAboutUs"
    (image)="imageAboutUsChange($event)"></app-adm-about-us>

  <div class="d-flex align-items-center justify-content-center gap-2">
    <app-button
      label="Cancelar"
      layout="button-border"
      variant="transparent"
      (buttonClick)="navigationService.navigateBack()"></app-button>

    <app-button
      type="submit"
      label="Salvar"
      [disabled]="form.invalid"></app-button>
  </div>
</form>
