<section class="admin login" *ngIf="isLoggedIn">
  <div class="container p-5">
    <div class="row vh-100">
      <div class="col-12">
        <app-logo
          [imageUrl]="logoUrl"
          [url]="'/' + adminUrl + '/' + adminWhiteLabelUrl"></app-logo>
      </div>

      <div class="col-12 col-md-6">
        <h1>Painel administrativo</h1>

        <app-paragraph>
          Para continuar, informe seus dados abaixo.
        </app-paragraph>

        <form
          appAutoFocus
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          class="admin edit-admin-branding d-flex flex-column gap-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Email</mat-label>

            <input
              matInput
              required
              email
              type="email"
              formControlName="email" />

            <app-icons class="pe-3" icon="mail_outline" matSuffix></app-icons>

            <mat-error *ngIf="hasError({ form, field: 'email' })">
              E-mail é obrigatório.
            </mat-error>

            <mat-error
              *ngIf="hasError({ form, field: 'email', validation: 'email' })">
              E-mail inválido.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Senha</mat-label>

            <input
              matInput
              required
              type="password"
              formControlName="password" />

            <app-icons class="pe-3" icon="lock_outline" matSuffix></app-icons>

            <mat-error *ngIf="hasError({ form, field: 'password' })">
              Senha é obrigatória.
            </mat-error>

            <mat-error
              *ngIf="
                hasError({ form, field: 'password', validation: 'minlength' })
              ">
              A senha deve ter pelo menos 9 caracteres.
            </mat-error>
          </mat-form-field>

          <app-button
            type="submit"
            label="Continuar"
            [disabled]="form.invalid"></app-button>
        </form>
      </div>
    </div>
  </div>
</section>

<app-loading></app-loading>
