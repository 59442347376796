<form
  appAutoFocus
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="admin edit-admin-social-media d-flex flex-column gap-4">
  <ng-container *ngIf="itemsArray.length > 0; else notItem">
    <p-orderList [value]="itemsArray.controls" [dragdrop]="true">
      <ng-template
        let-item
        let-index="index"
        pTemplate="item"
        formArrayName="items">
        <div [formGroupName]="index" class="social-media-item">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>{{ item.value.label }}</mat-label>

            <input matInput type="text" formControlName="link" />
          </mat-form-field>

          <div
            class="buttons d-flex justify-content-end gap-2"
            *ngIf="items.length > 1">
            <app-button
              icon="drag_handle"
              label="Ordenar"
              layout="button-border button-size-auto cursor-move"
              variant="transparent"></app-button>
          </div>
        </div>
      </ng-template>
    </p-orderList>
  </ng-container>

  <app-line></app-line>

  <div class="d-flex align-items-center justify-content-center gap-2">
    <app-button
      label="Cancelar"
      layout="button-border"
      variant="transparent"
      (buttonClick)="navigationService.navigateBack()"></app-button>

    <app-button
      type="submit"
      label="Salvar"
      [disabled]="form.invalid"></app-button>
  </div>
</form>

<ng-template #notItem>Nenhum item</ng-template>
