<div class="buttons-paginator">
  <button
    mat-icon-button
    [disabled]="currentPage === 1"
    (click)="onPageChange(currentPage - 1)"
    class="p-0 px-2 m-0">
    <mat-icon class="icon-custom">chevron_left</mat-icon>
  </button>

  <ng-container *ngIf="currentPage > 2">
    <button mat-button (click)="onPageChange(0)">1</button>

    <span *ngIf="currentPage > 3">...</span>
  </ng-container>

  <ng-container *ngFor="let page of visiblePages">
    <button
      mat-button
      [disabled]="page === currentPage"
      (click)="onPageChange(page)"
      [ngClass]="{
        'item-current-page bg-primary-color text-primary-color':
          page === currentPage,
      }">
      <app-paragraph
        [variant]="page === currentPage ? 'text-primary-color fw-500' : 'mb-0'">
        {{ page }}
      </app-paragraph>
    </button>
  </ng-container>

  <ng-container *ngIf="currentPage < pages.length - 3">
    <span *ngIf="currentPage < pages.length - 4">...</span>

    <button mat-button (click)="onPageChange(pages.length - 1)">
      {{ pages.length }}
    </button>
  </ng-container>

  <button
    mat-icon-button
    [disabled]="currentPage === pages.length"
    (click)="onPageChange(currentPage + 1)"
    class="p-0 px-2 m-0">
    <mat-icon class="icon-custom">chevron_right</mat-icon>
  </button>
</div>
