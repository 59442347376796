import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, authSend, authVerify } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  BaseResponse,
  BaseUserResponse,
  FilterCompanyUser,
  FilterValidPassword,
  ValidPasswordREsponse,
} from '@modules/auth/services/user/models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApiService {
  createUser(email: string): Observable<BaseUserResponse> {
    return this.makeRequest(
      () =>
        this.http.post<BaseUserResponse>(
          `${apiUrl}/${apiVersion}/${authSend}`,
          {
            email,
          }
        ),
      LoadingIdEnum.createOrLoginUser
    );
  }

  createCompany(data: FilterCompanyUser): Observable<BaseResponse> {
    const { email, phone, name, role, entity } = data;

    return this.makeRequest(
      () =>
        this.http.post<BaseResponse>(
          `${apiUrl}/${apiVersion}/users/management`,
          {
            email,
            phone,
            name,
            role,
            entity,
          }
        ),
      LoadingIdEnum.companyData,
      true
    );
  }

  validPassword({
    email = '',
    password = '',
  }: FilterValidPassword): Observable<ValidPasswordREsponse> {
    return this.makeRequest(
      () =>
        this.http.post<ValidPasswordREsponse>(
          `${apiUrl}/${apiVersion}/${authVerify}`,
          {
            email,
            password,
          }
        ),
      LoadingIdEnum.validPassword
    );
  }

  login(email: string): Observable<BaseUserResponse> {
    return this.makeRequest(
      () =>
        this.http.post<BaseUserResponse>(
          `${apiUrl}/${apiVersion}/${authSend}`,
          {
            email,
          }
        ),
      LoadingIdEnum.createOrLoginUser
    );
  }
}
