import { FormControl, FormGroup, Validators } from '@angular/forms';

export class FormUtils {
  static addControlToForm(
    form: FormGroup,
    names: string | string[],
    initialValues:
      | string
      | number
      | boolean
      | string[]
      | number[]
      | boolean[]
      | null = null,
    required?: boolean
  ): void {
    if (form) {
      const namesArray = typeof names === 'string' ? [names] : names;
      const initialValuesArray =
        typeof initialValues === 'string' ? [initialValues] : initialValues;

      namesArray.forEach((name, index) => {
        if (!form.contains(name)) {
          const validators = required ? [Validators.required] : [];

          const initialValue = Array.isArray(initialValuesArray)
            ? initialValuesArray[index]
            : initialValues;

          form.addControl(name, new FormControl(initialValue, validators));
        }
      });
    }
  }
}
