<mat-sidenav-container
  autosize
  class="drawer-container"
  [ngClass]="{ 'drawer-opened': getIsSidebarDrawerOpen() | async }"
  (backdropClick)="
    showConfirmation() ? confirmation.openModal() : closeSidebarDrawer()
  ">
  <mat-sidenav
    mode="over"
    position="end"
    disableClose
    [opened]="getIsSidebarDrawerOpen() | async"
    [fixedInViewport]="true"
    [fixedTopGap]="0"
    [fixedBottomGap]="0">
    <section class="row mt-4 mt-md-0 p-3 p-md-5 h-100">
      <div class="col-12">
        <div class="header d-flex align-items-center justify-content-between">
          <span
            [ngClass]="{
              'd-flex align-items-center justify-content-start gap-3':
                isNotFirstNorLastStage,
            }">
            <app-button
              *ngIf="isNotFirstNorLastStage"
              variant="resetButton"
              [isShowingPreviousIcon]="true"
              (buttonClick)="previousStage()">
            </app-button>

            <app-title *ngIf="titleLabel" [resetMarginBottom]="true">
              {{ titleLabel }}
            </app-title>
          </span>

          <app-button
            variant="resetButton"
            tabIndex="-1"
            [isShowingCloseIcon]="true"
            (buttonClick)="
              showConfirmation()
                ? confirmation.openModal()
                : closeSidebarDrawer()
            ">
          </app-button>
        </div>

        <ng-content></ng-content>
      </div>
    </section>
  </mat-sidenav>
</mat-sidenav-container>

<app-modal
  #confirmation
  size="xs-2"
  variant="button-red"
  titleLabel="Deseja interromper o seu cadastro?"
  confirmButtonName="Sim, interromper"
  [confirmation]="true"
  (confirmed)="onCancelSignup(); confirmation.closeModal()">
  <app-confirmation-dialog></app-confirmation-dialog>
</app-modal>
