import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  Menu,
  MenuParams,
} from '@shared/components/menu/items-menu/models/items.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MenuService extends BaseApiService {
  menu({
    domain,
    section = 'homeSignedInOrOut',
  }: MenuParams): Observable<Menu[]> {
    return this.makeRequest(
      () =>
        this.http.get<Menu[]>(`${apiUrl}/${apiVersion}/menu`, {
          params: { domain, section },
        }),
      LoadingIdEnum.menu
    );
  }
}
