<main class="mb-md-5">
  <router-outlet></router-outlet>
</main>

<app-footer *ngIf="showFooter"></app-footer>

<app-sidebar-drawer [titleLabel]="flowTitle" *ngIf="showFooter">
  <app-auth-base
    *ngIf="
      currentContent === flowName.signup &&
      currentContent?.toString() !== accountSummaryFlowName.users
    "
    [flowName]="flowName.signup"
    (currentFlowTitle)="updateFlowTitle($event)"></app-auth-base>

  <app-auth-base
    *ngIf="
      currentContent === flowName.login &&
      currentContent?.toString() !== accountSummaryFlowName.users
    "
    [flowName]="flowName.login"
    (currentFlowTitle)="updateFlowTitle($event)"></app-auth-base>

  <app-cart-item-base
    *ngIf="
      currentContent === cartFlowName.cart &&
      currentContent?.toString() !== accountSummaryFlowName.users
    "
    [flowName]="cartFlowName.cart"></app-cart-item-base>

  <app-account-summary-base
    *ngIf="currentContent === accountSummaryFlowName.users"
    [flowName]="accountSummaryFlowName.users"
    (currentFlowTitle)="updateFlowTitle($event)"></app-account-summary-base>
</app-sidebar-drawer>

<p-toast position="bottom-left" key="bl"></p-toast>

<app-loading></app-loading>
