import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FlowName } from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';

@Component({
  selector: 'app-signup-gate',
  standalone: true,
  imports: [ParagraphComponent, ButtonComponent],
  templateUrl: './signup-gate.component.html',
})
export class SignupGateComponent {
  @ViewChild('signup') signup!: ModalComponent;

  flowName = FlowName;

  constructor(
    private dialogRef: MatDialogRef<SignupGateComponent>,
    private sidebarDrawerService: SidebarDrawerService
  ) {}

  openSidebarDrawer(type: string = ''): void {
    this.closeModal();

    if (type === 'new') this.sidebarDrawerService.toggle(FlowName.signup);
    else this.sidebarDrawerService.toggle(FlowName.login);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
