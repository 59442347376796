import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private router: Router,
    private location: Location
  ) {}

  navigateTo(route: string, id?: string | number, queryParams?: Params): void {
    if (route.startsWith('#')) {
      this.router.navigate(['/'], { fragment: route.substring(1) });
    } else if (id) this.router?.navigate([route, id], { queryParams });
    else
      this.router?.navigate([route], {
        queryParams,
        queryParamsHandling: 'merge',
      });
  }

  navigateBack(): void {
    this.location.back();
  }
}
