import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  AboutUsHowItWorkPostResponse,
  ContentAboutUsAdminCreateOrUpdate,
  ContentAboutUsAdminResponse,
  ContentAdminParams,
  ContentAdvantagesAdminCreateOrUpdate,
  ContentAdvantagesAdminResponse,
  ContentHowItWorkAdminCreateOrUpdate,
  ContentHowItWorkAdminResponse,
} from '@modules/admin/edit/components/content/models/content.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentAdminService extends BaseApiService {
  howItWorkCreateOrUpdate(
    data: ContentHowItWorkAdminCreateOrUpdate[]
  ): Observable<void> {
    return this.makeRequest(
      () => this.http.post<void>(`${apiUrl}/${apiVersion}/steps/batch`, data),
      '',
      true,
      bdAuth
    );
  }

  advantagesCreateOrUpdate(
    data: ContentAdvantagesAdminCreateOrUpdate[]
  ): Observable<void> {
    return this.makeRequest(
      () =>
        this.http.post<void>(`${apiUrl}/${apiVersion}/advantages/batch`, data),
      '',
      true,
      bdAuth
    );
  }

  aboutUsUpdate(
    data: ContentAboutUsAdminCreateOrUpdate
  ): Observable<AboutUsHowItWorkPostResponse> {
    return this.makeRequest(
      () =>
        this.http.post<AboutUsHowItWorkPostResponse>(
          `${apiUrl}/${apiVersion}/whitelabels/${data.domain}`,
          data
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  howItWork({
    domain,
    section,
  }: ContentAdminParams): Observable<ContentHowItWorkAdminResponse[]> {
    return this.makeRequest(
      () =>
        this.http.get<ContentHowItWorkAdminResponse[]>(
          `${apiUrl}/${apiVersion}/steps`,
          {
            params: {
              domain,
              section,
            },
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  advantages({
    domain,
    section,
  }: ContentAdminParams): Observable<ContentAdvantagesAdminResponse[]> {
    return this.makeRequest(
      () =>
        this.http.get<ContentAdvantagesAdminResponse[]>(
          `${apiUrl}/${apiVersion}/advantages`,
          {
            params: {
              domain,
              section,
            },
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  aboutUs(uid: string): Observable<ContentAboutUsAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.get<ContentAboutUsAdminResponse>(
          `${apiUrl}/${apiVersion}/whitelabels/${uid}`
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
