import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import { FileUpload } from '@modules/admin/edit/shared/upload/models/upload.model';
import { UploadAdminService } from '@modules/admin/edit/shared/upload/services/upload.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ImageComponent } from '@shared/components/image/image.component';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
  selector: 'app-adm-upload',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FileUploadModule,
    ButtonComponent,
    ImageComponent,
  ],
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class UploadAdminComponent implements OnChanges {
  @Input() image: string = '';
  @Input() name: string = 'image';
  @Input() label: string = 'Imagem';
  @Input() alt: string = 'Imagem';

  @Output() upload: EventEmitter<FileUpload> = new EventEmitter<FileUpload>();
  @Output() clear: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  imageSrc: string = '';
  editChange: boolean = false;
  showRemoveButton: boolean = false;

  constructor(
    public navigationService: NavigationService,
    private notificationService: NotificationService,
    private uploadAdminService: UploadAdminService
  ) {}

  ngOnChanges(): void {
    this.setImage();
  }

  setImage(): void {
    if (this.image) {
      if (!this.editChange) this.imageSrc = this.image;

      this.showRemoveButton = true;
    } else this.showRemoveButton = false;
  }

  onFileSelect(event: { files: File[] }): void {
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>): void =>
        this.uploadImage(file, e.target?.result as string);

      reader.readAsDataURL(file);
    }
  }

  clearPreview(): void {
    this.imageSrc = '';
    this.clear.emit(true);
  }

  uploadImage(file: File, filePreview: string): void {
    const fileFormData: FormData = new FormData();

    fileFormData.append(file.name, file);

    this.uploadAdminService.upload(fileFormData).subscribe({
      next: ({ message, files }) => {
        this.imageSrc = filePreview;
        this.editChange = true;

        this.upload.emit(files[0]);

        this.notificationService.showToast(message);
      },
    });
  }
}
