import { Pipe, PipeTransform } from '@angular/core';
import { FilterDisplayName } from '@shared/components/vehicles-base/enums/filter-type.enum';

@Pipe({
  name: 'filterName',
  standalone: true,
})
export class FilterNamePipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case FilterDisplayName.brands:
        return 'Marcas';
      case FilterDisplayName.categories:
        return 'Categorias';
      case FilterDisplayName.capacities:
        return 'Capacidades';
      default:
        return value;
    }
  }
}
