<section class="admin edit-admin-about-us row" *ngIf="form" [formGroup]="form">
  <div class="col-12 col-md-6">
    <h2>Quem somos</h2>
  </div>

  <div class="col-12 col-md-6" formGroupName="aboutUs">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Título</mat-label>

      <input matInput type="text" formControlName="aboutUsTitle" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Descrição</mat-label>

      <textarea
        matInput
        type="text"
        formControlName="aboutUsDescription"
        rows="8"
        class="fs-md lh-md"></textarea>
    </mat-form-field>

    <app-adm-upload
      name="aboutUsImage"
      label="Imagem"
      alt="Imagem quem somos"
      [image]="imageSrcStorage"
      (upload)="onUpload($event)"
      (clear)="onUploadClear()"></app-adm-upload>
  </div>
</section>
