<section class="admin edit-admin-advantages row" [formGroup]="form">
  <div class="col-12 col-md-6">
    <h2>Vantagens</h2>
  </div>

  <div class="col-12 col-md-6" formGroupName="advantages">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Título</mat-label>

      <input matInput type="text" formControlName="advantageTitle" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Descrição</mat-label>

      <input matInput type="text" formControlName="advantageDescription" />
    </mat-form-field>

    <div formArrayName="names">
      <div
        *ngFor="let name of names.controls; let i = index"
        [formGroupName]="i"
        class="advantage mb-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Vantagem {{ i + 1 }}</mat-label>

          <input matInput type="text" formControlName="name" />
        </mat-form-field>

        <mat-error *ngIf="name.hasError('imageNameInvalid')">
          Você deve inserir texto e imagem ou deixar ambos os campos vazios.
        </mat-error>

        <app-adm-upload
          name="icon1"
          [label]="'Ícone Vantagem ' + (i + 1)"
          [alt]="'Imagem vantagem ' + (i + 1)"
          [image]="form.value?.advantages?.names[i]?.icon?.location"
          (upload)="onUpload($event, i + 1)"
          (clear)="onUploadClear(i + 1)"></app-adm-upload>
      </div>
    </div>
  </div>
</section>
