import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControlOptions,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { imageNameValidator } from '@core/utils/validation-helpers/form-validation.util';
import { ContentAdvantagesAdminResponse } from '@modules/admin/edit/components/content/models/content.model';
import { FileUpload } from '@modules/admin/edit/shared/upload/models/upload.model';
import { UploadAdminComponent } from '@modules/admin/edit/shared/upload/upload.component';

@Component({
  selector: 'app-adm-advantages',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    UploadAdminComponent,
  ],
  templateUrl: './advantages.component.html',
  styleUrl: './advantages.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class AdvantagesAdminComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup;
  @Input() data: ContentAdvantagesAdminResponse[] = [];

  @Output() images: EventEmitter<string[]> = new EventEmitter();

  iconSelectedImageSrcs: string[] = ['', '', '', '', '', ''];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addControlToForm();
  }

  ngOnChanges(): void {
    this.getData();
  }

  get names(): FormArray {
    return this.form.get('advantages.names') as FormArray;
  }

  addControlToForm(): void {
    this.form.addControl(
      'advantages',
      this.fb.group({
        advantageTitle: '',
        advantageDescription: '',
        names: this.fb.array([]),
      })
    );
  }

  addAdvantageControl(advantage: {
    name: string;
    icon: { storage: string; location: string };
  }): void {
    this.names.push(
      this.fb.group(
        {
          name: advantage.name,
          icon: this.fb.group({
            storage: advantage.icon.storage,
            location: advantage.icon.location,
          }),
        },
        { validators: [imageNameValidator] } as AbstractControlOptions
      )
    );
  }

  getData(): void {
    if (this.data.length > 0) {
      this.names?.clear();

      const titleItem = this.data.find(item => item.category === 'title');

      if (titleItem)
        this.form.get('advantages')?.patchValue({
          advantageTitle: titleItem.name,
        });

      const descriptionItem = this.data.find(
        item => item.category === 'description'
      );

      if (descriptionItem)
        this.form.get('advantages')?.patchValue({
          advantageDescription: descriptionItem.name,
        });

      const advantageItems = this.data.filter(
        item => item.category === 'advantage'
      );

      advantageItems.forEach(item => {
        this.addAdvantageControl({
          name: item.name,
          icon: {
            storage: item?.icon?.storage || '',
            location: item?.icon?.location || '',
          },
        });
      });
    }

    while (this.names?.length < 6) {
      this.addAdvantageControl({
        name: '',
        icon: { storage: '', location: '' },
      });
    }
  }

  setFileSrc(fileName: string, index: number): void {
    this.iconSelectedImageSrcs[index] = fileName;
    this.images.emit(this.iconSelectedImageSrcs);
  }

  onUpload({ fileName }: FileUpload, index: number): void {
    this.setFileSrc(fileName, index);

    this.names.at(index - 1)?.patchValue({ icon: { storage: fileName } });
  }

  onUploadClear(index: number): void {
    this.setFileSrc('', index);
    this.names.at(index - 1)?.patchValue({ icon: { storage: '' } });
  }
}
