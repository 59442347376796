import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ContentHowItWorkAdminResponse } from '@modules/admin/edit/components/content/models/content.model';

@Component({
  selector: 'app-adm-how-it-works',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  templateUrl: './how-it-works.component.html',
})
export class HowItWorksAdminComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup;
  @Input() data: ContentHowItWorkAdminResponse[] = [];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.addControlToForm();
  }

  ngOnChanges(): void {
    this.getData();
  }

  get steps(): FormArray {
    return this.form.get('howItWork.steps') as FormArray;
  }

  addControlToForm(): void {
    const steps: FormGroup[] = [];

    if (this.data?.length === 0)
      for (let i = 0; i < 4; i++) steps.push(this.fb.group({ step: '' }));

    this.form.addControl(
      'howItWork',
      this.fb.group({
        title: '',
        description: '',
        steps: this.fb.array(steps),
      })
    );
  }

  getData(): void {
    if (this.data.length > 0) {
      this.steps.clear();

      this.data.forEach(howItWork => {
        switch (howItWork.category) {
          case 'title':
            this.form.get('howItWork')?.patchValue({
              title: howItWork.step,
            });
            break;
          case 'description':
            this.form.get('howItWork')?.patchValue({
              description: howItWork.step,
            });
            break;
          case 'step':
            this.steps.push(
              this.fb.group({
                step: howItWork.step,
              })
            );
            break;
        }
      });
    }
  }
}
