import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private loadings = new Map<string, BehaviorSubject<boolean>>();

  getLoading$(loadingId: string = 'global'): BehaviorSubject<boolean> {
    if (!this.loadings.has(loadingId))
      this.loadings.set(loadingId, new BehaviorSubject<boolean>(false));

    return this.loadings.get(loadingId)!;
  }

  show(loadingId: string = 'global'): void {
    this.getLoading$(loadingId).next(true);
  }

  hide(loadingId: string = 'global'): void {
    this.getLoading$(loadingId).next(false);
  }

  isLoading(loadingId: string = 'global'): boolean {
    return this.getLoading$(loadingId).getValue();
  }
}
