<mat-toolbar
  [ngClass]="[
    'menu bg-transparent d-flex',
    menuDark ? 'menu-dark bg-body' : 'position-absolute top-0',
  ]">
  <div class="menu-items container">
    <div class="row d-md-none">
      <div class="col-12 d-flex align-items-center justify-content-between">
        <app-logo
          [variant]="!menuDark ? 'negative' : ''"
          [isLogoLoader]="false"></app-logo>

        <div class="btn-actions d-flex align-items-center gap-2">
          <app-cart-icon></app-cart-icon>

          <app-button
            icon="menu"
            layout="btn-menu-mobile button-size-auto border border-white rounded-circle"
            variant="resetButton"
            (buttonClick)="toggleMenu()"></app-button>
        </div>
      </div>
    </div>

    <mat-sidenav-container class="bg-transparent">
      <mat-sidenav
        #sidenav
        mode="over"
        position="end"
        [(opened)]="isMenuOpen"
        [class.menu-open]="isMenuOpen">
        <mat-toolbar
          class="d-flex align-items-center justify-content-between mt-3">
          <app-logo variant="" [isLogoLoader]="false"></app-logo>

          <app-button
            variant="resetButton"
            [isShowingCloseIcon]="true"
            (buttonClick)="toggleMenu()">
          </app-button>
        </mat-toolbar>

        <div class="row buttons-user-actions">
          <div
            *ngIf="!isLogged"
            class="col-12 button-user d-flex align-items-center justify-content-center gap-2 mt-4">
            <app-button
              label="Entrar"
              icon="person_outline"
              layout="button-border button-size-auto w-100"
              variant="transparent"
              (buttonClick)="openSidebarDrawer()">
            </app-button>

            <app-button
              label="Criar conta"
              layout="w-100"
              (buttonClick)="openSidebarDrawer('new')">
            </app-button>
          </div>

          <div class="col-12 menu-mobile mt-4">
            <app-icon-user-profile
              class="d-block px-4"
              *ngIf="isLogged"></app-icon-user-profile>

            <app-items-menu
              *ngIf="isMenuOpen"
              [mobile]="isMenuOpen"
              (isMenuOpen)="toggleMenu($event)"></app-items-menu>
          </div>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="d-none d-md-block">
        <div class="row">
          <div class="col-md-8 d-flex align-items-center">
            <app-logo [variant]="!menuDark ? 'negative' : ''"></app-logo>

            <app-items-menu
              [mobile]="isMenuOpen"
              [isLink]="true"
              (isMenuOpen)="toggleMenu($event)"></app-items-menu>
          </div>

          <div class="buttons col-md-4 d-inline-flex justify-content-end gap-2">
            <app-user-icon *ngIf="!isLogged"></app-user-icon>

            <app-icon-user-profile *ngIf="isLogged"></app-icon-user-profile>

            <app-cart-icon></app-cart-icon>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</mat-toolbar>
