import { DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { Pipe, PipeTransform } from '@angular/core';

registerLocaleData(localePt, 'pt-BR');

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: string,
    format: string = "dd 'de' MMMM '-' yyyy"
  ): string | null {
    if (value) {
      let date: Date | null = null;

      if (/^\d{4}-\d{2}-\d{2}$/.test(value)) date = new Date(value);
      else if (/^\d+$/.test(value)) date = new Date(parseInt(value));

      if (date) {
        const datePipe = new DatePipe('pt-BR');
        const formattedDate = datePipe.transform(date, format);

        if (formattedDate) {
          const parts = formattedDate.split(' ');

          parts[2] = parts[2].charAt(0).toUpperCase() + parts[2].slice(1);
          return parts.join(' ');
        }
      }
    }

    return null;
  }
}
