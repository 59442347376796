import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  admin,
  adminEdit,
  adminLogin,
  logoUrl,
  whiteLabel,
} from '@api/app.api';
import { TokenService } from '@core/services/auth/token.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { TableAdmComponent } from '@modules/admin/white-label/components/table/table.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LogoComponent } from '@shared/components/logo/logo.component';

@Component({
  selector: 'app-adm-white-label',
  standalone: true,
  imports: [CommonModule, LogoComponent, ButtonComponent, TableAdmComponent],
  templateUrl: './white-label.component.html',
})
export class WhiteLabelComponent implements OnInit {
  adminUrl = admin;
  adminWhiteLabelUrl = whiteLabel;
  adminLoginUrl = adminLogin;
  adminEditUrl = adminEdit;
  logoUrl: string = logoUrl;

  constructor(
    private navigationService: NavigationService,
    private tokenService: TokenService
  ) {}

  ngOnInit(): void {
    this.getIsAdmin();
  }

  getIsAdmin(): void {
    this.tokenService.getUserAccessProfile().then(accessProfile => {
      if (accessProfile !== 'Admin')
        this.navigationService.navigateTo(
          `/${this.adminUrl}/${this.adminLoginUrl}`
        );
    });
  }

  onAddNew(): void {
    this.navigationService.navigateTo(`/${this.adminUrl}/${this.adminEditUrl}`);
  }
}
