<nav class="breadcrumb">
  <app-button
    layout="breadcrumb-back"
    variant="resetButton"
    [isShowingPreviousIcon]="true"
    (buttonClick)="onGoBack()"></app-button>

  <span
    class="item fs-xs fw-500 lh-md text-grey"
    *ngFor="let item of items; let i = index">
    {{ item.text }}
  </span>
</nav>
