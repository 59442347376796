import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Params } from '@angular/router';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { VehicleFilterService } from '@core/services/vehicle-filter.service';
import {
  FilterData,
  FilterOptionMap,
} from '@shared/components/filters/models/filters.model';
import { filterKeyToDisplayName } from '@shared/components/filters/utils/filters.util';
import { FilterMenuService } from '@shared/components/footer/components/filter-menu/services/filter-menu.service';
import { IconsComponent } from '@shared/components/icons/icons.component';
import { FilterDisplayName } from '@shared/components/vehicles-base/enums/filter-type.enum';
import { FilterNamePipe } from '@shared/pipes/filter-name.pipe';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    FilterNamePipe,
    IconsComponent,
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss',
})
export class FiltersComponent implements OnInit {
  filterMenuData: FilterData | null = null;
  filterKeys: (keyof FilterData)[] = [];
  filterOptions: FilterOptionMap = {};
  filterKeyDisplayName = filterKeyToDisplayName;

  private selectedValuesSubject = new BehaviorSubject<{
    [key: string]: string | null;
  }>({});
  selectedValues$ = this.selectedValuesSubject.asObservable();

  private queryParams: Params = {};

  constructor(
    private navigationService: NavigationService,
    private filterMenuService: FilterMenuService,
    private vehicleFilterService: VehicleFilterService
  ) {}

  ngOnInit(): void {
    this.routeFilter();
  }

  getFilterOptions(
    filterKey: keyof FilterData
  ): { label: string; value: string }[] {
    if (this.filterMenuData && filterKey in this.filterMenuData) {
      return this.filterMenuData[filterKey].map(value => {
        return { label: value, value };
      });
    }

    return [];
  }

  routeFilter(): void {
    combineLatest([
      this.filterMenuService.getFilterMenu(),
      this.vehicleFilterService.routeFilter$,
    ]).subscribe(([filterData, routeFilter]) => {
      this.filterMenuData = filterData;

      if (filterData) {
        this.filterKeys = Object.keys(filterData) as (keyof FilterData)[];

        this.filterKeys.forEach(
          filterKey =>
            (this.filterOptions[filterKey] = this.getFilterOptions(filterKey))
        );
      }

      if (routeFilter)
        this.setSelectedValue(routeFilter.type, routeFilter.title);
    });
  }

  setSelectedValue(filterType: string, filterValue: string): void {
    this.selectedValuesSubject.next({
      ...this.selectedValuesSubject.getValue(),
      [filterType]: filterValue,
    });
  }

  onOptionChange(event: MatSelectChange, type: FilterDisplayName): void {
    this.queryParams[type] = event.value;

    this.navigationService.navigateTo('/veiculos', '', this.queryParams);

    this.selectedValuesSubject.next({
      ...this.selectedValuesSubject.getValue(),
      [type]: event.value,
    });
  }

  onClear(event: Event, filterKey: keyof typeof filterKeyToDisplayName): void {
    event.stopPropagation();

    delete this.queryParams[this.filterKeyDisplayName[filterKey]];

    this.selectedValuesSubject.next({
      ...this.selectedValuesSubject.getValue(),
      [filterKey]: null,
    });

    const mockEvent = { value: null } as MatSelectChange;

    this.onOptionChange(mockEvent, this.filterKeyDisplayName[filterKey]);
  }
}
