<div class="user-proposal mt-5">
  <h2 class="mb-4 mb-md-0">Propostas</h2>

  <div class="user-proposal-table">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef width="15%">Número</th>
        <td mat-cell *matCellDef="let element" class="mobile-td">
          <span class="bg-number">
            <app-paragraph variant="mb-0">
              {{ getFirstCharacters(element.id, 8) }}
            </app-paragraph>
          </span>

          <span class="mobile-show">
            <app-paragraph variant="mb-0 text-black">
              {{ element.createdAt | dateFormat }}
            </app-paragraph>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Data</th>
        <td mat-cell *matCellDef="let element" class="mobile-hide">
          <app-paragraph variant="mb-0 text-black">
            {{ element.createdAt | dateFormat }}
          </app-paragraph>
        </td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Ativos</th>
        <td mat-cell *matCellDef="let element" class="mobile-hide">
          <app-paragraph variant="mb-0 text-black">
            {{ element.proposal[0].amount }}
          </app-paragraph>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" class="mobile-td-space-between">
          <div class="mobile-show">
            <app-paragraph variant="mb-0 text-mountain-mist fs-xs lh-xs fw-500">
              Ativos
            </app-paragraph>

            <app-paragraph variant="mb-0 text-black">
              {{ element.proposal[0].amount }}
            </app-paragraph>
          </div>

          <div>
            <span class="mobile-show">
              <app-paragraph
                variant="mb-0 text-mountain-mist fs-xs lh-xs fw-500">
                Status
              </app-paragraph>
            </span>

            <span [ngClass]="getClassesStatus(element.status)">
              {{ element.status ? 'Ativa' : 'Expirada' }}
            </span>
          </div>

          <ng-container
            *ngTemplateOutlet="
              buttonDownload;
              context: {
                downloadUrl: element.downloadUrl,
                classe: 'button-border button-size-auto mobile-show',
              }
            "></ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="downloadUrl">
        <th mat-header-cell *matHeaderCellDef class="mobile-hide"></th>
        <td mat-cell *matCellDef="let element" class="mobile-hide">
          <ng-container
            *ngTemplateOutlet="
              buttonDownload;
              context: {
                downloadUrl: element.downloadUrl,
                classe: 'button-border button-size-auto',
              }
            "></ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>

<ng-template #buttonDownload let-downloadUrl="downloadUrl" let-classe="classe">
  <app-button
    label="Download"
    variant="transparent"
    [layout]="classe"
    [isShowingDownloadIcon]="true"
    (buttonClick)="onDownload(downloadUrl)">
  </app-button>
</ng-template>
