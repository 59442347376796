import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ItemCountFormatterService {
  formatItemCount(count: number, maxCount: number): string {
    return count > maxCount ? `${maxCount}+` : count?.toString();
  }
}
