import { Injectable } from '@angular/core';
import { TokenService } from '@core/services/auth/token.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _requiresAuth = false;

  constructor(private tokenService: TokenService) {}

  async getToken(): Promise<string | null> {
    return this.tokenService.getToken();
  }

  async logout(): Promise<void> {
    return this.tokenService.deleteToken();
  }

  async isLoggedIn(): Promise<boolean> {
    return this.tokenService.getToken().then(token => !!token);
  }

  async userData(): Promise<string | null> {
    return this.tokenService.getUserName().then(userName => userName);
  }

  get requiresAuth(): boolean {
    return this._requiresAuth;
  }

  setRequiresAuth(value: boolean, bd?: string): void {
    this.tokenService.initDb(bd);

    this._requiresAuth = value;
  }
}
