import { Component } from '@angular/core';
import { CreateAccountComponent } from '@modules/auth/components/signup/components/create-account/create-account.component';

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [CreateAccountComponent],
  templateUrl: './signup.component.html',
})
export class SignupComponent {}
