import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, dynamicHost } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { removeEmptyProps } from '@core/utils/remove-empty-props.util';
import {
  FilterCardParams,
  VehicleList,
} from '@shared/components/vehicles-base/models/vehicles-base.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CardService extends BaseApiService {
  getVehicles({
    brands = '',
    capacities = '',
    categories = '',
    page = 1,
    perPage = 4,
  }: FilterCardParams = {}): Observable<VehicleList> {
    return this.makeRequest(
      () =>
        this.http.get<VehicleList>(
          `${apiUrl}/${apiVersion}/proposals/parameters`,
          {
            params: removeEmptyProps({
              brands,
              capacities,
              categories,
              page,
              perPage,
              domain: dynamicHost
            }),
          }
        ),
      LoadingIdEnum.getVehicles,
      true
    );
  }
}
