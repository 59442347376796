<app-options-skeleton
  [isLoading]="isLoading"
  [quantityRepeteLoading]="4"
  [reset]="true"
  circle="rectangle"
  width="4.5rem"
  height="4.5rem"></app-options-skeleton>

<carousel
  class="mt-4 d-block"
  [interval]="0"
  [showIndicators]="false"
  *ngIf="isBrowser && !isLoading && images.length > 1">
  <slide>
    <app-image
      *ngFor="let imageUrl of images; let i = index"
      [image]="imageUrl"
      [altText]="altText"
      [width]="72"
      [height]="72"
      [class.active]="activeSlide === i"
      (click)="onImageClick(imageUrl, i, $event)"></app-image>
  </slide>
</carousel>

<app-button
  icon="navigate_before"
  layout="button-border button-size-auto button-before position-absolute"
  [disabled]="activeSlide === 0"
  (buttonClick)="previous()">
</app-button>

<app-button
  icon="navigate_next"
  layout="button-border button-size-auto button-next position-absolute"
  [disabled]="images && activeSlide === images.length - 1"
  (buttonClick)="next()">
</app-button>
