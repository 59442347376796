<p-table
  [value]="dataSource"
  [resizableColumns]="true"
  [breakpoint]="'960px'"
  [tableStyle]="{ 'min-width': '50rem' }"
  responsiveLayout="stack"
  styleClass="admin-table">
  <ng-template pTemplate="header">
    <tr>
      <th class="table-title column-id" pResizableColumn>ID</th>
      <th class="table-title column-name" pResizableColumn>Nome</th>
      <th class="table-title column-status" pResizableColumn>Status</th>
      <th class="table-title column-actions" pResizableColumn>Ações</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-data>
    <tr>
      <td class="table-text">
        <span [pTooltip]="data.uid" tooltipPosition="bottom">
          {{ getFirstCharacters(data.uid, 13) }}
        </span>
      </td>

      <td class="table-text">{{ data.name }}</td>

      <td class="table-text">
        <span [ngClass]="getClassesStatus(data.active)">
          {{ data.active ? 'Ativa' : 'Expirada' }}
        </span>
      </td>

      <td class="table-text text-end">
        <app-button
          label="Editar"
          icon="mode_edit_outline"
          variant="transparent"
          layout="button-border button-size-auto"
          (buttonClick)="onAction(data.uid, data.domain)">
        </app-button>
      </td>
    </tr>
  </ng-template>
</p-table>

<app-paginator
  [totalItems]="totalItems"
  (pageChange)="onPageChange($event)"></app-paginator>

<ng-template #notFound>
  <app-paragraph>Nenhum item encontrado.</app-paragraph>
</ng-template>

<app-loading></app-loading>
