import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

interface Palette {
  primary: string;
  secondary: string;
}

interface AboutUs {
  palette: Palette;
}

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  applyColors(aboutUs: AboutUs): void {
    if (isPlatformBrowser(this.platformId) && aboutUs.palette) {
      document.documentElement.style.setProperty(
        '--on-secondary',
        aboutUs?.palette?.primary
      );

      document.documentElement.style.setProperty(
        '--on-secondary-container',
        aboutUs?.palette?.secondary
      );

      document.documentElement.style.setProperty(
        '--secondary-60',
        aboutUs?.palette?.primary
      );

      document.documentElement.style.setProperty(
        '--secondary',
        aboutUs?.palette?.primary
      );
    }
  }
}
