<div class="row auth-base h-100">
  <div class="col-12">
    <div
      class="auth-base-col container-fluid p-0 h-100 d-flex align-items-center">
      <div>
        <ng-template
          *ngComponentOutlet="currentStage.component"
          [ngComponentOutletContext]="{ nextStage: nextStage }"></ng-template>
      </div>
    </div>
  </div>
</div>
