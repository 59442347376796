import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { FormUtils } from '@modules/pages/vehicles/components/rental/utils/rental.util';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { OptionsSkeletonComponent } from '@shared/components/skeleton/options/options-skeleton.component';

@LoadingId(LoadingIdEnum.getVehicleByIdPlans)
@Component({
  selector: 'app-rental-quantity',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ParagraphComponent,
    ButtonComponent,
    OptionsSkeletonComponent,
  ],
  templateUrl: './rental-quantity.component.html',
  styleUrl: './rental-quantity.component.scss',
})
export class RentalQuantityComponent
  extends LoadingBaseComponent
  implements OnInit
{
  @Input() form!: FormGroup;
  @Input() quantity: number = 1;
  @Input() requiresUpdateQuantity: boolean = false;

  labelField: string = 'quantity';

  override ngOnInit(): void {
    this.addControlToForm();
    this.checkRequiresUpdateQuantity();
  }

  addControlToForm(): void {
    FormUtils.addControlToForm(this.form, this.labelField, this.quantity);
  }

  increment(): void {
    this.quantity++;

    this.setValueQuantity(this.quantity);
  }

  decrement(): void {
    if (this.quantity > 1) {
      this.quantity--;

      this.setValueQuantity(this.quantity);
    }
  }

  checkRequiresUpdateQuantity(): void {
    if (this.requiresUpdateQuantity) this.setValueQuantity(this.quantity);
  }

  setValueQuantity(quantity: number): void {
    this.form?.get(this.labelField)?.setValue(quantity);
  }

  updateQuantity(event: Event): void {
    const inputValue = (event.target as HTMLInputElement)?.value;

    this.quantity = parseInt(inputValue, 10) || 1;

    this.setValueQuantity(this.quantity);
  }
}
