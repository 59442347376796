<app-base-skeleton *ngIf="isLoading">
  <div
    class="skeleton-custom-col skeleton-custom-border mt-3"
    *appNgTemplateRepeat="quantityRepeteLoading">
    <p-skeleton
      shape="circle"
      styleClass="neutral-variant-95 mb-4"
      width="1.25rem"
      height="1.25rem"></p-skeleton>

    <p-skeleton
      styleClass="neutral-variant-95"
      width="9rem"
      height="1.5rem"></p-skeleton>
  </div>
</app-base-skeleton>
