import { isPlatformBrowser } from '@angular/common';
import {
  ContentChild,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  TemplateRef,
} from '@angular/core';
import { AuthService } from '@core/services/auth/auth.service';

@Directive({
  selector: '[appShowIfLoggedIn]',
  standalone: true,
})
export class ShowIfLoggedInDirective implements OnInit {
  @Input() data!: string;
  @ContentChild(TemplateRef) templateRef!: TemplateRef<{ data: string }>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.authService.isLoggedIn().then(isLoggedIn => {
        if (isLoggedIn) {
          const paragraphElement =
            this.elementRef.nativeElement.querySelector('p');

          const text = this.renderer.createText(this.data);

          this.renderer.setProperty(paragraphElement, 'innerHTML', '');
          this.renderer.appendChild(paragraphElement, text);
        }
      });
    }
  }
}
