import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private _timeLeft$ = new BehaviorSubject<number>(0);
  private timerInterval!: NodeJS.Timeout;

  timeLeft$: Observable<number> = this._timeLeft$.asObservable();

  get resendTimer$(): Observable<string> {
    return this.timeLeft$.pipe(
      map(time => (time > 0 ? this.formatTime(time) : ''))
    );
  }

  startTimer(seconds: number): void {
    this._timeLeft$.next(seconds);

    this.timerInterval = setInterval(() => {
      const currentTime = this._timeLeft$.value - 1;

      this._timeLeft$.next(currentTime);

      if (currentTime <= 0) this.stopTimer();
    }, 1000);
  }

  stopTimer(): void {
    clearInterval(this.timerInterval);

    this._timeLeft$.next(0);
  }

  formatTime(seconds: number | null): string {
    if (seconds === null) return '';

    const minutes = seconds < 60 ? 0 : Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
}
