import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { getFirstCharacters } from '@core/utils/get-first-characters.util';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { UserProposal } from '@modules/pages/account-summary/components/user-proposal/models/user-proposal.model';
import { UserProposalService } from '@modules/pages/account-summary/components/user-proposal/services/user-proposal.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { SharedModule } from '@shared/shared.modules';

@Component({
  selector: 'app-user-proposal',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    MatTableModule,
    ButtonComponent,
    ParagraphComponent,
  ],
  templateUrl: './user-proposal.component.html',
  styleUrl: './user-proposal.component.scss',
})
export class UserProposalComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'createdAt',
    'amount',
    'status',
    'downloadUrl',
  ];
  dataSource = new MatTableDataSource<UserProposal>();
  getFirstCharacters = getFirstCharacters;

  constructor(private userProposalService: UserProposalService) {}

  ngOnInit(): void {
    this.getUserProposal();
  }

  onDownload(url: string): void {
    window.open(url, '_blank');
  }

  getClassesStatus(status: boolean): string {
    if (status) return 'active';
    else return 'expired';
  }

  getUserProposal(): void {
    this.userProposalService.getUserProposal().subscribe({
      next: data => (this.dataSource.data = data),
    });
  }
}
