import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonsPaginatorUtil } from '@shared/components/paginator/components/buttons/utils/buttons.util';

@Component({
  selector: 'app-buttons-paginator',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    ParagraphComponent,
  ],
  templateUrl: './buttons.component.html',
  styleUrl: './buttons.component.scss',
})
export class ButtonsPaginatorComponent implements OnChanges {
  @Input() currentPage = 1;
  @Input() pageSize = 12;
  @Input() totalItems: number = 0;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  pages: number[] = [];

  ngOnChanges(): void {
    this.updatePages();
  }

  get visiblePages(): number[] {
    return ButtonsPaginatorUtil.getVisiblePages(
      this.currentPage,
      this.pages,
      2
    );
  }

  private updatePages(): void {
    this.pages = ButtonsPaginatorUtil.updatePages(
      Math.ceil(this.totalItems / this.pageSize)
    );
  }

  private emitPageChange(): void {
    this.pageChange.emit(this.currentPage);
  }

  onPageChange(page: number): void {
    this.currentPage = page;

    this.emitPageChange();
  }
}
