import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Stage } from '@core/models/flow.model';
import {
  AccountSummaryFlowName,
  AccountSummaryFlowTitle,
} from '@modules/pages/account-summary/components/account-summary-base/enums/account-summary.enum';
import { AccountSummaryFlowService } from '@modules/pages/account-summary/components/account-summary-base/services/account-summary.service';
import {
  AccountSummaryAllowedComponents,
  AccountSummaryFlowType,
} from '@modules/pages/account-summary/components/account-summary-base/types/account-summary.type';
import { CartFlowButtons } from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { ButtonLogoutComponent } from '@shared/components/button-logout/button-logout.component';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'app-account-summary-base',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ButtonLogoutComponent],
  templateUrl: './account-summary-base.component.html',
})
export class AccountSummaryBaseComponent implements OnInit {
  @Input() flowName!: AccountSummaryFlowName;

  @Output() currentFlowTitle = new EventEmitter<string>();

  currentFlow!: AccountSummaryFlowType;
  showClearButton: boolean = false;
  clearButtonLabel: string = CartFlowButtons.back;
  nextButtonLabel: string = CartFlowButtons.advance;
  buttonDisabled: boolean = true;

  constructor(protected accountSummaryFlowService: AccountSummaryFlowService) {}

  ngOnInit(): void {
    this.accountSummaryFlowService.initFlow();
    this.initializeRegistrationFlow();
    this.subscribeToCurrentStageChanges();
    this.updateButtonDisabled();
  }

  initializeRegistrationFlow(): void {
    this.currentFlow = this.accountSummaryFlowService
      .getFlowService()
      .getFlow(this.flowName);
  }

  emitCurrentFlowTitle(title: string): void {
    this.currentFlowTitle.emit(title);
  }

  subscribeToCurrentStageChanges(): void {
    this.accountSummaryFlowService
      .getFlowService()
      .currentStage$.subscribe(({ title }) => {
        this.updateButtonLabels();
        this.emitCurrentFlowTitle(title);
      });
  }

  nextStage(): void {
    this.accountSummaryFlowService.getFlowService().nextStage();
  }

  previousStage(): void {
    this.accountSummaryFlowService.getFlowService().previousStage();
  }

  onButtonClick(): void {
    if (this.stageEdit) this.accountSummaryFlowService.updateUserData();
    else this.nextStage();
  }

  updateButtonLabels(): void {
    switch (this.currentTitle) {
      case AccountSummaryFlowTitle.editProfile:
        this.showClearButton = true;
        this.nextButtonLabel = CartFlowButtons.save;
        break;
      default:
        this.showClearButton = false;
        this.nextButtonLabel = CartFlowButtons.advance;
        break;
    }
  }

  updateButtonDisabled(): void {
    this.accountSummaryFlowService.disableButton$.subscribe(
      changes => (this.buttonDisabled = changes)
    );
  }

  get currentStage(): Stage<AccountSummaryAllowedComponents> {
    return this.accountSummaryFlowService.getFlowService().currentStage;
  }

  get stageEdit(): boolean {
    return (
      this.accountSummaryFlowService.getFlowService().currentStage.title ===
      AccountSummaryFlowTitle.editProfile
    );
  }

  get currentTitle(): string {
    return this.accountSummaryFlowService.getFlowService().currentStage.title;
  }
}
