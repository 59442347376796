import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ShowSignupGateModalDirective } from '@core/directives/show-signup-gate-modal.directive';
import { BannerActionOpenModal } from '@shared/components/banner/components/banner-actions/enums/banner-actions.enum';
import { BannerAction } from '@shared/components/banner/models/banner.model';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'app-banner-actions',
  standalone: true,
  imports: [CommonModule, ShowSignupGateModalDirective, ButtonComponent],
  templateUrl: './banner-actions.component.html',
})
export class BannerActionsComponent {
  @Input() actions: BannerAction[] = [];

  loginAndSignup: string = BannerActionOpenModal.loginAndSignup;

  getButtonLayout(index: number): string {
    const baseClasses =
      'border rounded-5 text-decoration-none text-white fw-500 ';
    const additionalClasses =
      index === 0 ? 'bg-primary-color me-2' : 'border-white';

    return baseClasses + additionalClasses;
  }
}
