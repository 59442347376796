import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { CodeInputService } from '@core/services/code-input.service';
import { LoadingService } from '@core/services/loading.service';
import { NotificationService } from '@core/services/notification.service';
import { AuthFlowService } from '@modules/auth/services/auth-flow/auth-flow.service';
import {
  FlowName,
  UserStatusFlow,
} from '@modules/auth/services/auth-flow/enums/auth-flow.enum';
import { ValidPasswordREsponse } from '@modules/auth/services/user/models/user.model';
import { UserService } from '@modules/auth/services/user/user.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { TimerService } from '@shared/services/timer/timer.service';
import { Observable } from 'rxjs';

@LoadingId(LoadingIdEnum.validPassword)
@Component({
  selector: 'app-confirm-email',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    ParagraphComponent,
    ButtonComponent,
    AutoFocusDirective,
  ],
  templateUrl: './confirm-email.component.html',
  styleUrl: './confirm-email.component.scss',
})
export class ConfirmEmailComponent
  extends LoadingBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('codeInput1') codeInput1!: ElementRef;
  @ViewChild('codeInput2') codeInput2!: ElementRef;
  @ViewChild('codeInput3') codeInput3!: ElementRef;
  @ViewChild('codeInput4') codeInput4!: ElementRef;
  @ViewChild('codeInput5') codeInput5!: ElementRef;
  @ViewChild('codeInput6') codeInput6!: ElementRef;

  verificationForm!: FormGroup;
  timeLeft$!: Observable<number>;
  resendTimer$!: Observable<string>;
  codeInputs: ElementRef[] = [];

  constructor(
    public timerService: TimerService,
    protected override loadingService: LoadingService,
    protected authFlowService: AuthFlowService,
    private codeInputService: CodeInputService,
    private notification: NotificationService,
    private userService: UserService,
    private fb: FormBuilder
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initialTimer();
    this.initialForm();
  }

  ngAfterViewInit(): void {
    this.codeInputs = [
      this.codeInput1,
      this.codeInput2,
      this.codeInput3,
      this.codeInput4,
      this.codeInput5,
      this.codeInput6,
    ];
  }

  initialTimer(): void {
    this.timeLeft$ = this.timerService.timeLeft$;
    this.startTimer();
    this.resendTimer$ = this.timerService.resendTimer$;
  }

  initialForm(): void {
    this.verificationForm = this.fb.group({
      code1: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      code2: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      code3: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      code4: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      code5: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
      code6: ['', [Validators.required, Validators.pattern(/^[0-9]$/)]],
    });
  }

  validPassword(code: string): void {
    this.userService
      .validPassword({
        email: this.userEmail,
        password: code,
      })
      .subscribe({
        next: ({ status, name, token }: ValidPasswordREsponse) => {
          const flowName =
            status === UserStatusFlow.pending
              ? FlowName.signup
              : FlowName.login;

          this.authFlowService.switchToSignupFlow({
            email: this.userEmail,
            name,
            token,
            flowName,
          });
        },
        error: () => {
          this.verificationForm.reset();
          this.focusInput();
        },
      });
  }

  updateCode(): void {
    this.userService.createUser(this.userEmail).subscribe({
      next: () => {
        this.notification.showToast(
          'Foi reenviado um novo código para seu e-mail.'
        );

        this.startTimer();
      },
    });
  }

  get userEmail(): string {
    return this.authFlowService.getUserEmail;
  }

  onCodeInput(event: Event, nextInput?: HTMLInputElement): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (value && nextInput) nextInput.focus();
  }

  onResendCode(): void {
    this.verificationForm.reset();
    this.focusInput();
    this.updateCode();
  }

  onSubmit(): void {
    if (this.verificationForm.valid) {
      const code = Object.values(this.verificationForm.value).reduce(
        (acc: string, cur) => acc + cur,
        ''
      );

      this.validPassword(code);
    }
  }

  onPaste(event: ClipboardEvent): void {
    this.codeInputService.pasteCodeToInputs(
      event,
      this.codeInputs,
      this.verificationForm,
      ['code1', 'code2', 'code3', 'code4', 'code5', 'code6']
    );
  }

  focusInput(): void {
    this.codeInput1.nativeElement.focus();
  }

  startTimer(): void {
    this.timerService.startTimer(180);
  }
}
