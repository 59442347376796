<p-dialog
  [modal]="true"
  [closable]="false"
  [(visible)]="visible"
  [styleClass]="layout"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [draggable]="false"
  [resizable]="false">
  <div
    *ngIf="titleLabel"
    class="d-flex mb-4 align-items-start justify-content-between">
    <h2 class="m-0 fs-3xl lh-3xl fw-600">{{ titleLabel }}</h2>

    <app-button
      variant="resetButton"
      [isShowingCloseIcon]="true"
      (buttonClick)="closeModal()">
    </app-button>
  </div>

  <ng-content></ng-content>

  <div
    *ngIf="confirmation"
    mat-dialog-actions
    align="start"
    class="modal-custom-footer p-0 m-0 mt-4 d-flex align-items-end">
    <app-button
      layout="button-confirm sp-mr-x-small"
      [label]="confirmButtonName"
      (buttonClick)="onConfirmModal()"></app-button>
  </div>
</p-dialog>
