import { Injectable } from '@angular/core';
import { FlowService } from '@core/services/flow.service';
import { AccountSummaryComponent } from '@modules/pages/account-summary/account-summary.component';
import {
  AccountSummaryFlowName,
  AccountSummaryFlowTitle,
} from '@modules/pages/account-summary/components/account-summary-base/enums/account-summary.enum';
import { AccountSummaryFlows } from '@modules/pages/account-summary/components/account-summary-base/models/account-summary.model';
import { AccountSummaryAllowedComponents } from '@modules/pages/account-summary/components/account-summary-base/types/account-summary.type';
import { EditProfileFormComponent } from '@modules/pages/edit-profile-form/edit-profile-form.component';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountSummaryFlowService {
  private editUserSubject = new Subject<boolean>();
  editUser$ = this.editUserSubject.asObservable();

  private disableButtonSubject = new Subject<boolean>();
  disableButton$ = this.disableButtonSubject.asObservable();

  constructor(
    private flowService: FlowService<AccountSummaryAllowedComponents>
  ) {}

  initFlow(): void {
    const flows: AccountSummaryFlows = {
      users: {
        stages: [
          {
            title: AccountSummaryFlowTitle.myAccount,
            component: AccountSummaryComponent,
          },
          {
            title: AccountSummaryFlowTitle.editProfile,
            component: EditProfileFormComponent,
          },
        ],
      },
    };

    Object.entries(flows).forEach(([flowName, flow]) => {
      this.flowService.registerFlow(flowName as AccountSummaryFlowName, flow);
    });
  }

  getFlowService(): FlowService<AccountSummaryAllowedComponents> {
    return this.flowService;
  }

  getUserData(): Observable<boolean> {
    return this.editUser$;
  }

  updateUserData(): void {
    this.editUserSubject.next(true);
  }

  disableButton(action: boolean = false): void {
    this.disableButtonSubject.next(action);
  }
}
