import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  ProposalFilter,
  ProposalResponse,
} from '@modules/pages/vehicles/components/proposal/cart-item-base/models/proposal.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProposalService extends BaseApiService {
  sendProposal(data: ProposalFilter): Observable<ProposalResponse> {
    return this.makeRequest(
      () =>
        this.http.post<ProposalResponse>(
          `${apiUrl}/${apiVersion}/proposals/submit`,
          { ...data }
        ),
      LoadingIdEnum.sendProposal,
      true
    );
  }
}
