<section class="admin edit-admin-banner text-black row">
  <div class="col-12">
    <form
      appAutoFocus
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      class="d-flex flex-column gap-4">
      <ng-container *ngIf="itemsArray.length > 0; else notItem">
        <div
          cdkDropList
          cdkDropListOrientation="vertical"
          (cdkDropListDropped)="onReorderList($event)">
          <div
            *ngFor="let item of itemsArray.controls; let index = index"
            cdkDrag
            formArrayName="items"
            class="bg-white p-4 rounded-4">
            <div [formGroupName]="index" class="cdk-bg">
              <div class="row">
                <div class="col-12 col-md-6">
                  <h2>Banner {{ index + 1 }}</h2>
                </div>

                <div class="col-12 col-md-6">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Caption</mat-label>

                    <input matInput type="text" formControlName="caption" />
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Heading</mat-label>

                    <input matInput type="text" formControlName="title" />
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Texto</mat-label>

                    <input matInput type="text" formControlName="description" />
                  </mat-form-field>

                  <app-adm-upload
                    [name]="'banner-image' + index"
                    [image]="item.value.image"
                    (upload)="onUpload($event, index)"
                    (clear)="onUploadClear(index)"></app-adm-upload>

                  <div
                    class="buttons d-flex justify-content-start gap-2 mt-3"
                    *ngIf="itemsArray.length > 1">
                    <app-button
                      icon="delete_outline"
                      label="Remover"
                      layout="button-border button-size-auto"
                      variant="transparent"
                      (buttonClick)="removeItem(index)"></app-button>

                    <app-button
                      icon="drag_handle"
                      label="Ordenar"
                      layout="button-border button-size-auto cursor-move"
                      variant="transparent"></app-button>
                  </div>

                  <div
                    class="buttons buttons-banner mt-3"
                    *ngIf="itemsArray.length > 0">
                    <div formArrayName="actions">
                      <ng-container
                        *ngFor="
                          let buttonGroup of getActionsControls(item);
                          let j = index
                        ">
                        <div
                          [formGroupName]="j"
                          class="d-md-flex align-items-center gap-2 mt-3">
                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-3 mb-md-0">
                            <mat-label>
                              Label Botão
                              {{ j === 0 ? 'Primário' : 'Secundário' }}
                            </mat-label>

                            <input
                              matInput
                              type="text"
                              formControlName="title" />
                          </mat-form-field>

                          <mat-form-field
                            appearance="outline"
                            class="w-100 mb-3 mb-md-0">
                            <mat-label>
                              Link Botão
                              {{ j === 0 ? 'Primário' : 'Secundário' }}
                            </mat-label>

                            <input
                              matInput
                              type="text"
                              formControlName="link" />
                          </mat-form-field>

                          <app-button
                            icon="delete_outline"
                            label="Remover"
                            layout="button-border button-size-auto"
                            variant="transparent"
                            (buttonClick)="
                              removeButtonItem(index, j)
                            "></app-button>
                        </div>
                      </ng-container>
                    </div>

                    <div
                      class="d-flex justify-content-center mt-3"
                      *ngIf="item.value.actions.length < 2">
                      <app-button
                        icon="add_outline"
                        label=" Adicionar Botão"
                        layout="button-border"
                        variant="transparent"
                        (buttonClick)="addButtonItem(index)"></app-button>
                    </div>
                  </div>
                </div>
              </div>

              <app-line
                *ngIf="itemsArray.length > 1"
                class="mt-4 d-block"></app-line>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="d-flex justify-content-center">
        <app-button
          icon="add_outline"
          label="Adicionar item"
          layout="button-border"
          variant="transparent"
          (buttonClick)="addItem()"></app-button>
      </div>

      <app-line *ngIf="itemsArray.length > 0"></app-line>

      <div
        class="d-flex align-items-center justify-content-center gap-2"
        *ngIf="itemsArray.length > 0">
        <app-button
          label="Cancelar"
          layout="button-border"
          variant="transparent"
          (buttonClick)="navigationService.navigateBack()"></app-button>

        <app-button
          type="submit"
          label="Salvar"
          [disabled]="form.invalid"></app-button>
      </div>
    </form>

    <ng-template #notItem>Nenhum item</ng-template>
  </div>
</section>
