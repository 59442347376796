import { Injectable } from '@angular/core';
import { apiUrl, apiVersion, bdAuth } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import {
  BrandingAdminParams,
  BrandingAdminResponse,
} from '@modules/admin/edit/components/branding/models/branding.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandingAdminService extends BaseApiService {
  create(data: BrandingAdminParams): Observable<BrandingAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.post<BrandingAdminResponse>(
          `${apiUrl}/${apiVersion}/whitelabels`,
          {
            ...data,
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  update(data: BrandingAdminParams): Observable<BrandingAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.put<BrandingAdminResponse>(
          `${apiUrl}/${apiVersion}/whitelabels/${data.domain}`,
          {
            ...data,
          }
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }

  branding(uid: string): Observable<BrandingAdminResponse> {
    return this.makeRequest(
      () =>
        this.http.get<BrandingAdminResponse>(
          `${apiUrl}/${apiVersion}/whitelabels/${uid}`
        ),
      LoadingIdEnum.admin,
      true,
      bdAuth
    );
  }
}
