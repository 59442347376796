import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateFormatPipe } from '@shared/pipes/date-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DateFormatPipe],
  exports: [DateFormatPipe],
})
export class SharedModule {}
