import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RefreshPageService {
  constructor(private router: Router) {}

  refresh(router?: string): void {
    this.router
      .navigate([router ? router : '/'])
      .then(() => window.location.reload());
  }
}
