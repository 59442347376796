<div [formGroup]="form" class="rental-period d-block mt-4">
  <app-paragraph>Prazo de locação (meses)</app-paragraph>

  <app-options-skeleton [isLoading]="isLoading"></app-options-skeleton>

  <div
    class="mt-3 d-flex aligm-items-center justify-content-center gap-2"
    *ngIf="!isLoading">
    <label
      *ngFor="let period of periods"
      [for]="period"
      [ngClass]="['btn', selectedPeriod === period ? 'active' : '']">
      <input
        type="radio"
        [id]="period"
        [value]="period"
        [formControlName]="labelField"
        (change)="selectPeriod(period)" />

      {{ period }}
    </label>
  </div>
</div>
