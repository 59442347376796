<app-base-skeleton *ngIf="isLoading">
  <div class="skeleton-custom-col" *appNgTemplateRepeat="quantityRepeteLoading">
    <div class="d-flex flex-column gap-4">
      <p-skeleton width="27rem" height="0.5rem"></p-skeleton>

      <div class="d-flex gap-2">
        <p-skeleton width="1rem" height="1rem"></p-skeleton>

        <p-skeleton width="23rem" height="1rem"></p-skeleton>
      </div>
    </div>
  </div>
</app-base-skeleton>
