import { FormGroup, ValidationErrors } from '@angular/forms';
import { ValidationSchema } from '@core/utils/validation-helpers/models/validation-schema.model';

export function hasFieldValidationError({
  form,
  field,
  validation = 'required',
}: ValidationSchema): boolean {
  const control = form.get(field);

  return (
    !!control &&
    control.hasError(validation) &&
    control.touched &&
    control.dirty
  );
}

export function imageNameValidator(group: FormGroup): ValidationErrors | null {
  const textControl = group.get('name');
  const imageControl = group.get('icon') as FormGroup;

  const hasText = textControl?.value?.trim() !== '';
  const hasImage = imageControl?.get('storage')?.value?.trim() !== '';

  if ((hasText && hasImage) || (!hasText && !hasImage)) return null;
  else return { imageNameInvalid: true };
}
