import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { PlansData } from '@modules/pages/vehicles/components/rental/rental-plan-option/models/rental-plan-option.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RentalPlanOptionService extends BaseApiService {
  getPlans(): Observable<PlansData[]> {
    return this.makeRequest(
      () => this.http.get<PlansData[]>(`${apiUrl}/${apiVersion}/plans`),
      LoadingIdEnum.getPlanDescription
    );
  }
}
