<section
  class="banner banner-bg banner-skeleton d-flex align-items-center justify-content-center"
  [ngClass]="{ 'd-none': !isLoading }">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-base-skeleton *ngIf="isLoading">
          <div
            class="skeleton-custom-col position-absolute bottom-0 d-flex flex-column gap-2"
            *appNgTemplateRepeat="1">
            <p-skeleton
              styleClass="neutral-variant-80"
              width="9rem"
              height="1.5rem"></p-skeleton>
            <p-skeleton
              styleClass="neutral-variant-80"
              width="20rem"
              height="2.5rem"></p-skeleton>
            <p-skeleton
              styleClass="neutral-variant-80"
              width="13rem"
              height="1.25rem"></p-skeleton>
            <p-skeleton
              styleClass="neutral-variant-80"
              width="13rem"
              height="1.25rem"></p-skeleton>
            <p-skeleton
              styleClass="neutral-variant-80"
              width="13rem"
              height="1.25rem"></p-skeleton>

            <div class="d-flex gap-2">
              <p-skeleton
                styleClass="neutral-variant-80"
                width="7rem"
                height="2rem"></p-skeleton>
              <p-skeleton
                styleClass="neutral-variant-80"
                width="7rem"
                height="2rem"></p-skeleton>
            </div>
          </div>
        </app-base-skeleton>
      </div>
    </div>
  </div>
</section>
