import { Routes } from '@angular/router';
import { admin, adminEdit, adminLogin, whiteLabel } from '@api/app.api';
import { authAdminGuard } from '@core/guards/auth-admin.guard';
import { ShowFooterResolver } from '@core/resolvers/footer.resolver';
import { EditAdminComponent } from '@modules/admin/edit/edit.component';
import { LoginAdminComponent } from '@modules/admin/login/login.component';
import { WhiteLabelComponent } from '@modules/admin/white-label/white-label.component';
import { HomeComponent } from '@modules/pages/home/home.component';
import { VehiclesDetailsComponent } from '@modules/pages/vehicles/vehicles.component';
import { ViewVehiclesComponent } from '@modules/pages/view-vehicles/view-vehicles.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: { showFooter: ShowFooterResolver },
  },
  {
    path: 'veiculos',
    component: ViewVehiclesComponent,
    resolve: { showFooter: ShowFooterResolver },
  },
  {
    path: 'veiculos/detalhes/:id',
    component: VehiclesDetailsComponent,
    resolve: { showFooter: ShowFooterResolver },
  },
  {
    path: `${admin}/${adminLogin}`,
    component: LoginAdminComponent,
  },
  {
    path: `${admin}/${whiteLabel}`,
    component: WhiteLabelComponent,
    canActivate: [authAdminGuard],
  },
  {
    path: `${admin}/${adminEdit}`,
    component: EditAdminComponent,
    canActivate: [authAdminGuard],
  },
  {
    path: `${admin}/${adminEdit}/:id/:domain`,
    component: EditAdminComponent,
    canActivate: [authAdminGuard],
  },
];
