<form
  appAutoFocus
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="admin edit-admin-faq d-flex flex-column gap-4">
  <p-orderList
    [value]="itemsArray.controls"
    [dragdrop]="true"
    *ngIf="itemsArray.controls.length > 0; else notItem">
    <ng-template
      let-item
      let-index="index"
      pTemplate="item"
      formArrayName="items">
      <div [formGroupName]="index">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Pergunta</mat-label>

          <input matInput type="text" formControlName="question" />
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Resposta</mat-label>

          <input matInput type="text" formControlName="answer" />
        </mat-form-field>

        <div
          class="buttons d-flex justify-content-end gap-2"
          *ngIf="itemsArray.controls.length > 1">
          <app-button
            icon="delete_outline"
            label="Remover"
            layout="button-border button-size-auto"
            variant="transparent"
            (buttonClick)="removeItem(index)"></app-button>

          <app-button
            icon="drag_handle"
            label="Ordenar"
            layout="button-border button-size-auto cursor-move"
            variant="transparent"></app-button>
        </div>
      </div>
    </ng-template>
  </p-orderList>

  <app-line></app-line>

  <div class="d-flex justify-content-center">
    <app-button
      icon="add_outline"
      label="Adicionar pergunta"
      layout="button-border"
      variant="transparent"
      (buttonClick)="addItem()"></app-button>
  </div>

  <app-line *ngIf="itemsArray.controls.length > 0"></app-line>

  <div
    class="d-flex align-items-center justify-content-center gap-2"
    *ngIf="itemsArray.controls.length > 0">
    <app-button
      label="Cancelar"
      layout="button-border"
      variant="transparent"
      (buttonClick)="navigationService.navigateBack()"></app-button>

    <app-button
      type="submit"
      label="Salvar"
      [disabled]="form.invalid"></app-button>
  </div>
</form>

<ng-template #notItem>Nenhum item</ng-template>
