<div class="proposal-empty d-flex align-items-md-center flex-column">
  <app-icons
    icon="add_shopping_cart"
    class="bg-icon rounded-circle"></app-icons>

  <h3 class="fs-xl fw-600 lh-xl mt-3 w-50 w-md-100 mx-auto">
    Nenhum item adicionado
  </h3>

  <app-paragraph variant="mt-2 w-65 w-md-100 mx-auto">
    Você ainda não adicionou nenhum item à sua proposta.
  </app-paragraph>
</div>
