import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { AccountSummaryBaseComponent } from '@modules/pages/account-summary/components/account-summary-base/account-summary-base.component';
import { UserProfileData } from '@modules/pages/account-summary/components/user-profile-card/models/user-profile.card.model';
import { UserProfileService } from '@modules/pages/account-summary/components/user-profile-card/services/user-profile-card.service';
import { ButtonLogoutComponent } from '@shared/components/button-logout/button-logout.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { IconsComponent } from '@shared/components/icons/icons.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { CnpjPipe } from '@shared/pipes/cnpj-format.pipe';

@LoadingId(LoadingIdEnum.getUserDetailsById)
@Component({
  selector: 'app-user-profile-card',
  standalone: true,
  imports: [
    CommonModule,
    IconsComponent,
    ParagraphComponent,
    ButtonComponent,
    ButtonLogoutComponent,
    CnpjPipe,
  ],
  templateUrl: './user-profile-card.component.html',
})
export class UserProfileCardComponent
  extends LoadingBaseComponent
  implements OnInit
{
  userData!: UserProfileData;

  constructor(
    protected override loadingService: LoadingService,
    private accountSummaryBase: AccountSummaryBaseComponent,
    private userProfileService: UserProfileService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getUserDetailsById();
  }

  onUserEdit(): void {
    this.accountSummaryBase.nextStage();
  }

  getUserDetailsById(): void {
    this.userProfileService.getUserDetailsById().subscribe({
      next: userData => (this.userData = userData),
    });
  }
}
