<div class="faqs">
  <div class="row">
    <div [ngClass]="colLeft">
      <h2>Dúvidas frequentes</h2>
    </div>

    <div class="faq-item" [ngClass]="colRight">
      <mat-accordion *ngIf="isJavaScriptEnabled">
        <mat-expansion-panel
          *ngFor="let faq of faqList"
          [expanded]="faq.isOpen"
          class="line line-after">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ faq.question }}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <app-paragraph
            variant="fs-md lh-md"
            [html]="faq.answer"></app-paragraph>
        </mat-expansion-panel>
      </mat-accordion>

      <noscript>
        <div *ngFor="let faq of faqList" class="line line-after noscript">
          <input type="checkbox" id="faq-{{ faq.id }}" class="faq-checkbox" />

          <label role="button" for="faq-{{ faq.id }}" class="faq-header">
            {{ faq.question }}

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              aria-hidden="true"
              focusable="false"
              class="faq-arrow static-arrow">
              <path
                d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"></path>
            </svg>
          </label>

          <div class="faq-content">
            <app-paragraph
              variant="fs-16 fh-24"
              [html]="faq.answer"></app-paragraph>
          </div>
        </div>
      </noscript>
    </div>
  </div>
</div>
