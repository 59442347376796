import { Component, OnInit } from '@angular/core';
import { getFirstCharacters } from '@core/utils/get-first-characters.util';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { CartItemBaseService } from '@modules/pages/vehicles/components/proposal/cart-item-base/services/cart-item-base.service';
import { ProgressbarComponent } from '@modules/pages/vehicles/components/proposal/progressbar/progressbar.component';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';

@Component({
  selector: 'app-proposal-sent',
  standalone: true,
  imports: [ProgressbarComponent, ParagraphComponent],
  templateUrl: './proposal-sent.component.html',
  styleUrl: './proposal-sent.component.scss',
})
export class ProposalSentComponent implements OnInit {
  constructor(
    private cartItemBaseService: CartItemBaseService,
    private cartService: CartService
  ) {}

  ngOnInit(): void {
    this.clearCart();
  }

  clearCart(): void {
    this.cartService.clearCart(true);
  }

  get getIdProposal(): string {
    return getFirstCharacters(this.cartItemBaseService.getIdProposal, 8);
  }
}
