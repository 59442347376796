/* eslint-disable @typescript-eslint/no-explicit-any */
import { Constructor } from '@angular/cdk/table';
import { LoadingSkeleton } from '@core/utils/loading-base.util';

export function LoadingId(loadingIds: string | string[]): any {
  return function (target: Constructor<LoadingSkeleton>) {
    target.prototype.loadingIds = Array.isArray(loadingIds)
      ? loadingIds
      : [loadingIds];

    target.prototype.isLoadingById = function (loadingId: string): boolean {
      return this.loadingService.isLoading(loadingId);
    };
  };
}
