import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private messageService: MessageService) {}

  showToast(
    message: string,
    title: string = 'Sucesso',
    type:
      | 'success'
      | 'info'
      | 'warn'
      | 'error'
      | 'contrast'
      | 'secondary' = 'success'
  ): void {
    this.messageService.add({
      severity: type,
      summary: title,
      detail: message,
      key: 'bl',
    });
  }
}
