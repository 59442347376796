<footer class="footer overflow-hidden">
  <app-line></app-line>

  <div class="container pt-3 pt-md-5">
    <div class="row">
      <div class="col-sm-12 col-lg-2 col-md-6 mb-4 mb-lg-0">
        <app-logo [width]="96" [height]="24" [isLogoLoader]="false"></app-logo>
      </div>

      <div class="col-sm-12 col-lg-10">
        <app-footer-menu></app-footer-menu>
      </div>
    </div>
  </div>

  <app-line class="d-none d-md-block"></app-line>

  <app-copyright></app-copyright>
</footer>
