<div
  class="progress-bar-wrapper d-flex align-items-center justify-content-center">
  <mat-progress-bar
    class="custom-progress-bar me-2"
    mode="determinate"
    value="100"></mat-progress-bar>

  <mat-progress-bar
    class="custom-progress-bar"
    mode="determinate"
    [value]="value"></mat-progress-bar>
</div>
