<form
  appAutoFocus
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="admin edit-admin-branding">
  <div class="col-12 col-lg-6 mx-auto">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Nome</mat-label>

      <input
        matInput
        type="text"
        formControlName="name"
        (change)="onChangeName()" />
    </mat-form-field>
  </div>

  <app-line></app-line>

  <div class="col-12 col-lg-6 mx-auto logos-branding">
    <app-adm-upload
      name="logoMain"
      label="Padrão"
      alt="Logo principal"
      class="bg-black-custom"
      [image]="logoMainSelectedImageSrc"
      (upload)="onUpload($event)"
      (clear)="onUploadClear()"></app-adm-upload>

    <app-adm-upload
      name="logoInvert"
      label="Invertida (para aplicação em fundos escuros)"
      alt="Logo secundário"
      class="mt-3 mb-3 d-block bg-black-custom"
      [image]="logoInvertedSelectedImageSrc"
      (upload)="onUpload($event, 'logoInverted')"
      (clear)="onUploadClear('logoInverted')"></app-adm-upload>
  </div>

  <app-line></app-line>

  <div class="col-12 col-lg-6 mx-auto d-flex align-items-baseline gap-3">
    <p-colorPicker
      formControlName="colorButton"
      (onChange)="onChangeColor($event.value.toString())" />

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cor do botão</mat-label>

      <input
        matInput
        type="text"
        formControlName="colorButtonField"
        [value]="colorButton" />
    </mat-form-field>
  </div>

  <app-line></app-line>

  <div class="col-12 col-lg-6 mx-auto d-flex align-items-baseline gap-3">
    <p-colorPicker
      formControlName="colorTextButton"
      (onChange)="onChangeColor($event.value.toString(), 'text')" />

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Cor do texto do botão</mat-label>

      <input
        matInput
        type="text"
        formControlName="colorTextButtonField"
        [value]="colorTextButton" />
    </mat-form-field>
  </div>

  <app-line></app-line>

  <div class="col-12 col-lg-6 mx-auto">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Rodapé - CNPJ</mat-label>

      <input matInput type="text" formControlName="cnpj" />
    </mat-form-field>
  </div>

  <app-line></app-line>

  <div class="col-12 col-lg-6 mx-auto">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Rodapé - Copyright</mat-label>

      <input matInput type="text" formControlName="copyright" />
    </mat-form-field>
  </div>

  <div class="d-flex align-items-center justify-content-center gap-2">
    <app-button
      label="Cancelar"
      layout="button-border"
      variant="transparent"
      (buttonClick)="navigationService.navigateBack()"></app-button>

    <app-button
      type="submit"
      label="Salvar"
      [disabled]="form.invalid"></app-button>
  </div>
</form>
