import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute } from '@angular/router';
import { AutoFocusDirective } from '@core/directives/auto-focus.directive';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { NotificationService } from '@core/services/notification.service';
import {
  BannerActions,
  BannerAdminCreateOrUpdate,
} from '@modules/admin/edit/components/banner/models/banner.model';
import { BannerAdminService } from '@modules/admin/edit/components/banner/services/banner.service';
import { FileUpload } from '@modules/admin/edit/shared/upload/models/upload.model';
import { UploadAdminComponent } from '@modules/admin/edit/shared/upload/upload.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LineComponent } from '@shared/line/line.component';

@Component({
  selector: 'app-adm-banner',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    AutoFocusDirective,
    UploadAdminComponent,
    ButtonComponent,
    LineComponent,
  ],
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BannerAdminComponent implements OnInit {
  form!: FormGroup;
  domain: string = '';

  constructor(
    public navigationService: NavigationService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private bannerAdminService: BannerAdminService
  ) {}

  ngOnInit(): void {
    this.initialForm();
    this.getBannerByIdFromRoute();
  }

  get itemsArray(): FormArray {
    return this.form.get('items') as FormArray;
  }

  initialForm(): void {
    this.form = this.fb.group({
      items: this.fb.array([]),
    });
  }

  getBannerByIdFromRoute(): void {
    this.route.params.subscribe(
      params => params['domain'] && this.getBanner(params['domain'])
    );
  }

  getBanner(domain: string): void {
    this.domain = domain;

    this.bannerAdminService.banner({ domain }).subscribe({
      next: banners => {
        if (banners && banners.length > 0) {
          this.itemsArray.clear();

          banners.forEach(item => {
            const actionsArray = this.fb.array(
              item.actions.map(action => this.createButtonGroup(action))
            );

            this.itemsArray.push(
              this.fb.group({
                image: [item.image, Validators.required],
                storage: [item.storage, Validators.required],
                title: [item.title, Validators.required],
                caption: [item.caption, Validators.required],
                description: [item.description, Validators.required],
                actions: actionsArray,
              })
            );
          });
        }
      },
    });
  }

  getActionsControls(item: AbstractControl): AbstractControl[] {
    return (item.get('actions') as FormArray)?.controls ?? [];
  }

  createButtonGroup(actions?: BannerActions): FormGroup {
    return this.fb.group({
      title: [(actions && actions.title) || ''],
      link: [(actions && actions.link) || ''],
    });
  }

  addItem(): void {
    const actionsArray = this.fb.array([
      this.createButtonGroup(),
      this.createButtonGroup(),
    ]);

    this.itemsArray.push(
      this.fb.group({
        title: ['', Validators.required],
        caption: ['', Validators.required],
        description: ['', Validators.required],
        storage: ['', Validators.required],
        actions: actionsArray,
      })
    );

    this.form.reset(this.form.value);
  }

  addButtonItem(itemIndex: number): void {
    const actionsArray = this.itemsArray
      .at(itemIndex)
      .get('actions') as FormArray;

    if (actionsArray.length < 2) actionsArray.push(this.createButtonGroup());
  }

  removeItem(index: number): void {
    this.itemsArray.removeAt(index);
  }

  removeButtonItem(itemIndex: number, buttonIndex: number): void {
    const actionsArray = this.itemsArray
      .at(itemIndex)
      .get('actions') as FormArray;

    actionsArray.removeAt(buttonIndex);
  }

  setFileSrc(fileName: string, index: number): void {
    const itemToUpdate = this.itemsArray.at(index) as FormGroup;

    if (itemToUpdate) itemToUpdate.patchValue({ storage: fileName });
  }

  onUpload({ fileName }: FileUpload, index: number): void {
    this.setFileSrc(fileName, index);
  }

  onUploadClear(index: number): void {
    this.setFileSrc('', index);
  }

  onSubmit(): void {
    this.createOrUpdate();
  }

  onReorderList(event: CdkDragDrop<FormGroup[]>): void {
    this.itemsArray.controls
      .filter((_item, index) => index === event.previousIndex)
      .map(item => ({ ...item.value }));

    moveItemInArray(
      this.itemsArray.controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  createOrUpdate(): void {
    const data: BannerAdminCreateOrUpdate[] = this.itemsArray.controls.map(
      (item, index) => ({
        ...item.value,
        domain: this.domain,
        section: 'homeSignedIn',
        order: index + 1,
      })
    );

    this.bannerAdminService.createOrUpdate(data).subscribe({
      next: () => {
        this.getBanner(this.domain);
        this.notificationService.showToast('Banner salvo.');
      },
    });
  }
}
