<app-title>Obrigado {{ userName }}!</app-title>

<app-paragraph *ngIf="resendTimer$ | async">
  Você será redirecionado em <strong>{{ resendTimer$ | async }}</strong
  >.
</app-paragraph>

<div class="row mt-3">
  <div class="col-12">
    <app-button
      label="Ver veículos"
      (buttonClick)="navigationVehicles()"></app-button>
  </div>
</div>
