import { Injectable } from '@angular/core';
import { FlowService } from '@core/services/flow.service';
import {
  CartFlowName,
  CartFlowTitle,
} from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { CartFlows } from '@modules/pages/vehicles/components/proposal/cart-item-base/models/cart-item-base.model';
import { CartAllowedComponents } from '@modules/pages/vehicles/components/proposal/cart-item-base/types/cart-item-base.type';
import { CartItemComponent } from '@modules/pages/vehicles/components/proposal/cart-item/cart-item.component';
import { ObservationFormComponent } from '@modules/pages/vehicles/components/proposal/observation-form/observation-form.component';
import { ProposalSentComponent } from '@modules/pages/vehicles/components/proposal/proposal-sent/proposal-sent.component';

@Injectable({ providedIn: 'root' })
export class CartItemBaseService {
  idProposal: string = '';

  constructor(private flowService: FlowService<CartAllowedComponents>) {}

  initFlow(): void {
    const flows: CartFlows = {
      cart: {
        stages: [
          { title: CartFlowTitle.cart, component: CartItemComponent },
          {
            title: CartFlowTitle.observation,
            component: ObservationFormComponent,
          },
          { title: CartFlowTitle.sent, component: ProposalSentComponent },
        ],
      },
    };

    Object.entries(flows).forEach(([flowName, flow]) =>
      this.flowService.registerFlow(flowName as CartFlowName, flow)
    );
  }

  nextStage(idProposal?: string): void {
    if (idProposal) this.idProposal = idProposal;

    this.flowService.nextStage();
  }

  getFlowService(): FlowService<CartAllowedComponents> {
    return this.flowService;
  }

  get getIdProposal(): string {
    return this.idProposal;
  }
}
