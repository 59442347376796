import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { BaseApiService } from '@core/services/base-api.service';
import { FilterEditProfileFormParams } from '@modules/pages/edit-profile-form/models/edit-profile-form.model';
import { Observable } from 'rxjs';

@LoadingId(LoadingIdEnum.updateUserData)
@Injectable({
  providedIn: 'root',
})
export class EditProfileFormService extends BaseApiService {
  updateUserData({
    name,
    role,
    phone,
    entity,
    acceptedTerm,
  }: FilterEditProfileFormParams): Observable<void> {
    return this.makeRequest(
      () =>
        this.http.put<void>(`${apiUrl}/${apiVersion}/users/management`, {
          phone,
          name,
          role,
          entity: {
            name: entity.name,
            nationalRegistry: {
              number: entity.nationalRegistry.number,
            },
          },
          acceptedTerm,
        }),
      LoadingIdEnum.updateUserData,
      true
    );
  }
}
