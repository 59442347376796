import { Injectable } from '@angular/core';
import { apiUrl, apiVersion } from '@api/app.api';
import { BaseApiService } from '@core/services/base-api.service';
import { UserProposal } from '@modules/pages/account-summary/components/user-proposal/models/user-proposal.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserProposalService extends BaseApiService {
  getUserProposal(): Observable<UserProposal[]> {
    return this.makeRequest(
      () => this.http.get<UserProposal[]>(`${apiUrl}/${apiVersion}/proposals`),
      '',
      true
    );
  }
}
