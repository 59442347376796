<ng-container *ngIf="!href; else link">
  <button
    mat-raised-button
    [tabindex]="tabIndex"
    [type]="type"
    [ngClass]="[getVariantClass(variant), 'button', layout && layout]"
    [color]="color"
    [disabled]="disabled"
    (click)="onClick()">
    <app-icons
      *ngIf="
        icon ||
        isShowingCloseIcon ||
        isShowingPreviousIcon ||
        isShowingDownloadIcon
      "
      [icon]="icon"
      [isShowingCloseIcon]="isShowingCloseIcon"
      [isShowingPreviousIcon]="isShowingPreviousIcon"
      [isShowingDownloadIcon]="isShowingDownloadIcon"></app-icons>

    {{ label }}

    <ng-content *ngIf="content"></ng-content>
  </button>
</ng-container>

<ng-template #link>
  <a
    [href]="href"
    [ngClass]="[getVariantClass(variant), 'button', layout && layout]">
    {{ label }}
  </a>
</ng-template>
