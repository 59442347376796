<div class="vehicle-card col position-relative how-it-work-card mb-2">
  <div
    tabindex="0"
    role="button"
    (click)="openDetailsVehicle()"
    (keyup.enter)="openDetailsVehicle()">
    <div class="position-relative how-it-work-bg mb-2 rounded-4">
      <app-paragraph variant="fs-lg lh-lg text-white position-absolute message">
        Cuide do seu negócio que nós cuidamos da sua frota
      </app-paragraph>

      <app-image
        [width]="300"
        [height]="313"
        variant="position-absolute"
        altText="Homem na frente do carro como funciona"
        image="/assets/images/vehicles/how-it-work/man.png"></app-image>

      <app-button
        label="Veja como funciona"
        layout="position-absolute button-how-it-work"></app-button>
    </div>
  </div>
</div>
